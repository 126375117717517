import React, { useState } from 'react';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  Link as MuiLink,
  AccordionActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  deleteMatch,
  getMatchDetails
} from '../../redux/ducks/currentDataSlice';
import Match from './Match';
import ConnectMatchDialog from './ConnectMatchDialog';
import ConfirmDialog from '../shared/ConfirmDialog';

const MatchAccordion = (props) => {
  const { match, openDialog, eventRegionId, seriesId } = props;
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const {
    seriesMatchNumber,
    id,
    stats,
    riotId,
    completed,
    team1Data,
    team2Data,
    startDate,
    map
  } = match;

  const [connectingDialog, setConnectingDialog] = useState(false);
  const dispatch = useDispatch();
  const handleGetMatchDetails = () => {
    dispatch(getMatchDetails({ id }));
  };

  const handleDelete = () => {
    dispatch(deleteMatch({ id, seriesId }));
  };
  // const isProd = process.env.NODE_ENV === 'production';

  const getLink = () => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography color="primary">{`RIB.GG Match Link: `}</Typography>
      <MuiLink
        sx={{ marginLeft: '4px' }}
        href={`https://rib.gg/series/${seriesId}?match=${id}`}
        target="_blank"
      >
        <Typography>{` https://rib.gg/series/${seriesId}?match=${id}`}</Typography>
      </MuiLink>
    </Box>
  );

  return (
    <Accordion>
      <AccordionSummary>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Typography>{`Game #${seriesMatchNumber}`}</Typography>
          <Typography
            sx={{ marginLeft: '15px' }}
            color={riotId ? 'primary' : 'secondary'}
          >
            {riotId ? `Connected` : 'Not Connnected'}
          </Typography>
          <Typography
            color={completed ? 'primary' : 'secondary'}
            sx={{ marginLeft: '15px' }}
          >
            {completed ? `Completed` : 'Not Completed'}
          </Typography>
          {map && map.name && (
            <Typography color="secondary" sx={{ marginLeft: '15px' }}>
              {map.name}
            </Typography>
          )}
        </Box>
        <Typography>{`Match id: ${id}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ width: '100%' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={completed}
            onClick={() => setConnectingDialog(true)}
          >
            Qucik Connect API
          </Button>
          {connectingDialog && (
            <ConnectMatchDialog
              open={connectingDialog}
              handleClose={() => setConnectingDialog(false)}
              team1Data={team1Data}
              team2Data={team2Data}
              eventRegionId={eventRegionId}
              startDate={startDate}
              cmsId={id}
            />
          )}
          {stats ? (
            <>
              <Box sx={{ width: '100%' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleGetMatchDetails}
                  sx={{ marginBottom: '10px' }}
                >
                  Refresh Match Data
                </Button>
                {getLink()}
                <Match
                  seriesId={seriesId}
                  data={match}
                  openDialog={openDialog}
                />
              </Box>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleGetMatchDetails}
              >
                Load Match Data
              </Button>
              {getLink()}
            </>
          )}
        </Box>
      </AccordionDetails>
      <AccordionActions>
        <Button
          sx={{
            color: '#fa4454',
            border: `1px solid #fa4454`
          }}
          variant="outlined"
          onClick={() => setDeleteOpen(true)}
        >
          Delete Match
        </Button>
      </AccordionActions>
      <ConfirmDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        title="Delete Match (you cannot make a new one, you may have to create a new series)"
        onConfirm={handleDelete}
      />
    </Accordion>
  );
};

export default MatchAccordion;
