import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { toPng } from 'html-to-image';
import { hsl } from 'd3-color';
import { interpolateHsl } from 'd3-interpolate';

const generateShades = (color, numShades = 10) => {
  const colorScale = [];
  for (let i = 0; i < numShades; i += 1) {
    const hslColor = hsl(color);
    hslColor.l = Math.max(0, hslColor.l - i * 0.03);
    colorScale.push(interpolateHsl(color, hslColor)(1));
  }
  return colorScale;
};

const ImageTable = ({ eventNames, playersList }) => {
  const myRef = useRef();
  const [baseColor, setBaseColor] = useState('#db302f');
  const [imageURL, setImageURL] = useState(null);
  const colorShades = generateShades(baseColor);

  const handleColorChange = (event) => {
    setBaseColor(event.target.value);
  };

  const generateImage = async () => {
    try {
      const dataUrl = await toPng(myRef.current, {
        width: 1150,
        height: 1500
      });
      setImageURL(dataUrl);
    } catch (err) {
      console.error('Oops, an error occurred!', err);
    }
  };

  useEffect(() => {
    generateImage();
  }, [baseColor]); // update the dependency array if needed

  console.log(eventNames);
  return (
    <Box>
      <Typography> Twitter image: </Typography>
      <TextField
        label="Base Color"
        variant="outlined"
        value={baseColor}
        onChange={handleColorChange}
      />
      <Button variant="contained" color="primary" onClick={generateImage}>
        Generate Image
      </Button>
      {imageURL && (
        <a href={imageURL} download="image.png">
          <Button variant="contained" color="secondary">
            Download Image
          </Button>
        </a>
      )}
      <Box ref={myRef} sx={{ width: 1150, backgroundColor: 'black' }}>
        <Box sx={{ width: '100%', height: '240px' }}>
          <Typography>Event Name</Typography>
        </Box>
        {playersList.slice(0, 10).map((player, index) => {
          console.log(eventNames);
          console.log(player);

          const mainRating = parseFloat(player.ratings[eventNames[0]]).toFixed(
            2
          );
          const subRating1 = parseFloat(player.ratings[eventNames[1]]).toFixed(
            2
          );
          const subRating2 = parseFloat(player.ratings[eventNames[2]]).toFixed(
            2
          );

          return (
            <Box
              sx={{
                borderTop: `1px solid white`,
                borderBottom: `1px solid white`,
                backgroundColor: colorShades[index],
                height: '125px',
                display: 'flex',
                alignItems: 'center',
                px: '30px'
              }}
            >
              <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: '24px' }}>
                  {player.team_logo && (
                    <img
                      src={player.team_logo}
                      alt={`${player.team_name} logo`}
                      style={{ height: '118px' }}
                    />
                  )}
                </Box>

                <Typography sx={{ fontSize: '92px', fontFamily: 'impact' }}>
                  {player.ign}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  textAlign: 'right',
                  alignItems: 'center'
                }}
              >
                <Box sx={{ marginRight: '16px' }}>
                  {subRating1 && (
                    <Typography
                      sx={{
                        fontSize: '38px',
                        fontFamily: 'impact',
                        color: '#FAE29F'
                      }}
                    >
                      {subRating1}
                    </Typography>
                  )}
                  {subRating2 && (
                    <Typography
                      sx={{
                        fontSize: '38px',
                        fontFamily: 'impact',
                        color: '#A8EDEB'
                      }}
                    >
                      {subRating2}
                    </Typography>
                  )}
                </Box>
                <Typography
                  sx={{
                    fontSize: '92px',
                    fontFamily: 'impact',
                    width: '160px'
                  }}
                >
                  {mainRating}
                </Typography>
              </Box>
            </Box>
          );
        })}
        {/* Here you'd use your data to generate some cool graphics */}
      </Box>
    </Box>
  );
};

export default ImageTable;
