import axios from 'axios';

const BACKEND_PROD_URL = 'https://be-cms.rib.gg';
const BACKEND_DEV_URL = 'http://localhost:8081';

export const backendBaseUrl =
  process.env.NODE_ENV === 'production' ? BACKEND_PROD_URL : BACKEND_DEV_URL;

// Switching http(s) to ws(s) for websocket connection
export const websocketBaseUrl = backendBaseUrl.replace(
  /^http/,
  process.env.NODE_ENV === 'production' ? 'wss' : 'ws'
);

export const generateAxiosInterceptors = (history, showSnackbar) => {
  axios.interceptors.response.use(
    (response) => {
      if (response && response.data && response.data.snackbar) {
        showSnackbar(response.data.snackbar);
      }
      if (response.data.redirect) {
        history(response.data.redirect);
      }
      return response;
    },
    (error) => {
      if (error && error.response && error.response.data) {
        if (error.response.data.snackbar) {
          showSnackbar(error.response.data.snackbar);
        }
        if (error.response.data.redirect) {
          history(error.response.data.redirect);
        }
      }
      return Promise.reject(error);
    }
  );
};

export const competitiveTierMap = {
  3: 'Iron 1',
  4: 'Iron 2',
  5: 'Iron 3',
  6: 'Bronze 1',
  7: 'Bronze 2',
  8: 'Bronze 3',
  9: 'Silver 1',
  10: 'Silver 2',
  11: 'Silver 3',
  12: 'Gold 1',
  13: 'Gold 2',
  14: 'Gold 3',
  15: 'Plat 1',
  16: 'Plat 2',
  17: 'Plat 3',
  18: 'Diamond 1',
  19: 'Diamond 2',
  20: 'Diamond 3',
  21: 'Immortal 1',
  22: 'Immortal 2',
  23: 'Imomoral 3',
  24: 'Radiant'
};
export const maps = [undefined, 'Bind', 'Haven', 'Split', 'Ascent', 'Icebox'];

export const mapsToAssetMap = {
  '/Game/Maps/Bonsai/Bonsai': 'Split',
  '/Game/Maps/Duality/Duality': 'Bind',
  '/Game/Maps/Ascent/Ascent': 'Ascent',
  '/Game/Maps/Range/Range': 'The Range',
  '/Game/Maps/Range_NewPlayerExperience_Master/Range_NewPlayerExperience_Master':
    'The Shooting Range',
  '/Game/Maps/Triad/Triad': 'Haven',
  '/Game/Maps/Port/Port': 'Icebox',
  '/Game/Maps/Foxtrot/Foxtrot': 'Breeze'
};

export const roundWinConditions = [null, 'kills', 'time', 'bomb', 'defuse'];

export const matchWinConditions = [null, 'forfeit', 'automatic', 'standard'];

export const agents = [
  undefined,
  'Breach',
  'Brimstone',
  'Cypher',
  'Jett',
  'Killjoy',
  'Omen',
  'Phoenix',
  'Raze',
  'Reyna',
  'Sage',
  'Sova',
  'Viper',
  'Skye'
];

export const valNA = 'NA';
export const valLATAM = 'LATAM';
export const valKR = 'KR';
export const valEU = 'EU';
export const valBR = 'BR';
export const valAP = 'AP';
export const valESPORTS = 'ESPORTS';

export const valRegionList = [
  valNA,
  valLATAM,
  valKR,
  valEU,
  valBR,
  valAP,
  valESPORTS
];

export const serverRegionListToEventRegion = {
  1: valEU, // eu
  2: valNA, // na
  3: valAP, // asia
  4: valBR, // latam
  5: valEU, // MENA
  6: valAP, // oceana
  7: valESPORTS // international
};

export const vctRegionList = ['AMERICAS', 'EMEA', 'PACIFIC', 'CHINA'];
export const divisionList = ['VCT', 'VCL', 'GC', 'T3', 'UNI'];
export const t3SubdivisionList = ['S', 'A', 'B', 'C', 'D', 'UNRANKED'];

export const regions = [
  'North America',
  'South America',
  'Europe',
  'Asia',
  'Africa',
  'Australia',
  'International'
];

export const regionShortNameMap = {
  // '0': 'Global',
  1: 'EU',
  2: 'NA',
  3: 'AP',
  4: 'LA',
  5: 'ME',
  6: 'OC'
};

export const currencies = [
  'USD',
  'EUR',
  'CAD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTC',
  'BTN',
  'BWP',
  'BYN',
  'BZD',
  'CDF',
  'CHF',
  'CLF',
  'CLP',
  'CNH',
  'CNY',
  'COP',
  'CRC',
  'CUC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GGP',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'IMP',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JEP',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STD',
  'STN',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VEF',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XAG',
  'XAU',
  'XCD',
  'XDR',
  'XOF',
  'XPD',
  'XPF',
  'XPT',
  'YER',
  'ZAR',
  'ZMW',
  'ZWL'
];

export const playerRoleOptions = [
  'player',
  'head-coach',
  'coach',
  'assistant-coach',
  'performance-coach',
  'manager',
  'analyst',
  'staff',
  'content-creator'
];
