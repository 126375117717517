import React from 'react';
import { Grid, Typography } from '@mui/material';

const NotFound = () => (
  <Grid container justify="center">
    <Typography variant="h1">Not found</Typography>
  </Grid>
);

export default NotFound;
