/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import slugify from 'slugify';
import {
  Button,
  LinearProgress,
  Box,
  MenuItem,
  Typography,
  Divider,
  FormControlLabel
} from '@mui/material';
import classNames from 'classnames';
import { Formik, Form, Field } from 'formik';
import { DatePicker } from 'formik-mui-x-date-pickers';
import { TextField, Switch } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryState } from 'react-router-use-location-state';
import MDEditor from '@uiw/react-md-editor';

import {
  currencies,
  vctRegionList,
  divisionList,
  t3SubdivisionList
} from '../../constants/constants';
import { create, edit } from '../../redux/ducks/data';

const styles = () => ({
  select: {
    minWidth: '120px'
  },
  currency: {
    marginTop: '0',
    marginBottom: '0'
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px'
  }
});

const getSwitchField = (props) => {
  const {
    field: { name },
    label,
    index
  } = props;

  return (
    <FormControlLabel
      control={
        <Field name={name}>
          {({ field, form }) => (
            <input
              type="checkbox"
              {...field}
              checked={field?.value?.includes(label)}
              onChange={() => {
                if (field?.value?.includes(label)) {
                  const nextValue = field.value.filter(
                    (value) => value !== label
                  );
                  form.setFieldValue(name, nextValue);
                } else {
                  const nextValue = field.value.concat(label);
                  form.setFieldValue(name, nextValue);
                }
              }}
            />
          )}
        </Field>
      }
      label={label}
      key={index}
    />
  );
};

const EventForm = (props) => {
  const { id, data, isNew } = props;
  const [pId, _] = useQueryState('pId', '');
  const [newType, __] = useQueryState('newType', 'event');
  const [isParent, ___] = useQueryState('isParent', false);

  const dispatch = useDispatch();
  const classes = styles();
  const { countryList, regionList } = useSelector((state) => state.data);

  const eventRanks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const eventImportance = [0, 1, 2];

  const [formatMd, setFormatMd] = React.useState(data?.formatMd || '');

  const [eventsMd, setEventsMd] = React.useState(data?.eventsMd || '');

  // const [overviewValue, setOverviewValue] = React.useState(
  //   data?.eventsMd || '**Circuit sub events here**'
  // );

  return (
    <Formik
      initialValues={
        data && !isNew
          ? {
              ...data,
              vctRegions: data.vctRegions || [],
              divisions: data.divisions || []
            }
          : {
              name: '',
              description: '',
              startDate: new Date(),
              endDate: new Date(),
              prizePool: null,
              prizePoolCurrency: 'USD',
              url: '',
              imageUrl: '',
              livestreamLink: '',
              countryId: '',
              regionId: '',
              importance: 0,
              parent: isParent,
              parentId: pId,
              type: newType,
              vctRegions: [],
              divisions: []
            }
      }
      validate={(values) => {
        const errors = {};

        if (!values.name) {
          errors.name = 'Required';
        }
        if (
          (!values.slug && isNew) ||
          (!values.slug.match(/^[a-zA-Z0-9\s]+$/) && isNew)
        ) {
          errors.slug = 'Required - only letters, numbers, or spaces';
        }
        if (
          values.logoUrl &&
          (!values.logoUrl.match('.*\\.(jpg|png|jpeg|webp)$') ||
            !values.logoUrl.startsWith('https://'))
        ) {
          errors.logoUrl =
            'Invalid url. Must start with https:// and end with .jpg .png .webp or .jpeg';
        }
        if (
          values.imageUrl &&
          (!values.imageUrl.match('.*\\.(jpg|png|jpeg|webp)$') ||
            !values.imageUrl.startsWith('https://'))
        ) {
          errors.imageUrl =
            'Invalid url. Must start with https:// and end with .jpg .png .webp or .jpeg';
        }
        if (!values.url) {
          errors.url = 'Event URL is required';
        }
        if (!values.type) {
          errors.type = 'Event type is required';
        }
        if (!values.shortName) {
          errors.shortName = 'Event Short name is required';
        }
        if (values.url && !values.url.startsWith('https://')) {
          errors.url = 'Invalid url. Must start with https:// ';
        }
        if (values?.divisions?.includes('T3') && !values.t3Subdivision) {
          errors.t3Subdivision = 'T3 events must have a T3 subdivision';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (values.series) {
          delete values.series;
        }
        if (values.childEvents) {
          delete values.childEvents;
        }
        if (values.parentId === '') {
          values.parentId = null;
        }
        values.slug = slugify(values.slug).toLowerCase();
        values.formatMd = formatMd;
        values.eventsMd = eventsMd;
        setTimeout(() => {
          setSubmitting(false);
          isNew
            ? dispatch(create('events', values))
            : dispatch(edit('events', id, values));
        }, 500);
      }}
    >
      {({ submitForm, isSubmitting, values }) => (
        <Form>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="name"
              type="text"
              label="Event Name *"
            />
          </Box>
          <Box margin={1} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              placeholder="Event SEO Slug"
              name="slug"
              type="text"
              disabled={!isNew}
              label="Event SEO Slug *"
            />
            {values.slug && (
              <Typography variant="caption">
                {`Slug Preview: https://rib.gg/events/${slugify(
                  values.slug
                ).toLowerCase()}`}
              </Typography>
            )}
          </Box>
          <Box margin={1}>
            {vctRegionList.map((region, idx) =>
              getSwitchField({
                values,
                field: { name: 'vctRegions', value: region },
                label: region,
                index: idx
              })
            )}
          </Box>
          <Box margin={1}>
            {divisionList.map((division, idx) =>
              getSwitchField({
                values,
                field: { name: 'divisions', value: division },
                label: division,
                index: idx
              })
            )}
          </Box>
          {values.divisions.includes('T3') && (
            <Box margin={1}>
              <Field
                sx={{ width: '400px' }}
                component={TextField}
                type="text"
                name="t3Subdivision"
                label="T3 Subdivision (if known, keep it at C)"
                select
                variant="standard"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              >
                {t3SubdivisionList.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Field>
              <Box margin={1} sx={{ backgroundColor: '#aaa', color: 'black' }}>
                <Typography>
                  T3 SUBDIVISION (S, A, B, C, D, UNRANKED)
                </Typography>
                <Typography>
                  S = Nothing will be ranked here to start
                </Typography>
                <Typography>A = 1700+ elo</Typography>
                <Typography>B = 1500+ elo</Typography>
                <Typography>C = 1300+ elo</Typography>
                <Typography>D = Nothing ranked here to start</Typography>
                <Typography>
                  unranked = no matches from this event should be ranked
                </Typography>
              </Box>
            </Box>
          )}
          <Divider sx={classes.divider} />
          <Box margin={1} mb={4}>
            <Typography variant="h6">
              Event/Circuit format (like liquipedia)
            </Typography>
            <MDEditor value={formatMd} onChange={setFormatMd} />
          </Box>
          <Box margin={1}>
            <Typography variant="h6">
              Events (add for circuits, like liquipedia)
            </Typography>
            <MDEditor value={eventsMd} onChange={setEventsMd} />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <Typography>Useful colors:</Typography>
            <Typography color="#64A1D8">
              #64A1D8 light blue for titles
            </Typography>
            <Typography color="#64A1D8">
              #64A1D8 light blue for titles
            </Typography>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Submit
            </Button>
          </Box>
          <Typography> Example of format + events section: </Typography>
          <img width="500x" src="https://i.imgur.com/qWHlnnx.png" alt="ex" />
          <Divider sx={classes.divider} />
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              type="text"
              name="type"
              label="Type *"
              select
              variant="standard"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            >
              {['circuit', 'stage', 'event', 'bracket'].map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Field>
          </Box>
          <Box margin={1}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Field component={Switch} type="checkbox" name="parent" />
              }
              label="parent event (true for circuits, stages, events)"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="parentId"
              type="text"
              label="Parent Event ID"
            />
            <Typography variant="caption">
              If this event is a child event, put the eventId of the parent
              event here.
            </Typography>
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="childLabel"
              type="text"
              label="Child Label"
            />
            <Typography variant="caption">
              If this event is a child event, put a label that will show up on
              the site under the box of the parent event. For example, if this
              event is VCT NA Stage 1 Open Qualfiers, the parent event will be
              "VCT Stage 1", and this child label for this event will be "Open
              Qualifiers"
            </Typography>
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="shortName"
              type="text"
              label="Event Short Name "
            />
          </Box>
          <Divider sx={classes.divider} />
          <Box margin={1}>
            <Field
              component={TextField}
              name="url"
              type="text"
              label="Official Event URL *"
            />
          </Box>
          <Box margin={1}>
            <Field
              component="textarea"
              placeholder="Event Description"
              rows="5"
              name="description"
              type="text"
              label="Description"
            />
          </Box>
          {regionList && (
            <Box margin={1}>
              <Field
                sx={{ width: '400px' }}
                component={TextField}
                type="text"
                name="regionId"
                label="Region"
                select
                variant="standard"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              >
                {regionList.map((region) => (
                  <MenuItem key={region.id} value={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
              </Field>
            </Box>
          )}
          {countryList && (
            <Box margin={1}>
              <Field
                sx={{ width: '400px' }}
                component={TextField}
                type="text"
                name="countryId"
                label="Country"
                select
                variant="standard"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              >
                {countryList.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Field>
            </Box>
          )}
          {eventRanks && (
            <Box margin={1}>
              <Field
                sx={{ width: '400px' }}
                component={TextField}
                type="text"
                name="rank"
                label="Event Rank (10 is highest)"
                select
                variant="standard"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              >
                {eventRanks.map((rank) => (
                  <MenuItem key={rank} value={rank}>
                    {rank}
                  </MenuItem>
                ))}
              </Field>
            </Box>
          )}
          <Box margin={1}>
            <Field
              component={DatePicker}
              name="startDate"
              label="Event Start Date *"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={DatePicker}
              name="endDate"
              label="Event End Date *"
            />
          </Box>
          <Box margin={1}>
            <Box>
              <Field
                component={TextField}
                name="prizePool"
                type="number"
                label="Prize Amount"
              />
              <Field
                className={classNames(classes.select, classes.currency)}
                component={TextField}
                type="text"
                name="prizePoolCurrency"
                label="Currency"
                select
                variant="standard"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              >
                {currencies.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Field>
            </Box>
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '300px' }}
              component={TextField}
              name="logoUrl"
              type="text"
              label="Event Logo URL (Square)"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '300px' }}
              component={TextField}
              name="imageUrl"
              type="text"
              label="Optional Event Image URL (16:9)"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '300px' }}
              component={TextField}
              name="livestreamLink"
              type="text"
              label="Livestream (twitch) url"
            />
          </Box>
          {eventImportance && (
            <Box margin={1}>
              <Field
                sx={{ width: '400px' }}
                component={TextField}
                type="text"
                name="importance"
                label="Event Importance (0 for normal, 1 for important, 2 for SUPER important)"
                select
                variant="standard"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              >
                {eventImportance.map((rank) => (
                  <MenuItem key={rank} value={rank}>
                    {rank}
                  </MenuItem>
                ))}
              </Field>
            </Box>
          )}
          {isSubmitting && <LinearProgress />}
          <Box margin={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EventForm;
