/* eslint-disable no-console */
import { call, put } from 'redux-saga/effects';
import { setCustomers } from '../../redux/ducks/stripeSlice';
import { doGetCustomers } from '../requests/stripe';

export function* handleGetCustomers() {
  try {
    const response = yield call(doGetCustomers);
    const { data } = response;
    const { payload } = data;
    yield put(setCustomers({ data: payload }));
  } catch (error) {
    console.log(error);
  }
}
