export const SET_DATA = 'cms/riot/SET_DATA';
export const GET_DATA = 'cms/riot/GET_DATA';
export const POST_DATA = 'cms/riot/POST_DATA';
export const ADD_MATCH = 'cms/riot/ADD_MATCH';

const initialState = {
  gettingData: false,
  postingData: undefined,
  playerSearchResults: undefined,
  matchHistoryRegion: undefined,
  cmsMatchDetails: undefined,
  matchHistoryResults: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      const { data } = action;
      return { ...state, ...data };

    case ADD_MATCH:
      const { match } = action;
      const {
        matchInfo: { matchId }
      } = match;
      const newMatchHistory = state.matchHistoryResults;
      newMatchHistory.history.map(matchHistory => {
        if (matchHistory.matchId === matchId) {
          Object.assign(matchHistory, { details: match });
        }
        return matchHistory;
      });
      return { ...state, matchHistoryResults: newMatchHistory };

    default:
      return state;
  }
};

export const getData = (dataId, queryParams = {}, urlParams = {}) => ({
  type: GET_DATA,
  dataId,
  queryParams,
  urlParams
});

export const postData = (dataId, data) => ({
  type: POST_DATA,
  dataId,
  data
});

export const setData = data => ({
  type: SET_DATA,
  data
});

export const addMatch = match => ({
  type: ADD_MATCH,
  match
});
