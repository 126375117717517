import React from 'react';

import { TextField } from '@mui/material';

export default function AddGroup(props) {
  const { existingTitle, setContentDialogValues } = props;

  return (
    <TextField
      sx={{ mt: '20px' }}
      label="Group Title"
      type="text"
      defaultValue={existingTitle || ''}
      onChange={(e) => {
        setContentDialogValues({
          title: e.target.value
        });
      }}
      InputLabelProps={{
        shrink: true
      }}
    />
  );
}
