/* eslint-disable no-console */
import { call, put } from 'redux-saga/effects';

import {
  getAllTrending,
  getTrending,
  setAll,
  setCurrent
} from '../../redux/ducks/trendingSlice';
import {
  doAddTrending,
  doDeleteTrending,
  doGetAllTrending,
  doGetTrending
} from '../requests/trending';

export function* handleGetTrendingData(action) {
  const { category } = action.payload;
  let current = {};
  console.log('hello');
  try {
    const response = yield call(doGetTrending, category);
    const { data } = response;
    const { payload } = data;
    current = payload;
  } catch (error) {
    console.log(error);
  }
  yield put(setCurrent({ category, data: current }));
}

export function* handleGetAllTrendingData(action) {
  const { category } = action.payload;
  let listData = [];
  try {
    const response = yield call(doGetAllTrending, category);
    const { data } = response;
    const { payload } = data;
    listData = payload;
  } catch (error) {
    console.log(error);
  }
  yield put(setAll({ category, data: listData }));
}

export function* handleAddTrendingData(action) {
  const { category, data } = action.payload;
  try {
    yield call(doAddTrending, category, data);
    yield put(getAllTrending({ category }));
    yield put(getTrending({ category }));
  } catch (error) {
    console.log(error);
  }
}

export function* handleDeleteTrending(action) {
  const { category, id } = action.payload;
  try {
    yield call(doDeleteTrending, category, id);
    yield put(getAllTrending({ category }));
    yield put(getTrending({ category }));
  } catch (error) {
    console.log(error);
  }
}
