import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import TreeView from './TreeView';
import { getCircuits } from '../../redux/ducks/listSlice';

export default function Circuits() {
  const dispatch = useDispatch();
  const { circuits } = useSelector((state) => state.list);
  useEffect(() => {
    if (!circuits) {
      dispatch(getCircuits());
    }
  }, [dispatch]);

  return (
    <Box>
      <Box>
        <Link
          to="/events/new/edit?newType=circuit&isParent=true"
          target="_blank"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Button size="small" variant="outlined" color="primary">
            Create new circuit
          </Button>
        </Link>
        <Typography>Color map:</Typography>
        <Typography color="error"> Circuit </Typography>
        <Typography color="secondary"> Stage </Typography>
        <Typography color="primary"> Event </Typography>
        <Typography> Bracket </Typography>
      </Box>
      {circuits &&
        circuits.map((circuit) => (
          <Box sx={{ marginBottom: '50px' }}>
            <TreeView circuit={circuit} />
          </Box>
        ))}
      {!circuits && <CircularProgress />}
    </Box>
  );
}
