export const GET_COUNTRY_LIST = 'cms/data/GET_COUNTRY_LIST';
export const SET_COUNTRY_LIST = 'cms/data/SET_COUNTRY_LIST';
export const SET_REGION_LIST = 'cms/data/SET_REGION_LIST';
export const GET_REGION_LIST = 'cms/data/GET_REGION_LIST';
export const GET_AGENT_LIST = 'cms/data/GET_AGENT_LIST';
export const SET_AGENT_LIST = 'cms/data/SET_AGENT_LIST';
export const GET_MAP_LIST = 'cms/data/GET_MAP_LIST';
export const SET_MAP_LIST = 'cms/data/SET_MAP_LIST';
export const CREATE = 'cms/data/CREATE';
export const EDIT = 'cms/data/EDIT';
export const DELETE_DATA = 'cms/data/DELETE_DATA';
export const ADD_PLAYER_TO_TEAM = 'cms/data/ADD_PLAYER_TO_TEAM';
export const REMOVE_PLAYER_FROM_TEAM = 'cms/data/REMOVE_PLAYER_FROM_TEAM';
export const DO_TEAM_ALIAS = 'cms/data/DO_TEAM_ALIAS';
export const DO_NEW_PUUID = 'cms/data/DO_NEW_PUUID';
export const ADD_PLAYERS_TO_MATCH = 'cms/data/ADD_PLAYERS_TO_MATCH';
export const SWAP_TEAMS = 'cms/data/SWAP_TEAMS';
export const REMOVE_PLAYER_FROM_MATCH = 'cms/data/REMOVE_PLAYER_FROM_MATCH';
export const MAKE_PLAYER_CAPTAIN = 'cms/data/MAKE_PLAYER_CAPTAIN';
export const GET_ALL_TEAMS = 'cms/data/GET_ALL_TEAMS';
export const MATCH_UPDATE_PLAYER = 'cms/data/MATCH_UPDATE_PLAYER';
export const SET_PLAYER_STATS = 'cms/data/SET_PLAYER_STATS';

const initialState = {
  countryList: undefined,
  regionList: undefined,
  mapList: undefined,
  agentList: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY_LIST:
      const { countryList } = action;
      return { ...state, countryList };
    case SET_REGION_LIST:
      const { regionList } = action;
      return { ...state, regionList };
    case SET_AGENT_LIST:
      const { agentList } = action;
      return { ...state, agentList };
    case SET_MAP_LIST:
      const { mapList } = action;
      return { ...state, mapList };
    default:
      return state;
  }
};

export const getCountryList = () => ({
  type: GET_COUNTRY_LIST
});

export const setCountryList = countryList => ({
  type: SET_COUNTRY_LIST,
  countryList
});

export const getRegionList = () => ({
  type: GET_REGION_LIST
});

export const setRegionList = regionList => ({
  type: SET_REGION_LIST,
  regionList
});

export const getAgentList = () => ({
  type: GET_AGENT_LIST
});

export const setAgentList = agentList => ({
  type: SET_AGENT_LIST,
  agentList
});

export const getMapList = () => ({
  type: GET_MAP_LIST
});

export const setMapList = mapList => ({
  type: SET_MAP_LIST,
  mapList
});

export const create = (category, data, foolData, puuid) => ({
  type: CREATE,
  category,
  data,
  foolData,
  puuid
});

export const edit = (category, dataId, data, refresh = true) => ({
  type: EDIT,
  category,
  dataId,
  data,
  refresh
});

export const deleteData = (category, dataId) => ({
  type: DELETE_DATA,
  category,
  dataId
});

export const addPlayerToTeam = (teamId, playerObject) => ({
  type: ADD_PLAYER_TO_TEAM,
  teamId,
  playerObject
});

export const removePlayerFromTeam = (teamId, playerId, endDate) => ({
  type: REMOVE_PLAYER_FROM_TEAM,
  teamId,
  playerId,
  endDate
});

export const makePlayerCaptain = (teamId, playerId) => ({
  type: MAKE_PLAYER_CAPTAIN,
  teamId,
  playerId
});

export const setPlayerStats = (matchId, playerObject) => ({
  type: SET_PLAYER_STATS,
  matchId,
  playerObject
});

export const matchUpdatePlayer = (matchId, playerObject) => ({
  type: MATCH_UPDATE_PLAYER,
  matchId,
  playerObject
});

export const addPlayersToMatch = (matchId, playerObject) => ({
  type: ADD_PLAYERS_TO_MATCH,
  matchId,
  playerObject
});

export const removePlayerFromMatch = (matchId, playerId) => ({
  type: REMOVE_PLAYER_FROM_MATCH,
  matchId,
  playerId
});

export const swapTeams = matchId => ({
  type: SWAP_TEAMS,
  matchId
});

export const getAllTeams = () => ({
  type: GET_ALL_TEAMS
});

export const doTeamAlias = (teamId, alias, action) => ({
  type: DO_TEAM_ALIAS,
  teamId,
  alias,
  action
});

export const doNewPuuid = (playerId, puuid, action) => ({
  type: DO_NEW_PUUID,
  playerId,
  puuid,
  action
});
