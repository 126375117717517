import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';

const getRequestOptions = (dataId, urlParams = {}) => {
  return {
    searchPlayerIGN: {
      url: `${backendBaseUrl}/riot/search-player`
    },
    getMatchHistory: {
      url: `${backendBaseUrl}/riot/match-history`
    },
    getTeamHistories: {
      url: `${backendBaseUrl}/riot/team-history`
    },
    getMatch: {
      url: `${backendBaseUrl}/riot/match`
    },
    getIndividualMatch: {
      url: `${backendBaseUrl}/riot/match`
    },
    addPlayerByPuuid: {
      url: `${backendBaseUrl}/riot/add-player-by-puuid`
    },
    addPlayerByIgn: {
      url: `${backendBaseUrl}/riot/add-player-by-ign`
    },
    getCmsMatchDetails: {
      url: `${backendBaseUrl}/riot/cms-match/${urlParams.matchId}`
    },
    connectMatch: {
      url: `${backendBaseUrl}/riot/connect-match`
    },
    quickConnectMatch: {
      url: `${backendBaseUrl}/riot/quick-connect-match`
    },
    unconnectMatch: {
      url: `${backendBaseUrl}/riot/unconnect-match`
    },
    postPMT: {
      url: `${backendBaseUrl}/riot/post-pmt`
    },
    schedulePMT: {
      url: `${backendBaseUrl}/riot/schedule-pmts`
    }
  }[dataId];
};

export function doGetData(dataId, queryParams, urlParams) {
  let url = '';
  const query = queryParams;
  const method = 'get';
  ({ url } = getRequestOptions(dataId, urlParams));
  return axios.request({
    method,
    url,
    params: query,
    withCredentials: true
  });
}

export function doPostData(dataId, data) {
  let url = '';
  const method = 'post';
  ({ url } = getRequestOptions(dataId));
  return axios.request({
    method,
    url,
    data,
    withCredentials: true
  });
}

export function doPostPMT(dataId, data) {
  let url = '';
  ({ url } = getRequestOptions(dataId));
  return axios.request({
    method: 'POST',
    url,
    data,
    withCredentials: true
  });
}

export function doSchedulePMT(dataId, data) {
  let url = '';
  ({ url } = getRequestOptions(dataId));
  return axios.request({
    method: 'POST',
    url,
    withCredentials: true,
    data
  });
}
