import React, { useState } from 'react';
import {
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { backendBaseUrl } from '../../constants/constants';

const EditPickBansForm = ({ seriesId, team1, team2, pickban }) => {
  const [pickBans, setPickBans] = useState(
    JSON.parse(JSON.stringify(pickban)) || []
  );
  const typeOptions = ['pick', 'ban'];
  const { mapList } = useSelector((state) => state.data);

  const handleAddPickBan = () => {
    setPickBans([
      ...pickBans,
      { teamId: '', mapId: '', type: '', seriesId, isLeftover: false }
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const newPickBans = [...pickBans];
    newPickBans[index][field] = value;
    setPickBans(newPickBans);
  };

  const handleDeletePickBan = (index) => {
    const newPickBans = [...pickBans];
    newPickBans.splice(index, 1);
    setPickBans(newPickBans);
  };

  const handleSubmit = () => {
    const teamSequences = {
      [team1?.id]: { pick: 0, ban: 0 },
      [team2?.id]: { pick: 0, ban: 0 }
    };

    // Add sequence and teamSequence fields
    const processedPickBans = pickBans.map((pickBan, index) => {
      const teamSequenceType = pickBan.type;
      teamSequences[pickBan?.teamId][teamSequenceType] += 1;

      return {
        ...pickBan,
        seqNum: index + 1,
        teamSeqNum: teamSequences[pickBan?.teamId][teamSequenceType]
      };
    });

    axios
      .post(
        `${backendBaseUrl}/cms/series/pickban`,
        { pickbans: processedPickBans },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Box>
      {pickBans.map((pickBan, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} sx={{ mb: 3 }}>
          <TextField label="Sequence" value={index + 1} disabled />
          <FormControl sx={{ width: '150px' }}>
            <InputLabel>Team</InputLabel>
            <Select
              value={pickBan.teamId}
              onChange={(e) =>
                handleInputChange(index, 'teamId', e.target.value)
              }
            >
              <MenuItem value={team1?.id}>{team1?.name}</MenuItem>
              <MenuItem value={team2?.id}>{team2?.name}</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: '100px' }}>
            <InputLabel>Type</InputLabel>
            <Select
              value={pickBan.type}
              onChange={(e) => handleInputChange(index, 'type', e.target.value)}
            >
              {typeOptions.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: '100px' }}>
            <InputLabel>Map</InputLabel>
            <Select
              value={pickBan.mapId}
              onChange={(e) =>
                handleInputChange(index, 'mapId', e.target.value)
              }
            >
              {mapList.map((map) => (
                <MenuItem key={map.id} value={map.id}>
                  {map.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: '100px' }}>
            <InputLabel>Map</InputLabel>
            <Select
              value={pickBan.mapId}
              onChange={(e) =>
                handleInputChange(index, 'mapId', e.target.value)
              }
            >
              {mapList.map((map) => (
                <MenuItem key={map.id} value={map.id}>
                  {map.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={pickBan.isLeftover}
                onChange={(e) =>
                  handleInputChange(index, 'isLeftover', e.target.checked)
                }
              />
            }
            label="Is Leftover"
          />
          <Button onClick={() => handleDeletePickBan(index)}>Delete</Button>
        </Box>
      ))}
      <Button onClick={handleAddPickBan}>Add Pick/Ban</Button>
      <Button onClick={handleSubmit}>Submit</Button>
    </Box>
  );
};

export default EditPickBansForm;
