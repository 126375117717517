import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Tooltip
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import { postData } from '../../../redux/ducks/riot';

const styles = () => ({
  searchBar: {
    marginTop: '10px',
    width: '600px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    marginLeft: '10px'
  },
  dialog: {
    padding: '50px'
  },
  playerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  playerItem: {
    textAlign: 'center',
    marginTop: '10px'
  }
});

const MatchDialog = props => {
  const { connecting, setConnecting, details, realm, map } = props;
  // const [cmsMatchId, setCmsMatchId] = useState('4W2OmFNSEIxSFKk81Pl5');
  const [cmsMatchId, setCmsMatchId] = useState('');
  const { gettingData } = useSelector(state => state.riot);
  const dispatch = useDispatch();
  const classes = styles();

  const handleClose = addMatchConfirmed => {
    setConnecting(false);
    if (addMatchConfirmed) {
      dispatch(
        postData('connectMatch', {
          riotMatchId: details.matchInfo.matchId,
          dbMatchId: cmsMatchId,
          realm,
          map: map.riotId
        })
      );
    }
  };

  return (
    <Dialog
      sx={classes.dialog}
      open={connecting}
      fullScreen
      onClose={() => setConnecting(false)}
    >
      <DialogTitle id="alert-dialog-title">
        Connect this riot match to a CMS match
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex' }}>
          <Tooltip
            title=" The match ID is the end of a matchs URL. I.e for
            /matches/063OeMXIcmVyD1V6SWmF, the matchId is 063OeMXIcmVyD1V6SWmF"
          >
            <InfoIcon color="secondary" />
          </Tooltip>
          <Typography color="secondary" variant="body1">
            Enter a CMS Match ID
          </Typography>
        </Box>
        <Box sx={classes.searchBar}>
          <TextField
            inputProps={{
              onChange: event => setCmsMatchId(event.target.value)
            }}
            label="CMS Match ID"
            variant="outlined"
            fullWidth
            value={cmsMatchId}
          />
        </Box>
        <Typography color="error">
          Make sure all 10 players are added to the match with agents, and
          attackingFirstTeam is set.
        </Typography>
        <Typography>This can take up to 40 seconds</Typography>
        <Button
          onClick={() => handleClose(true)}
          disabled={gettingData}
          sx={classes.button}
          variant="contained"
          color="primary"
        >
          Connect Match
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default MatchDialog;
