/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

function sanitizeData(dataArray) {
  return dataArray.map((data) => {
    const sanitizedData = {};

    if (data.id !== undefined) sanitizedData.id = data.id;
    if (data.seriesId !== undefined) sanitizedData.seriesId = data.seriesId;
    if (data.localSeriesId !== undefined)
      sanitizedData.localSeriesId = data.localSeriesId;

    if (data.startDate !== undefined) sanitizedData.startDate = data.startDate;
    if (data.bestOf !== undefined) sanitizedData.bestOf = data.bestOf;
    if (data.completed !== undefined) sanitizedData.completed = data.completed;

    if (data.team1Score !== undefined)
      sanitizedData.team1Score = data.team1Score;
    if (data.team2Score !== undefined)
      sanitizedData.team2Score = data.team2Score;

    if (data.team1 !== undefined) {
      sanitizedData.team1 = {};
      if (data.team1.name !== undefined)
        sanitizedData.team1.name = data.team1.name;
      if (data.team1.id !== undefined) sanitizedData.team1.id = data.team1.id;
      if (data.team1.logoUrl !== undefined)
        sanitizedData.team1.logoUrl = data.team1.logoUrl;
      if (data.team1.shortName !== undefined)
        sanitizedData.team1.shortName = data.team1.shortName;
      if (data.team1.team1TotalRoundsWon !== undefined)
        sanitizedData.team1.team1TotalRoundsWon =
          data.team1.team1TotalRoundsWon;
      if (data.team1.team1TotalRoundsLost !== undefined)
        sanitizedData.team1.team1TotalRoundsLost =
          data.team1.team1TotalRoundsLost;
    }
    if (data.team2 !== undefined) {
      sanitizedData.team2 = {};
      if (data.team2.name !== undefined)
        sanitizedData.team2.name = data.team2.name;
      if (data.team2.id !== undefined) sanitizedData.team2.id = data.team2.id;
      if (data.team2.logoUrl !== undefined)
        sanitizedData.team2.logoUrl = data.team2.logoUrl;
      if (data.team2.shortName !== undefined)
        sanitizedData.team2.shortName = data.team2.shortName;
      if (data.team2.team2TotalRoundsWon !== undefined)
        sanitizedData.team2.team2TotalRoundsWon =
          data.team2.team2TotalRoundsWon;
      if (data.team2.team2TotalRoundsLost !== undefined)
        sanitizedData.team2.team2TotalRoundsLost =
          data.team2.team2TotalRoundsLost;
    }
    return sanitizedData;
  });
}

export default function AddWeeklySeries(props) {
  console.log({ daProps: props });
  const { series: seriesList } =
    useSelector((state) => state.currentData)?.events || {};
  const [selectedSeriesIds, setSelectedSeriesIds] = useState([]);
  const [processedSeries, setProcessedSeries] = useState([]);

  const handleSeriesSelection = (seriesId) => {
    setSelectedSeriesIds((prevIds) =>
      prevIds.includes(seriesId)
        ? prevIds.filter((id) => id !== seriesId)
        : [...prevIds, seriesId]
    );
  };

  const { setContentDialogValues } = props;

  function selectTeamProperties(team) {
    const { name, id, logoUrl, shortName } = team;
    return {
      name,
      id,
      logoUrl,
      shortName
    };
  }

  function selectSeriesProperties(series) {
    const {
      id,
      startDate,
      bestOf,
      completed,
      matches,
      team1,
      team2,
      team1Score,
      team2Score
    } = series || {};

    let team1TotalRoundsWon = 0;
    let team1TotalRoundsLost = 0;
    let team2TotalRoundsWon = 0;
    let team2TotalRoundsLost = 0;

    matches?.forEach((match) => {
      const { team1Score, team2Score } = match;
      team1TotalRoundsWon += team1Score;
      team1TotalRoundsLost += team2Score;
      team2TotalRoundsWon += team2Score;
      team2TotalRoundsLost += team1Score;
    });
    return {
      id,
      seriesId: id,
      bestOf,
      startDate,
      completed,
      team1: {
        ...selectTeamProperties(team1),
        team1TotalRoundsWon,
        team1TotalRoundsLost
      },
      team2: {
        ...selectTeamProperties(team2),
        team2TotalRoundsWon,
        team2TotalRoundsLost
      },
      team1Score,
      team2Score
    };
  }

  useEffect(() => {
    const processed = selectedSeriesIds.map((seriesId) => {
      const series = seriesList.find((s) => s.id === seriesId);
      return selectSeriesProperties(series);
    });
    setProcessedSeries(processed);
  }, [selectedSeriesIds, seriesList]);

  useEffect(() => {
    const sanitizedDataArray = sanitizeData(processedSeries);
    console.log({ sanitizedDataArray });
    setContentDialogValues({ seriesData: sanitizedDataArray });
  }, [processedSeries, setContentDialogValues]);

  return (
    <Box>
      {seriesList.map((series) => (
        <Box key={series.id} sx={{ display: 'flex', p: 3 }}>
          <input
            type="checkbox"
            checked={selectedSeriesIds.includes(series.id)}
            onChange={() => handleSeriesSelection(series.id)}
          />
          <Typography>
            {series.team1?.name || 'TBD'} VS {series.team2?.name || 'TBD'} -{' '}
            {dayjs(series.startDate).format('MMM DD YY') || 'Date Unknown'}{' '}
            {`series id - `}
            {series.id}
          </Typography>
        </Box>
      ))}
      <Typography variant="h6">Selected Series:</Typography>
    </Box>
  );
}
