import React, { useState, useRef, useEffect } from 'react';
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box
} from '@mui/material';
import RibBracket from '@runitback/bracket/RibBracket';
import Dialog from './Dialog';
import DialogActions from './DialogActions';
import useBracketBuilder from './useBracketBuilder';

const BracketBuilder = ({ bracket, setBracket, eventData }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState(<> </>);
  const [type, setType] = useState(bracket?.type || 'winners');

  useEffect(() => {
    setBracket((cur) => ({ ...cur, type }));
    if (type === 'weekly' && !bracket.weekly) {
      setBracket((cur) => ({
        ...cur,
        weekly: {
          standingOptions: {},
          weeks: [{ id: 0, title: 'Week 1', series: [] }]
        }
      }));
    }
  }, [type]);

  const contentDialogValues = useRef({});

  const setContentDialogValues = (params) => {
    contentDialogValues.current = {
      ...contentDialogValues.current,
      ...params
    };
  };

  const handleDialog = (title, content) => {
    setDialogTitle(title);
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleConfirm = (action, params) => {
    const currentDialogValues = contentDialogValues.current;
    action({ ...params, ...currentDialogValues });
    setDialogOpen(false);
  };

  const confirmDialog = (message, action, params) => (
    <Box>
      <Typography>{message}</Typography>
      <DialogActions
        handleCancel={() => setDialogOpen(false)}
        handleConfirm={() => handleConfirm(action, params)}
      />
    </Box>
  );

  const handleClickDialog = (dialogType, params) => {
    const {
      title,
      message,
      Content,
      action,
      skipContent = false
    } = bracketBuilder[dialogType];
    setContentDialogValues({ ...params });

    if (skipContent) {
      console.log('skipping dialog');
      action(params);
      setDialogOpen(false);
    } else {
      handleDialog(
        title,
        <>
          {Content && (
            <Content
              {...params}
              eventData={eventData}
              bracket={bracket}
              setContentDialogValues={setContentDialogValues}
            />
          )}
          {confirmDialog(message, action, params)}
        </>
      );
    }
  };

  const bracketBuilder = useBracketBuilder(setBracket);

  return (
    <Box>
      <Typography variant="h3"> Bracket Builder </Typography>
      <FormControl component="fieldset">
        <FormLabel component="legend">Bracket Type</FormLabel>
        <RadioGroup
          aria-label="gender"
          name="type"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <FormControlLabel value="single" control={<Radio />} label="single" />
          <FormControlLabel value="double" control={<Radio />} label="double" />
          <FormControlLabel
            value="group"
            control={<Radio />}
            label="group stage"
          />
          <FormControlLabel value="weekly" control={<Radio />} label="weekly" />
        </RadioGroup>
      </FormControl>
      <Typography variant="h4">Edit Bracket</Typography>
      <RibBracket
        getSeriesPath={() => {}}
        bracket={bracket}
        editable
        handleClickDialog={handleClickDialog}
      />
      <Dialog open={dialogOpen} title={dialogTitle} content={dialogContent} />
    </Box>
  );
};

export default BracketBuilder;
