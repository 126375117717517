import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';

export function doAddPlayerToTeam(teamId, playerObject) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/teams/addPlayer`,
    data: {
      teamId,
      playerObject
    },
    withCredentials: true
  });
}

export function doTeamAlias(teamId, alias, action) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/teams/alias`,
    data: {
      teamId,
      alias,
      action
    },
    withCredentials: true
  });
}

export function doNewPuuid(playerId, puuid, action) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/players/puuid`,
    data: {
      playerId,
      puuid,
      action
    },
    withCredentials: true
  });
}

export function doRemovePlayerFromTeam(teamId, playerId, endDate) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/teams/removePlayer`,
    data: {
      teamId,
      playerId,
      endDate
    },
    withCredentials: true
  });
}

export function doCreateByUrl(teamUrl) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/teams/createByUrl`,
    data: {
      teamUrl
    },
    withCredentials: true
  });
}

export function doGetAllTeams() {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/cms/teams/getAll`,
    withCredentials: true
  });
}

export function doMakePlayerCaptain(teamId, playerId) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/teams/makeCaptain`,
    data: {
      teamId,
      playerId
    },
    withCredentials: true
  });
}
