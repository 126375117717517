import React from 'react';
import { Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { deleteData } from '../../redux/ducks/data';

const Delete = (props) => {
  const { category, id, handleClose } = props;
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteData(category, id));
    handleClose();
  };

  return (
    <Box>
      Are you sure you want to delete this?
      <Button variant="text" onClick={handleDelete}>
        Yes
      </Button>
      <Button variant="text" onClick={handleClose}>
        No
      </Button>
    </Box>
  );
};

export default Delete;
