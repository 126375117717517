import {
  configureStore,
  combineReducers,
  getDefaultMiddleware
} from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import settingsReducer from './ducks/settings';
import dataReducer from './ducks/data';
import userReducer from './ducks/user';
import riotReducer from './ducks/riot';
import { watcherSaga } from '../sagas/rootSaga';
import listSlice from './ducks/listSlice';
import currentDataSlice from './ducks/currentDataSlice';
import teamSlice from './ducks/teamSlice';
import rankingSlice from './ducks/rankingSlice';
import trendingSlice from './ducks/trendingSlice';
import stripeSlice from './ducks/stripeSlice';
import metaSlice from './ducks/metaSlice';

const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger();

let middleware = [sagaMiddleware];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, loggerMiddleware];
}

const reducer = combineReducers({
  settings: settingsReducer,
  data: dataReducer,
  user: userReducer,
  riot: riotReducer,
  currentData: currentDataSlice,
  list: listSlice,
  team: teamSlice,
  ranking: rankingSlice,
  trending: trendingSlice,
  stripe: stripeSlice,
  meta: metaSlice
});

const store = configureStore({
  reducer,
  middleware: [...getDefaultMiddleware({ thunk: false }), ...middleware]
});
sagaMiddleware.run(watcherSaga);

export default store;
