export function getUniqueTeams(seriesList) {
  const teamsMap = new Map();

  seriesList?.forEach((series) => {
    // Extract team 1 and team 2 from each series
    const team1 = series?.team1;
    const team2 = series?.team2;

    // Add team 1 if it hasn't been added yet
    if (team1 && !teamsMap.has(team1.id)) {
      teamsMap.set(team1.id, {
        logoUrl: team1.logoUrl,
        name: team1.name,
        shortName: team1.shortName,
        id: team1.id
      });
    }

    // Add team 2 if it hasn't been added yet
    if (team2 && !teamsMap.has(team2.id)) {
      teamsMap.set(team2.id, {
        logoUrl: team2.logoUrl,
        name: team2.name,
        shortName: team2.shortName,
        id: team2.id
      });
    }
  });

  // Convert the Map values to an array
  return Array.from(teamsMap.values());
}
