import React from 'react';
import { Typography, Box, Paper, Button } from '@mui/material';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from '../../../redux/ducks/riot';
import MatchDetails from './MatchDetails';

// import { setSnackbar } from '../../../redux/ducks/settings';

const styles = () => ({
  root: {
    margin: '15px',
    padding: '15px'
  },
  header: {
    fontWeight: 'bold'
  },
  teamId: {
    color: props =>
      // eslint-disable-next-line no-nested-ternary
      props.teamId !== 'Blue' && props.teamId !== 'Red'
        ? '#ffef62'
        : props.teamId === 'Blue'
        ? '#35baf6'
        : '#ff4569',
    fontWeight: 'bold'
  }
});

const MatchHistoryCard = props => {
  const classes = styles(props);
  const dispatch = useDispatch();
  const { matchId, gameStartTimeMillis, details, parentPuuid } = props;
  const { matchHistoryRegion, gettingData } = useSelector(state => state.riot);
  const timeAgo = moment(gameStartTimeMillis).fromNow();

  return (
    <Paper sx={classes.root}>
      <Typography sx={classes.header} color="inherit">
        {`Match started ${timeAgo}`}
      </Typography>
      {/* <Box>
        <Typography variant="caption" sx={classes.teamId}>
          {`Team ID: ${teamId} ${isDeathmatch ? '(deathmatch)' : ''}`}
        </Typography>
      </Box> */}
      <Typography variant="caption"> {`MatchID: ${matchId}`} </Typography>
      <Box>
        {!details && (
          <Button
            variant="outlined"
            color="secondary"
            disabled={gettingData}
            onClick={() =>
              dispatch(
                getData('getMatch', { realm: matchHistoryRegion, matchId })
              )
            }
          >
            Get Match Info
          </Button>
        )}
        {details && (
          <MatchDetails
            realm={matchHistoryRegion}
            parentPuuid={parentPuuid}
            details={details}
          />
        )}
      </Box>
    </Paper>
  );
};

export default MatchHistoryCard;
