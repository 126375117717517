/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Link,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageTable from './ImageTable';

function PlayerTable({ rows, sortEvent = '' }) {
  const [data, setData] = useState(rows);

  useEffect(() => {
    setData(rows);
  }, [rows]);

  const players = new Map();
  const eventNames = data.map((event) => event.eventName);
  for (const event of data) {
    for (const player of event.data) {
      if (!players.has(player.player_id)) {
        players.set(player.player_id, {
          ign: player.ign,
          image_url: player.image_url,
          player_id: player.player_id,
          ratings: {},
          ...player
        });
      }
      players.get(player.player_id).ratings[event.eventName] =
        player.average_rating;
    }
  }

  const [playersList, setPlayersList] = useState([]);

  useEffect(() => {
    // Filter players who participated in all events and sort them
    const sortedPlayers = Array.from(players.values())
      .filter(
        (player) => Object.keys(player.ratings).length === eventNames.length
      )
      .sort((a, b) => b.ratings[sortEvent] - a.ratings[sortEvent]);

    setPlayersList(sortedPlayers);
  }, [players, eventNames, sortEvent]);

  const removePlayer = (id) => {
    const newData = data.map((event) => {
      return {
        ...event,
        data: event.data.filter((player) => player.player_id !== id)
      };
    });

    setData(newData);
  };

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <ImageTable eventNames={eventNames} playersList={playersList} />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" dense>
          <TableHead>
            <TableRow>
              <TableCell>Position</TableCell>
              <TableCell>Team</TableCell>
              <TableCell>Player</TableCell>
              {eventNames.map((eventName) => (
                <TableCell key={eventName} align="right">
                  Average Rank ({eventName})
                </TableCell>
              ))}
              <TableCell align="right">Profile</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {playersList.map((player, idx) => (
              <TableRow key={player.player_id}>
                <TableCell component="th" scope="row">
                  {idx + 1}
                </TableCell>
                <TableCell align="right">
                  {player.team_logo && (
                    <img
                      src={player.team_logo}
                      alt={`${player.team_name} logo`}
                      style={{ maxWidth: '50px', maxHeight: '50px' }}
                    />
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {player.image_url && (
                      <Avatar
                        src={
                          player.image_url || 'https://i.imgur.com/s8elSjO.png'
                        }
                        sx={{ mr: 2 }}
                      />
                    )}
                    <Typography>{player.ign}</Typography>
                    {player.team_name && (
                      <Typography>({player.team_name})</Typography>
                    )}
                  </Box>
                </TableCell>

                {eventNames.map((eventName) => (
                  <TableCell key={eventName} align="right">
                    {parseFloat(player.ratings[eventName]).toFixed(3)}
                  </TableCell>
                ))}
                <TableCell align="right">
                  <Link
                    href={`https://rib.gg/players/${player.player_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Profile
                  </Link>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removePlayer(player.player_id)}
                  >
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PlayerTable;
