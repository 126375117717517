import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Button, TextField, Checkbox } from '@mui/material';

import { postData } from '../../../redux/ducks/riot';

const styles = () => ({
  searchBar: {
    marginTop: '10px',
    width: '600px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    width: '100px',
    marginLeft: '10px'
  },
  or: {
    color: '#ffac33',
    marginTop: '50px',
    marginBottom: '50px'
  }
});

const AddPlayer = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { postingData } = useSelector(state => state.riot);
  const [puuid, setPuuid] = useState('');
  const [ign, setIgn] = useState('');
  const [tagLine, setTagLine] = useState('');

  const handleSetLan = val => {
    setLan(val);
    if (val) {
      setTagLine('eProd');
    }
  };
  const [lan, setLan] = useState(false);

  return (
    <Box>
      <Typography variant="h4"> Add player by PUUID</Typography>
      <Box sx={classes.searchBar}>
        <TextField
          inputProps={{
            onChange: event => setPuuid(event.target.value)
          }}
          label="PUUID"
          variant="outlined"
          fullWidth
          value={puuid}
        />
      </Box>
      <Button
        onClick={() => {
          dispatch(postData('addPlayerByPuuid', { puuid }));
        }}
        disabled={postingData}
        sx={classes.button}
        variant="contained"
        color="primary"
      >
        Add Player
      </Button>
      <Typography sx={classes.or} variant="h3">
        - OR -
      </Typography>
      <Typography variant="h4"> Add by IGN and Tagline </Typography>
      <Box sx={classes.searchBar}>
        <TextField
          inputProps={{
            onChange: event => setIgn(event.target.value)
          }}
          label="IGN"
          variant="outlined"
          fullWidth
          value={ign}
        />
        <TextField
          inputProps={{
            onChange: event => setTagLine(event.target.value)
          }}
          label="Tagline"
          variant="outlined"
          fullWidth
          value={tagLine}
        />
        <Checkbox
          value={lan}
          onChange={event => handleSetLan(event.target.value)}
        />
        <Typography sx={{ width: '200px' }}>
          LAN (or on tournament server?){' '}
        </Typography>
      </Box>
      <Button
        onClick={() => {
          dispatch(postData('addPlayerByIgn', { ign, tagLine, lan }));
        }}
        disabled={postingData}
        sx={classes.button}
        variant="contained"
        color="primary"
      >
        Add Player
      </Button>
    </Box>
  );
};

export default AddPlayer;
