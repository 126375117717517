/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Link as MuiLink,
  Checkbox,
  Divider,
  Switch
} from '@mui/material';
import { Link } from 'react-router-dom';
import * as clipboard from 'clipboard-polyfill/text';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toggleSeriesLive } from '../../redux/ducks/currentDataSlice';
import { postPmt, schedulePmt } from '../../redux/ducks/metaSlice';
import EditPickBansForm from './Pickbans';

const Series = (props) => {
  // get the user stuff and disable button if not proper user
  const { user } = useSelector((state) => state.user);
  const { postingData } = useSelector((state) => state.riot);
  const { pmtAuthorized, discordId } = user;

  // celtic: 421936711266992129
  // adawg: 359136498118950923
  // pickles: 176672487793360897
  // buck: 700746507653218324
  const allowedValorantSubredditIds = [
    '421936711266992129',
    '359136498118950923',
    '176672487793360897',
    '700746507653218324'
  ];

  const valorantAuthorized = allowedValorantSubredditIds.includes(discordId);

  const { data, openDialog, eventData } = props;
  const {
    team1,
    team2,
    bestOf,
    bracket,
    live,
    stage,
    id,
    matches,
    vlrId,
    vodUrl,
    ggbetId,
    completed,
    pmtJson,
    pickban
  } = data;
  const { name: team1Name, id: team1Id } = team1 || {};
  const { name: team2Name, id: team2Id } = team2 || {};
  const dispatch = useDispatch();
  let deleteDisabled = false;
  let linkedMatchAmount = 0;
  let completedMatchAmount = 0;
  matches.forEach((match) => {
    if (match.riotId) {
      linkedMatchAmount += 1;
    }
    if (match.completed) {
      completedMatchAmount += 1;
    }
  });

  if (data && data.matches) {
    const { matches } = data;
    if (
      matches.some(
        (match) => match.riotId !== undefined && match.riotId !== null
      )
    ) {
      deleteDisabled = true;
    }
  }

  let fullLink = '';
  if (window.location && window.location.pathname) {
    fullLink = `${window.location.origin}${window.location.pathname}?seriesId=${id}`;
  }

  const copyToClipboard = (copyFullLink) => {
    let link = id;
    if (copyFullLink) {
      link = fullLink;
    }
    clipboard.writeText(link);
  };

  const [disabled, setDisabled] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [sub, setSub] = React.useState();
  const [autoPostPmt, setAutoPostPmt] = useState(true);
  const [autoMarkSeriesCompleted, setAutoMarkSeriesCompleted] = useState(true);
  const handleClickOpen = (subr) => {
    setOpen(true);
    setSub(subr);
  };

  const handleClose = (postConfirmed) => {
    setOpen(false);
    if (postConfirmed) {
      dispatch(
        postPmt({
          dataId: 'postPMT',
          data: { seriesData: data, eventData, subreddit: sub },
          eventId: eventData.id
        })
      );
      disabled.push(sub);
    }
  };

  const handleSchedule = (subreddit, action) => {
    dispatch(
      schedulePmt({
        dataId: 'schedulePMT',
        data: { series: [{ seriesId: id, action }], subreddit },
        eventId: eventData.id
      })
    );
  };

  const handleToggleSeriesLive = () => {
    dispatch(
      toggleSeriesLive({
        id,
        postPMT: autoPostPmt,
        seriesData: data,
        eventData,
        eventId: eventData.id,
        markSeriesCompleted: autoMarkSeriesCompleted
      })
    );
  };

  const {
    valorant = null,
    valorantcompetitive = null,
    valorantUrl = null,
    valorantcompetitiveUrl = null
  } = pmtJson || {};

  const pmtSwitch = (subredditName, status) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Switch
        onChange={(e) =>
          handleSchedule(subredditName, e.target.checked ? 'scheduled' : null)
        }
        checked={status === 'scheduled'}
        disabled={!pmtAuthorized || status === 'posted' || postingData}
      />
      <Typography>
        {status === null && `Schedule PMT /r/${subredditName}`}
        {status === 'scheduled' && `Un-Schedule PMT for /r/${subredditName}`}
        {status === 'posted' && `PMT has been posted to /r/${subredditName}`}
      </Typography>
    </Box>
  );

  return (
    <Box>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography color="secondary">{`Series id: ${id}`}</Typography>
          <Tooltip title="Copy this series ID to clipboard">
            <IconButton onClick={() => copyToClipboard()}>
              <FileCopyIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography color="secondary">{`CMS Series Link: ${fullLink}`}</Typography>
          <Tooltip title="Copy this series ID to clipboard">
            <IconButton onClick={() => copyToClipboard(true)}>
              <FileCopyIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography color="primary">{`RIB.GG Series Link: `}</Typography>
          <MuiLink
            sx={{ marginLeft: '4px' }}
            href={`https://rib.gg/series/${id}`}
            target="_blank"
          >
            <Typography>{` https://rib.gg/series/${id}`}</Typography>
          </MuiLink>
        </Box>
        {!completed && pmtSwitch('valorantcompetitive', valorantcompetitive)}
        {completed && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleClickOpen('valorantcompetitive')}
            disabled={
              !pmtAuthorized ||
              !completed ||
              disabled.includes('valorantcompetitive') ||
              postingData
            }
          >
            Manually Post /r/valorantcompetitive PMT
          </Button>
        )}
        {valorantAuthorized && (
          <>
            {!completed && pmtSwitch('valorant', valorant)}
            {completed && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleClickOpen('valorant')}
                disabled={
                  !pmtAuthorized ||
                  !completed ||
                  disabled.includes('valorant') ||
                  postingData
                }
              >
                Manually Post /r/valorant PMT
              </Button>
            )}
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 'max-content'
          }}
        >
          <Button
            variant="outlined"
            sx={{
              color: completed || postingData ? '000' : '#fa4454',
              border: `1px solid #fa4454`
            }}
            onClick={handleToggleSeriesLive}
            disabled={
              // (completed && !live) ||
              postingData
            }
          >
            {live ? 'Turn live off' : 'Set series live'}
          </Button>
          {live &&
            (valorantcompetitive === 'scheduled' ||
              valorant === 'scheduled') && (
              <Box>
                <Checkbox
                  checked={autoPostPmt}
                  onChange={(e) => setAutoPostPmt(e.target.checked)}
                />
                <Typography variant="label">
                  Automatically post pmt when live is turned off
                </Typography>
              </Box>
            )}
          {live && (
            <Box>
              <Checkbox
                checked={autoMarkSeriesCompleted}
                onChange={(e) => setAutoMarkSeriesCompleted(e.target.checked)}
              />
              <Typography variant="label">
                Automatically mark series completed
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <EditPickBansForm
            seriesId={id}
            team1={team1}
            team2={team2}
            pickban={pickban}
          />
        </Box>
        <Dialog open={open} onClose={() => handleClose(false)}>
          <DialogTitle id="alert-dialog-title">Post PMT</DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ overflowWrap: 'break-word' }}
            >
              {(sub === 'valorant' && valorantUrl) ||
              (sub === 'valorantcompetitive' && valorantcompetitiveUrl)
                ? `${
                    sub === 'valorant' ? valorantUrl : valorantcompetitiveUrl
                  } - there already exists a /r/${sub} thread for this match. Are you sure you want to manually post another one?`
                : `Are you sure you want to post a PMT to /r/${sub}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(true)}>Yes</Button>
            <Button onClick={() => handleClose(false)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Typography>{`Best of ${bestOf}`}</Typography>
        <Typography>{`Bracket: ${bracket}`}</Typography>
        <Typography>{`Live: ${live}`}</Typography>
        <Typography>{`Stage: ${stage}`}</Typography>
        <Typography>{`Linked Matches: ${linkedMatchAmount}`}</Typography>
        <Typography>{`VLR Series ID: ${vlrId}`}</Typography>
        <Typography>{`ggbet ID: ${ggbetId}`}</Typography>
        <Typography>{`Vod URL: ${vodUrl}`}</Typography>
        <Typography>{`Completed Matches: ${completedMatchAmount}`}</Typography>
        <Divider />
        <Typography>PMT Data:</Typography>
        <Typography>
          {`/r/valorantcompetitive: ${valorantcompetitive || 'unscheduled'}`}
        </Typography>
        {valorantcompetitiveUrl && (
          <MuiLink href={valorantcompetitiveUrl}>
            <Typography>{`/r/valorantcompetitive reddit url: ${valorantcompetitiveUrl}`}</Typography>
          </MuiLink>
        )}
        <Typography>{`/r/valorant: ${valorant || 'unscheduled'}`}</Typography>
        {valorantUrl && (
          <Box>
            <Typography>/r/valorant reddit url:</Typography>
            <MuiLink href={valorantUrl}>
              <Typography>{` ${valorantUrl}`}</Typography>
            </MuiLink>
          </Box>
        )}
        <Divider />
        <Link
          to={team1Id ? `/teams/${team1Id}` : ''}
          sx={{ textDecoration: 'none' }}
        >
          <Typography color="primary">
            {`Team 1: ${team1Name || 'TBD'}`}
          </Typography>
        </Link>
        <Link
          to={team2Id ? `/teams/${team2Id}` : ''}
          sx={{ textDecoration: 'none' }}
        >
          <Typography color="primary">
            {`Team 2: ${team2Name || 'TBD'}`}
          </Typography>
        </Link>
      </Box>

      <Button
        color="primary"
        variant="contained"
        onClick={() => openDialog('editSeries', data)}
      >
        Edit
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => openDialog('deleteSeries', data.id)}
        disabled={deleteDisabled}
      >
        Delete
      </Button>
    </Box>
  );
};

export default Series;
