import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography
} from '@mui/material';
import React from 'react';
import MatchTable from './MatchTable';

const Match = (props) => {
  const { data, openDialog } = props;
  const {
    id,
    attackingFirstTeamNumber,
    winningTeamNumber,
    winCondition,
    startDate,
    mapId,
    live,
    completed,
    readyToDisplay,
    teamsInvertedInStream,
    team1Score,
    team2Score,
    riotId,
    riotSeasonId,
    vlrId,
    vodUrl
  } = data;
  const matchDetails = {
    'match Id': id,
    startDate,
    live,
    completed,
    mapId,
    readyToDisplay,
    attackingFirstTeamNumber,
    winCondition,
    winningTeamNumber,
    teamsInvertedInStream,
    team1Score,
    team2Score,
    riotId,
    vlrId,
    riotSeasonId,
    vodUrl
  };

  return (
    <>
      {data && (
        <Box>
          <Accordion
            sx={{
              width: '100%',
              border: '2px solid #c6ff00',
              borderRadius: '6px',
              marginBottom: '20px'
            }}
          >
            <AccordionSummary>
              <Typography variant="h4"> Match Details </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {Object.keys(matchDetails).map((detail) => {
                  return (
                    <Box sx={{ display: 'flex' }} key={detail}>
                      <Typography variant="body1" color="primary">
                        {`${detail}`}
                      </Typography>
                      <Typography variant="body1">=</Typography>
                      <Typography variant="body1" color="secondary">
                        {`${matchDetails[detail]}`}
                      </Typography>
                    </Box>
                  );
                })}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => openDialog('editMatch', data)}
                >
                  Edit
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              border: '2px solid #c6ff00',
              borderRadius: '6px',
              marginBottom: '20px'
            }}
          >
            <AccordionSummary>
              <Typography variant="h4"> Match Stats </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MatchTable data={data} openDialog={openDialog} />
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </>
  );
};

export default Match;
