/* eslint-disable no-console */
import { call, put } from 'redux-saga/effects';
import {
  requestGetUser,
  requestCheckIfLoggedIn,
  requestLogout
} from '../requests/user';
import { logIn, logOutSuccess } from '../../redux/ducks/user';
import { history } from '../../Components/AppWrapper';

export function* doGetUser() {
  try {
    const response = yield call(requestGetUser);
    if (response.status === 200) {
      const { data } = response;
      const { user } = data;
      if (user) {
        yield put(logIn(user));
      }
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export function* doCheckIfLoggedIn() {
  try {
    const response = yield call(requestCheckIfLoggedIn);
    if (response.status === 200) {
      console.log(response);
      const {
        data: { payload }
      } = response || {};
      const { user, redirect } = payload;
      if (user) {
        yield put(logIn(user));
      } else {
        yield put(logOutSuccess());
      }
      if (redirect) {
        history.push(redirect);
      }
    } else {
      yield put(logOutSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* doLogout() {
  try {
    const response = yield call(requestLogout);
    if (response.status === 200) {
      yield put(logOutSuccess());
      history.push('/');
    }
  } catch (error) {
    console.log(error);
  }
}
