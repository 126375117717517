import { call, put } from 'redux-saga/effects';
import { setList } from '../../redux/ducks/listSlice';
import { doGetCircuits } from '../requests/circuits';

export function* handleGetCircuits() {
  let listData = [];
  try {
    const response = yield call(doGetCircuits);
    const { data } = response;
    const { payload } = data;
    listData = payload;
  } catch (error) {
    console.log(error);
  }
  yield put(setList({ circuits: listData }));
}
