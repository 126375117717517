import { call, put } from 'redux-saga/effects';
import {
  doAddPlayerToTeam,
  doRemovePlayerFromTeam,
  doMakePlayerCaptain,
  doGetAllTeams,
  doCreateByUrl,
  doTeamAlias,
  doNewPuuid
} from '../requests/teams';
import { get } from '../../redux/ducks/currentDataSlice';
import { setList } from '../../redux/ducks/listSlice';

export function* handleAddPlayerToTeam(action) {
  const { teamId, playerObject } = action;
  try {
    yield call(doAddPlayerToTeam, teamId, playerObject);
    yield put(get({ category: 'teams', dataId: teamId }));
  } catch (error) {
    console.log(error);
  }
}

export function* handleTeamAlias(action) {
  const { teamId, alias, action: aliasAction } = action;
  try {
    yield call(doTeamAlias, teamId, alias, aliasAction);
    yield put(get({ category: 'teams', dataId: teamId }));
  } catch (error) {
    console.log(error);
  }
}

export function* handleNewPuuid(action) {
  const { playerId, puuid, action: aliasAction } = action;
  try {
    yield call(doNewPuuid, playerId, puuid, aliasAction);
    yield put(get({ category: 'players', dataId: playerId }));
  } catch (error) {
    console.log(error);
  }
}

export function* handleRemovePlayerFromTeam(action) {
  const { teamId, playerId, endDate } = action;
  try {
    yield call(doRemovePlayerFromTeam, teamId, playerId, endDate);
    yield put(get({ category: 'teams', dataId: teamId }));
  } catch (error) {
    console.log(error);
  }
}

export function* handleCreateByUrl(action) {
  const { teamUrl } = action.payload;
  try {
    yield call(doCreateByUrl, teamUrl);
  } catch (error) {
    console.log(error);
  }
}

export function* handleGetAllTeams() {
  let listData = [];
  try {
    const response = yield call(doGetAllTeams);
    const { data } = response;
    const { payload } = data;
    listData = payload;
  } catch (error) {
    console.log(error);
  }
  yield put(setList({ allTeams: listData }));
}

export function* handleMakePlayerCaptain(action) {
  const { teamId, playerId } = action;
  try {
    yield call(doMakePlayerCaptain, teamId, playerId);
    yield put(get({ category: 'teams', dataId: teamId }));
  } catch (error) {
    console.log(error);
  }
}
