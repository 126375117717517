import { createSlice } from '@reduxjs/toolkit';
import createClone from 'rfdc';

const currentDataSlice = createSlice({
  name: 'currentData',
  initialState: {},
  reducers: {
    set(state, action) {
      const { category, data, key } = action.payload;
      const keyName = key || category;
      return { ...state, [keyName]: data };
    },
    get() {},
    getMatchDetails() {},
    deleteMatch() {},
    deleteSeries() {},
    addPatch() {},
    toggleSeriesLive() {},
    setMatchDetails(state, action) {
      const { details } = action.payload;
      const { seriesId, id: matchId } = details;
      const { events: currentEventData } = state;
      const clone = createClone();
      const currentEventDataCOPY = clone(currentEventData);
      const { series } = currentEventData;
      const newSeriesList = series.map((aSeries) => {
        if (aSeries.id === seriesId) {
          const { matches } = aSeries;
          const newMatches = matches.map((match) => {
            const { id: loopMatchId } = match;
            if (loopMatchId === matchId) {
              return details;
            }
            return match;
          });
          const newMatchesCOPY = clone(newMatches);
          const aSeriesCOPY = clone(aSeries);
          const newSeries = Object.assign(aSeriesCOPY, {
            matches: newMatchesCOPY
          });
          return newSeries;
        }
        return aSeries;
      });
      const newEventdata = Object.assign(currentEventDataCOPY, {
        series: newSeriesList
      });
      return { ...state, events: newEventdata };
    }
  }
});

export const {
  get,
  set,
  getMatchDetails,
  setMatchDetails,
  toggleSeriesLive,
  deleteMatch,
  deleteSeries,
  addPatch
} = currentDataSlice.actions;
export default currentDataSlice.reducer;
