/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  AccordionActions,
  Button
} from '@mui/material';
import moment from 'moment';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { useDispatch } from 'react-redux';
import Series from './Series';
import MatchAccordion from '../matches/MatchAccordion';
import ConfirmDialog from '../shared/ConfirmDialog';
import { deleteSeries } from '../../redux/ducks/currentDataSlice';

const SeriesAccordion = (props) => {
  const { series, openDialog, eventData, linkedSeriesId } = props;
  const { team1, team2, startDate, matches, eventId, id, completed, pmtJson } =
    series;
  const { valorant, valorantcompetitive } = pmtJson || {};
  const { regionId: eventRegionId } = eventData;
  const { name: team1Name } = team1 || {};
  const { name: team2Name } = team2 || {};
  const [expanded, setExpanded] = React.useState(linkedSeriesId === `${id}`);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleChange = () => {
    setExpanded((state) => !state);
  };
  const handleDelete = () => {
    dispatch(deleteSeries({ id, eventId }));
    console.log('deleting');
  };

  const renderPmtStatus = (subredditName, status) => (
    <>
      <Box sx={{ marginLeft: '15px', display: 'flex' }}>
        {status && status === 'scheduled' && (
          <RadioButtonCheckedIcon fontSize="small" color="error" />
        )}
        {status && status === 'posted' && (
          <DoneOutlineIcon fontSize="small" color="secondary" />
        )}
        <Typography
          color={status && status === 'scheduled' ? 'error' : 'secondary'}
          sx={{ marginLeft: '5px' }}
        >
          {`${subredditName} ${status}`}
        </Typography>
      </Box>
    </>
  );

  return (
    <Accordion id={id} expanded={expanded} onChange={handleChange}>
      <AccordionSummary>
        <Box sx={{ flex: 1, display: 'flex' }}>
          <Typography>
            {`${team1Name || 'TBD'} vs ${team2Name || 'TBD'}`}
          </Typography>
          <Typography
            color={completed ? 'primary' : 'secondary'}
            sx={{ marginLeft: '15px' }}
          >
            {completed ? `Completed` : 'Not Completed'}
          </Typography>
          {valorantcompetitive &&
            renderPmtStatus('/r/valorantcompetitive', valorantcompetitive)}
          {valorant && renderPmtStatus('/r/valorant', valorant)}
        </Box>
        <Typography>
          {`id: ${id} - ${moment(startDate).format('ddd  MMMM DD YYYY')}`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ width: '100%' }}>
          <Series data={series} openDialog={openDialog} eventData={eventData} />
          <Typography variant="h6"> Matches </Typography>
          {matches && matches.length > 0 && (
            <Box>
              {matches.map((match) => (
                <Box
                  sx={{
                    marginBottom: '5px',
                    border: '2px solid #03a9f4',
                    borderRadius: '6px'
                  }}
                  key={match.id}
                >
                  <MatchAccordion
                    openDialog={openDialog}
                    eventRegionId={eventRegionId}
                    seriesId={id}
                    match={{
                      ...match,
                      eventId,
                      team1Name,
                      team2Name,
                      team1Data: team1,
                      team2Data: team2
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </AccordionDetails>
      <AccordionActions>
        <Button
          sx={{
            color: '#fa4454',
            border: `1px solid #fa4454`
          }}
          variant="outlined"
          onClick={() => setDeleteOpen(true)}
        >
          Delete SERIES
        </Button>
      </AccordionActions>
      <ConfirmDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        title="Delete Series"
        onConfirm={handleDelete}
      />
    </Accordion>
  );
};

export default SeriesAccordion;
