import React, { useEffect, useState } from 'react';
import { TextField, Box } from '@mui/material';

export default function AddRound(props) {
  const {
    editing = false,
    title: existingTitle,
    seeds: existingSeeds,
    setContentDialogValues
  } = props;

  const [round, setRound] = useState({
    title: existingTitle || 'New Round',
    seeds: existingSeeds || 1
  });

  useEffect(() => {
    setContentDialogValues({ ...round });
  }, [round, setContentDialogValues]);

  return (
    <Box>
      <TextField
        sx={{ mt: '20px' }}
        label="Round Title"
        type="text"
        defaultValue={existingTitle}
        onChange={(e) => {
          setRound((curr) => ({ ...curr, title: e.target.value }));
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
      {!editing && (
        <TextField
          sx={{ mt: '20px' }}
          label="Amount of seeds"
          type="number"
          defaultValue={1}
          onChange={(e) => {
            setRound((curr) => ({ ...curr, seeds: e.target.value }));
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
    </Box>
  );
}
