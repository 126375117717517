import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';

export function doGetCustomers() {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/cms/stripe/customers`,
    withCredentials: true
  });
}
