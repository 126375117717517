import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import React from 'react';

export default function ConfirmDialog({ open, setOpen, title, onConfirm }) {
  const handleConfirm = () => {
    setOpen(false);
    onConfirm();
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogActions>
        <Button onClick={handleConfirm}>Confirm</Button>
        <Button onClick={() => setOpen(false)} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
