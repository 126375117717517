import React from 'react';

import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import * as clipboard from 'clipboard-polyfill/text';
import { useDispatch, useSelector } from 'react-redux';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { setSnackbar } from '../../../redux/ducks/settings';
import { getAgentNameFromId } from '../../../utils/utils';

const styles = () => ({
  playerPaper: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: '#121212',
    'overflow-wrap': 'anywhere',
    width: '400px',
    height: '155px',
    margin: '5px'
  },
  puuid: {
    fontSize: '9px'
  },
  deathmatchTeamId: {
    color: '#ffef62'
  },
  redTeamId: {
    color: '#ff4569'
  },
  blueTeamId: {
    color: '#35baf6'
  },
  originalPuuid: {
    color: '#ffac33'
  }
});

const PlayerCard = props => {
  const {
    characterId,
    kills,
    deaths,
    assists,
    teamId,
    puuid,
    gameName,
    tagLine
  } = props;
  const dispatch = useDispatch();
  const agentMap = useSelector(state => state.data.agentList);
  const copyToClipboard = () => {
    clipboard.writeText(puuid);
    dispatch(setSnackbar(true, 'success', 'Puuid copied!'));
  };

  const classes = styles();
  return (
    <Paper sx={classes.playerPaper}>
      <Box>
        <Typography sx={classes.originalPuuid} variant="body1">
          {`${gameName} #${tagLine}`}
        </Typography>
        <Typography variant="body1">
          {`${getAgentNameFromId(characterId, agentMap)}`}
        </Typography>
        <Typography variant="body2" color="secondary">
          {`${kills} / ${deaths} / ${assists}`}
        </Typography>
      </Box>
      {teamId !== 'Blue' && teamId !== 'Red' ? (
        <Typography variant="caption" sx={classes.deathmatchTeamId}>
          {`Team ID: ${teamId} (deathmatch)`}
        </Typography>
      ) : (
        <Typography
          variant="caption"
          className={teamId === 'Blue' ? classes.blueTeamId : classes.redTeamId}
        >
          {`Team ID: ${teamId}`}
        </Typography>
      )}
      <Box>
        <Tooltip title="Copy this player's PUUID to clipboard">
          <IconButton>
            <FileCopyIcon fontSize="small" onClick={() => copyToClipboard()} />
          </IconButton>
        </Tooltip>
      </Box>
    </Paper>
  );
};

export default PlayerCard;
