import { call, put } from 'redux-saga/effects';
import {
  doGet,
  doCreate,
  doEdit,
  doDelete,
  doGetCountryList,
  doSearch,
  doGetAgentList,
  doGetMapList,
  doGetRegionList
} from '../requests/data';
import {
  setCountryList,
  setAgentList,
  setMapList,
  setRegionList
} from '../../redux/ducks/data';
import { addToPlayerList, setList } from '../../redux/ducks/listSlice';
import { set, get, getMatchDetails } from '../../redux/ducks/currentDataSlice';

export function* handleGet(action) {
  const { category, dataId, key } = action.payload;
  let data;
  try {
    const response = yield call(doGet, category, dataId);
    const { data: responseData } = response;
    const { payload } = responseData;
    data = payload;
  } catch (error) {
    console.log(error);
  }
  yield put(set({ category, data, key }));
}

export function* handleGetCountryList() {
  let countryList = [];
  try {
    const response = yield call(doGetCountryList);
    const { data } = response;
    const { payload } = data;
    countryList = payload;
  } catch (error) {
    console.log(error);
  }
  yield put(setCountryList(countryList));
}

export function* handleGetRegionList() {
  let regionList = [];
  try {
    const response = yield call(doGetRegionList);
    const { data } = response;
    const { payload } = data;
    regionList = payload;
  } catch (error) {
    console.log(error);
  }
  yield put(setRegionList(regionList));
}

export function* handleGetAgentList() {
  let agentList = [];
  try {
    const response = yield call(doGetAgentList);
    const { data } = response;
    const { payload } = data;
    agentList = payload;
  } catch (error) {
    console.log(error);
  }
  yield put(setAgentList(agentList));
}

export function* handleGetMapList() {
  let mapList = [];
  try {
    const response = yield call(doGetMapList);
    const { data } = response;
    const { payload } = data;
    mapList = payload;
  } catch (error) {
    console.log(error);
  }
  yield put(setMapList(mapList));
}

export function* handleCreate(action) {
  const { category, data: createData, foolData = false, puuid } = action;
  try {
    const data = yield call(doCreate, category, createData, foolData);
    if (category === 'series' || category === 'matches') {
      window.location.reload();
    } else {
      yield put(setList({ [category]: undefined }));
    }
    if (foolData) {
      yield put(addToPlayerList({ puuid, data: data.data.payload }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleSearch(action) {
  const { category, searchTerm } = action.payload;
  let listData = [];
  try {
    const searchResults = yield call(doSearch, category, searchTerm);
    const { data } = searchResults;
    const { payload } = data;
    listData = payload;
  } catch (error) {
    console.log(error);
  }
  yield put(setList({ [category]: listData }));
}

export function* handleEdit(action) {
  const { category, dataId, data, refresh } = action;
  try {
    const response = yield call(doEdit, category, dataId, data);
    if (refresh) {
      if (category === 'series') {
        const { eventId } = data;
        yield put(set({ category, data: undefined }));
        yield put(get({ category: 'events', dataId: eventId }));
      } else if (category === 'matches') {
        if (response.status === 200) {
          yield put(getMatchDetails({ id: data.id }));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleDelete(action) {
  const { category, dataId } = action;
  try {
    yield call(doDelete, category, dataId);
    if (category === 'series' || category === 'matches') {
      window.location.reload();
    }
  } catch (error) {
    console.log(error);
  }
}
