export const CHECK_IF_LOGGED_IN = 'teamly/auth/CHECK_IF_LOGGED_IN';
export const GET_USER = 'teamly/auth/GET_USER';
export const LOG_IN = 'teamly/auth/LOG_IN';
export const LOG_OUT = 'teamly/auth/LOG_OUT';
export const LOG_OUT_SUCCESS = 'teamly/auth/LOG_OUT_SUCCESS';

const initialState = {
  user: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN:
      return { ...state, authenticated: true, user: action.user };
    case LOG_OUT_SUCCESS:
      return { ...state, authenticated: false, user: undefined };
    default:
      return state;
  }
};

export const checkIfLoggedIn = () => ({
  type: CHECK_IF_LOGGED_IN
});

export const getUser = () => ({
  type: GET_USER
});

export const logIn = user => ({
  type: LOG_IN,
  user
});

export const logOut = () => ({
  type: LOG_OUT
});

export const logOutSuccess = () => ({
  type: LOG_OUT_SUCCESS
});
