import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  Select
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';

const FantasyLeagueForm = ({ league, onSubmitSuccess }) => {
  console.log({ league });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [title, setTitle] = useState('');
  const [swapDuration, setSwapDuration] = useState({ hours: 6, minutes: 30 });
  const [swapDayOfWeek, setSwapDayOfWeek] = useState(0);
  const [swapTimeOfDay, setSwapTimeOfDay] = useState(0);
  const isEditing = !!league;

  useEffect(() => {
    if (isEditing) {
      setTitle(league.title);
      setStartDate(new Date(league.startDate));
      setEndDate(new Date(league.endDate));
      setSwapDuration(league.swapDuration);
      setSwapDayOfWeek(league.swapDayOfWeek);
      setSwapTimeOfDay(league.swapTimeOfDay);
    }
  }, [league, isEditing]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
      swapDuration: `${swapDuration.hours} hours ${swapDuration.minutes} minutes`,
      swapDayOfWeek: Number(swapDayOfWeek),
      swapTimeOfDay: Number(swapTimeOfDay),
      title
    };

    try {
      const response = isEditing
        ? await axios.put(
            `${backendBaseUrl}/cms/fantasy/${league.id}`,
            formData,
            { withCredentials: true }
          )
        : await axios.post(`${backendBaseUrl}/cms/fantasy`, formData, {
            withCredentials: true
          });

      onSubmitSuccess(response.data); // Call the passed-in callback function
    } catch (error) {
      console.error('Failed to submit fantasy league', error);
      // Handle error (e.g., show error message)
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Typography variant="h6" gutterBottom>
        {isEditing ? 'Edit Fantasy League' : 'Create Fantasy League'}
      </Typography>
      <DatePicker selected={startDate} onChange={setStartDate} />
      <DatePicker selected={endDate} onChange={setEndDate} />
      <TextField
        margin="normal"
        required
        fullWidth
        label="League Name"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Typography sx={{ mt: 6 }}>
        {' '}
        NOTE: Swap times are in UTC so plan accordingly
      </Typography>
      <Select
        margin="normal"
        required
        fullWidth
        label="Swap Day Of Week"
        value={swapDayOfWeek}
        onChange={(e) => setSwapDayOfWeek(e.target.value)}
      >
        <MenuItem value={0}>Sunday</MenuItem>
        <MenuItem value={1}>Monday</MenuItem>
        <MenuItem value={2}>Tuesday</MenuItem>
        <MenuItem value={3}>Wednesday</MenuItem>
        <MenuItem value={4}>Thursday</MenuItem>
        <MenuItem value={5}>Friday</MenuItem>
        <MenuItem value={6}>Saturday</MenuItem>
      </Select>
      <Select
        margin="normal"
        required
        fullWidth
        label="Swap Time Of Day Start"
        value={swapTimeOfDay}
        onChange={(e) => setSwapTimeOfDay(e.target.value)}
      >
        {Array.from({ length: 24 }, (_, i) => (
          <MenuItem key={i} value={i}>
            {`${i}:00 UTC`}
          </MenuItem>
        ))}
      </Select>
      <TextField
        margin="normal"
        required
        fullWidth
        label="Swap Duration (hours)"
        value={swapDuration.hours}
        onChange={(e) =>
          setSwapDuration((prevState) => ({
            ...prevState,
            hours: e.target.value
          }))
        }
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="Swap Duration (minutes)"
        value={swapDuration.minutes}
        onChange={(e) =>
          setSwapDuration((prevState) => ({
            ...prevState,
            minutes: e.target.value
          }))
        }
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        {isEditing ? 'Save Changes' : 'Create League'}
      </Button>
    </Box>
  );
};

export default FantasyLeagueForm;
