/* eslint-disable prefer-object-spread */
/* eslint-disable prefer-destructuring */
// eslint-disable-next-line import/no-extraneous-dependencies
import { produce } from 'immer';
import AddRound from './dialogContent/AddRound';
import AddGroup from './dialogContent/AddGroup';
import AddSeed from './dialogContent/AddSeed';
import AddTeam from './dialogContent/AddTeam';
import AddMatch from './dialogContent/AddMatch';
import AddWeeklySeries from './dialogContent/AddWeeklySeries';

export default function useBracketBuilder(setBracket) {
  const builder = {
    addRound: ({ stage, title, position, seeds }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        if (position === 'start') {
          newBracket[stage].unshift({
            title,
            seeds: Array(parseInt(seeds, 10)).fill({
              teams: [{}, {}]
            })
          });
        } else {
          newBracket[stage].push({
            title,
            seeds: Array(parseInt(seeds, 10)).fill({
              teams: [{}, {}]
            })
          });
        }
        return newBracket;
      });
    },
    moveRound: ({ stage, roundId, direction }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        const temp = newBracket[stage][roundId];
        if (direction === 'left') {
          newBracket[stage][roundId] = newBracket[stage][roundId - 1];
          newBracket[stage][roundId - 1] = temp;
        } else {
          newBracket[stage][roundId] = newBracket[stage][roundId + 1];
          newBracket[stage][roundId + 1] = temp;
        }
        return newBracket;
      });
    },
    deleteRound: ({ stage, roundId }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        newBracket[stage].splice(roundId, 1);
        return newBracket;
      });
    },
    editSeed: ({ stage, roundId, seedId, seriesData }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        newBracket[stage][roundId].seeds[seedId] = {
          ...seriesData
        };
        return newBracket;
      });
    },
    swapTeams: ({ stage, roundId, seedId }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        const temp = newBracket[stage][roundId].seeds[seedId].teams[0];
        newBracket[stage][roundId].seeds[seedId].teams[0] =
          newBracket[stage][roundId].seeds[seedId].teams[1];
        newBracket[stage][roundId].seeds[seedId].teams[1] = temp;
        return newBracket;
      });
    },
    addGroup: ({ title, position }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));

        if (position === 'start') {
          newBracket?.groups.unshift({
            title,
            teams: [],
            seeds: []
          });
        } else {
          newBracket?.groups.push({
            title,
            teams: [],
            seeds: []
          });
        }
        return newBracket;
      });
    },
    editRound: ({ stage, roundId, title }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        newBracket[stage][roundId].title = title;
        return newBracket;
      });
    },
    addSeed: ({ stage, position, roundId, seriesData }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        if (position === 'start') {
          newBracket[stage][roundId].seeds.unshift(seriesData);
        } else {
          newBracket[stage][roundId].seeds.push(seriesData);
        }
        return newBracket;
      });
    },
    moveSeed: ({ stage, roundId, direction, seedId }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        const temp = newBracket[stage][roundId].seeds[seedId];
        if (direction === 'up') {
          newBracket[stage][roundId].seeds[seedId] =
            newBracket[stage][roundId].seeds[seedId - 1];
          newBracket[stage][roundId].seeds[seedId - 1] = temp;
        } else {
          newBracket[stage][roundId].seeds[seedId] =
            newBracket[stage][roundId].seeds[seedId + 1];
          newBracket[stage][roundId].seeds[seedId + 1] = temp;
        }
        return newBracket;
      });
    },
    deleteSeed: ({ stage, roundId, seedId }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        newBracket[stage][roundId].seeds.splice(seedId, 1);
        return newBracket;
      });
    },
    deleteGroup: ({ groupId }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        newBracket.groups.splice(groupId, 1);
        return newBracket;
      });
    },
    addTeam: ({ groupIdx, team }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        newBracket?.groups?.[groupIdx]?.teams?.push(team);
        return newBracket;
      });
    },
    deleteTeam: ({ groupIdx, teamId }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        const teamIdx = newBracket.groups[groupIdx].teams.findIndex(
          (team) => team.id === teamId
        );
        if (teamIdx !== -1) {
          newBracket.groups[groupIdx].teams.splice(teamIdx, 1);
        }

        // Iterate over all groups
        newBracket.groups.forEach((group, groupIndex) => {
          // Filter out the seeds that include the team being deleted
          const filteredSeeds = group.seeds.filter(
            (seed) => seed.team1Id !== teamId && seed.team2Id !== teamId
          );

          // Assign the filtered array back to the group's seeds
          newBracket.groups[groupIndex].seeds = filteredSeeds;
        });

        return newBracket;
      });
    },
    changeTeamQualified: ({ groupIdx, teamId }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        const teamIdx = newBracket.groups[groupIdx].teams.findIndex(
          (team) => team.id === teamId
        );
        if (teamIdx !== -1) {
          newBracket.groups[groupIdx].teams[teamIdx].qualified =
            !newBracket.groups[groupIdx].teams[teamIdx].qualified;
        }
        return newBracket;
      });
    },
    changeTeamNonQualified: ({ groupIdx, teamId }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        const teamIdx = newBracket.groups[groupIdx].teams.findIndex(
          (team) => team.id === teamId
        );
        if (teamIdx !== -1) {
          newBracket.groups[groupIdx].teams[teamIdx].nonQualified =
            !newBracket.groups[groupIdx].teams[teamIdx].nonQualified;
        }
        return newBracket;
      });
    },
    addMatch: (payload) => {
      const { groupIdx, series } = payload;
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        series.forEach((seriesData) => {
          // Check if series already exists in the group
          const seriesExists = newBracket.groups[groupIdx].seeds.some(
            (seed) => seed.id === seriesData.id
          );
          // If series does not exist, add it to the group
          if (!seriesExists) {
            newBracket.groups[groupIdx].seeds.push({ ...seriesData });
          }
          // Add teams of the series to the group
          seriesData.teams.forEach((team) => {
            // Check if team already exists in the group
            const teamExists = newBracket.groups[groupIdx].teams.some(
              (groupTeam) => groupTeam.id === team.id
            );
            // If team does not exist, add it to the group
            if (!teamExists) {
              newBracket.groups[groupIdx].teams.push(team);
            }
          });
        });
        return newBracket;
      });
    },
    deleteMatch: ({ groupIdx, matchIdx }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        newBracket.groups[groupIdx].seeds.splice(matchIdx, 1);
        return newBracket;
      });
    },
    editGroup: ({ groupId, title }) => {
      setBracket((prevBracket) => {
        const newBracket = JSON.parse(JSON.stringify(prevBracket));
        newBracket.groups[groupId].title = title;
        return newBracket;
      });
    },
    addWeek: ({ title, position }) => {
      setBracket((prevBracket) =>
        produce(prevBracket, (draft) => {
          const newWeek = {
            id: draft.weekly.weeks.length,
            title,
            series: []
          };

          if (position === 'start') {
            draft.weekly.weeks.unshift(newWeek);
          } else {
            draft.weekly.weeks.push(newWeek);
          }
        })
      );
    },
    editWeek: ({ weekId, title: newTitle }) => {
      setBracket((prevBracket) =>
        produce(prevBracket, (draft) => {
          const weekIndex = draft.weekly.weeks.findIndex(
            (week) => week.id === weekId
          );
          if (weekIndex !== -1) {
            draft.weekly.weeks[weekIndex].title = newTitle;
          }
        })
      );
    },
    addWeekSeries: (props) => {
      setBracket((prevBracket) =>
        produce(prevBracket, (draft) => {
          const { weekId, seriesData: newSeriesArray } = props;

          const weekIndex = draft.weekly.weeks.findIndex(
            (week) => week.id === weekId
          );

          if (weekIndex !== -1) {
            draft.weekly.weeks[weekIndex].series.push(...newSeriesArray);
          }
        })
      );
    },
    deleteWeekSeries: ({ weekId, seriesId }) => {
      setBracket((prevBracket) =>
        produce(prevBracket, (draft) => {
          const weekIndex = draft.weekly.weeks.findIndex(
            (week) => week.id === weekId
          );
          if (weekIndex !== -1) {
            draft.weekly.weeks[weekIndex].series = draft.weekly.weeks[
              weekIndex
            ].series.filter((series) => series.id !== seriesId);
          }
        })
      );
    },
    deleteWeek: ({ weekId }) => {
      setBracket((prevBracket) =>
        produce(prevBracket, (draft) => {
          draft.weekly.weeks = draft.weekly.weeks.filter(
            (week) => week.id !== weekId
          );
        })
      );
    },
    editStandingOptions: ({ standingOptions }) => {
      console.log({ standingOptions });
      setBracket((prevBracket) =>
        produce(prevBracket, (draft) => {
          draft.weekly.standingOptions = standingOptions;
        })
      );
    }
  };
  return {
    deleteSeed: {
      title: 'Delete this seed',
      message: 'Are you sure you want to delete this seed?',
      action: builder.deleteSeed
    },
    editSeed: {
      title: 'Edit a seed',
      message: 'Are you sure you want to edit this seed?',
      action: builder.editSeed,
      Content: AddSeed
    },
    deleteGroup: {
      title: 'Delete this group',
      message: 'Are you sure you want to delete this group?',
      action: builder.deleteGroup
    },
    addGroup: {
      title: 'Add a new Group',
      message: 'Are you sure you want to add a new group?',
      action: builder.addGroup,
      Content: AddGroup
    },
    deleteTeam: {
      title: 'Delete this team',
      message: 'Are you sure you want to delete this team?',
      action: builder.deleteTeam
    },
    addTeam: {
      title: 'Add a new team',
      message: 'Are you sure you want to add a new team?',
      action: builder.addTeam,
      Content: AddTeam
    },
    deleteMatch: {
      title: 'Delete this match',
      message: 'Are you sure you want to delete this match?',
      action: builder.deleteMatch
    },
    addMatch: {
      title: 'Add a new match to this group',
      message: 'Are you sure you want to add a new match to this group?',
      action: builder.addMatch,
      Content: AddMatch
    },
    deleteRound: {
      title: 'Delete this round',
      message: 'Are you sure you want to delete this round?',
      action: builder.deleteRound
    },
    addRound: {
      title: 'Add a new round',
      message: 'Are you sure you want to add a new round?',
      action: builder.addRound,
      Content: AddRound
    },
    editRound: {
      title: 'Edit a round',
      message: 'Are you sure you want to edit this round?',
      action: builder.editRound,
      Content: AddRound
    },
    moveSeed: {
      title: 'Move seed',
      message: 'Are you sure you want to move this seed?',
      action: builder.moveSeed
    },
    addSeed: {
      title: 'Add seed',
      message: 'Are you sure you want to add this seed?',
      action: builder.addSeed,
      Content: AddSeed
    },
    swapTeams: {
      title: 'Swap teams',
      message: 'Are you sure you want to swap these teams?',
      action: builder.swapTeams
    },
    qualifyTeam: {
      title: 'Qualify team',
      message: 'Are you sure you want to qualify this team?',
      action: builder.changeTeamQualified,
      skipContent: true
    },
    nonQualifyTeam: {
      title: 'Non-qualify team',
      message: 'Are you sure you want to non-qualify this team?',
      action: builder.changeTeamNonQualified,
      skipContent: true
    },
    moveRound: {
      title: 'Move round',
      message: 'Are you sure you want to move this round?',
      action: builder.moveRound
    },
    editGroup: {
      title: 'Edit this group',
      message: 'Are you sure you want to edit this group?',
      action: builder.editGroup,
      Content: AddGroup
    },
    addWeek: {
      title: 'Add a new week',
      message: 'Are you sure you want to add a new week?',
      action: builder.addWeek,
      Content: AddGroup
    },
    addWeekSeries: {
      title: 'Add a new series to this week',
      message: 'Are you sure you want to add a new series?',
      action: builder.addWeekSeries,
      Content: AddWeeklySeries
    },
    deleteWeekSeries: {
      title: 'Delete this series',
      message: 'Are you sure you want to delete this series from this week?',
      action: builder.deleteWeekSeries
    },
    deleteWeek: {
      title: 'Delete a new week',
      message: 'Are you sure you want to delete this week?',
      action: builder.deleteWeek
    },
    editWeek: {
      title: 'Edit this week',
      message: 'Are you sure you want to edit this week?',
      action: builder.editWeek,
      Content: AddGroup
    },
    editStandingOptions: builder.editStandingOptions
  };
}
