import React, { useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Drawer from './drawer/Drawer';
import AppRoutes from './layout/AppRoutes';
import Snackbar from './snackbar/Snackbar';
import {
  generateAxiosInterceptors,
  backendBaseUrl
} from '../constants/constants';
import { setSnackbar } from '../redux/ducks/settings';
import { checkIfLoggedIn } from '../redux/ducks/user';
import {
  getAgentList,
  getCountryList,
  getMapList,
  getRegionList
} from '../redux/ducks/data';

const styles = () => ({
  root: {
    minHeight: '100vh',
    display: 'flex'
  },
  content: {
    paddingRight: '75px',
    paddingLeft: '75px',
    paddingTop: '25px',
    width: '100%'
  }
});

export default function App() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const classes = styles();

  const showSnackbar = (snackbar) => {
    const { type, message } = snackbar;
    dispatch(setSnackbar(true, type, message));
  };

  useEffect(() => {
    dispatch(checkIfLoggedIn());
    generateAxiosInterceptors(history, showSnackbar);
  }, []);

  useEffect(() => {
    dispatch(getCountryList());
  }, []);

  useEffect(() => {
    dispatch(getRegionList());
  }, []);

  useEffect(() => {
    dispatch(getAgentList());
  }, []);

  useEffect(() => {
    dispatch(getMapList());
  }, []);

  const { user } = useSelector((state) => state.user);
  const discordAuthHref = `${backendBaseUrl}/auth/discord/login`;

  // eslint-disable-next-line no-nested-ternary
  return user && (user.cmsAuthorized || user.pmtAuthorized) ? (
    <>
      <Box sx={classes.root}>
        <Drawer />
        <Box sx={classes.content}>
          <AppRoutes />
        </Box>
      </Box>
      <Snackbar />
    </>
  ) : user && !user.cmsAuthorized && !user.pmtAuthorized ? (
    <Typography variant="h1">
      You are not authorized. Access has been requested. Contact Adawg to
      approve you.
    </Typography>
  ) : (
    <Button variant="contained" color="primary" href={discordAuthHref}>
      Log in with Discord
    </Button>
  );
}
