/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  Typography,
  Tooltip
} from '@mui/material';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getData, setData } from '../../redux/ducks/riot';
import {
  serverRegionListToEventRegion,
  valRegionList
} from '../../constants/constants';
import Histories from './Histories';
import Review from './Review';

export default function ConnectMatchDialog(props) {
  const {
    open,
    handleClose,
    team1Data,
    team2Data,
    eventRegionId,
    startDate,
    cmsId
  } = props;

  const { id: team1Id, name: team1Name } = team1Data;
  const { id: team2Id, name: team2Name } = team2Data;
  const dispatch = useDispatch();
  const [stage, setStage] = useState('histories');
  const [selectedMatchId, setSelectedMatchId] = useState();
  const [realm, setRealm] = useState(
    serverRegionListToEventRegion[eventRegionId] || valRegionList[6]
  );
  useEffect(() => {
    dispatch(
      setData({
        team1ConnectionData: undefined,
        team2ConnectionData: undefined
      })
    );
    dispatch(getData('getTeamHistories', { team1Id, team2Id, realm }));
  }, [realm]);

  useEffect(() => {
    return () => {
      dispatch(
        setData({
          team1ConnectionData: undefined,
          team2ConnectionData: undefined
        })
      );
    };
  }, []);

  return (
    <MuiDialog
      sx={{
        padding: '20px'
      }}
      maxWidth="lg"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1 }}>
            <Typography>
              {startDate
                ? moment(startDate).format('MMMM Do hh:mm A z')
                : 'No start date added'}
            </Typography>
            <Box sx={{ display: 'flex', whiteSpace: 'break-spaces' }}>
              <Tooltip title={`TeamId: ${team1Id}`}>
                <Typography color="primary">{team1Name} </Typography>
              </Tooltip>
              <Typography>vs </Typography>
              <Tooltip title={`TeamId: ${team2Id}`}>
                <Typography color="secondary">{team2Name} </Typography>
              </Tooltip>
            </Box>
          </Box>
          <Select
            value={realm}
            onChange={(event) => setRealm(event.target.value)}
          >
            {valRegionList.map((region) => (
              <MenuItem value={region} key={region}>
                {region}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </DialogTitle>
      <DialogContent>
        {stage === 'histories' && (
          <Histories
            setStage={setStage}
            setSelectedMatchId={setSelectedMatchId}
          />
        )}
        {stage === 'review' && (
          <Review
            selectedMatchId={selectedMatchId}
            setStage={setStage}
            setSelectedMatchId={setSelectedMatchId}
            realm={realm}
            handleClose={handleClose}
            cmsId={cmsId}
          />
        )}
      </DialogContent>
    </MuiDialog>
  );
}
