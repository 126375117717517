import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Box,
  Button,
  TextField,
  Select,
  MenuItem
} from '@mui/material';

import { valRegionList } from '../../../constants/constants';
import { postData } from '../../../redux/ducks/riot';

const styles = () => ({
  searchBar: {
    marginTop: '10px',
    width: '600px',
    alignItems: 'center'
  },
  select: {
    width: '100px',
    marginLeft: '10px'
  }
});

const connectMatch = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const [riotMatchId, setRiotMatchId] = useState('');
  const [dbMatchId, setDbMatchId] = useState('');
  const [realm, setRealm] = useState(valRegionList[0]);

  return (
    <Box>
      <Typography variant="h4"> Riot Match History Search</Typography>
      <Box sx={classes.searchBar}>
        <TextField
          inputProps={{
            onChange: event => setDbMatchId(event.target.value)
          }}
          label="CMS Match ID"
          variant="outlined"
          fullWidth
          value={dbMatchId}
        />
        <TextField
          inputProps={{
            onChange: event => setRiotMatchId(event.target.value)
          }}
          label="Riot Match ID"
          variant="outlined"
          fullWidth
          value={riotMatchId}
        />
        <Select
          sx={classes.select}
          value={realm}
          onChange={event => setRealm(event.target.value)}
        >
          {valRegionList.map(region => (
            <MenuItem value={region} key={region}>
              {region}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Button
        onClick={() => {
          dispatch(
            postData('connectMatch', {
              riotMatchId,
              dbMatchId,
              realm
            })
          );
        }}
        disabled={!(riotMatchId && dbMatchId && realm)}
        sx={classes.button}
        variant="contained"
        color="primary"
      >
        Connect match (click once and wait at least 20 seconds)
      </Button>
    </Box>
  );
};

export default connectMatch;
