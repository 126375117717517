import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
  IconButton,
  Collapse,
  Tooltip
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useDispatch, useSelector } from 'react-redux';
import * as clipboard from 'clipboard-polyfill/text';
import Autocomplete from '@mui/lab/Autocomplete';
import { getData, postData, setData } from '../../redux/ducks/riot';
import SearchField from '../layout/SearchField';
import DensePlayerForm from '../players/DensePlayerForm';
import { setList } from '../../redux/ducks/listSlice';
import { setSnackbar } from '../../redux/ducks/settings';

const styles = () => ({
  container: {
    display: 'flex'
  },
  playerContainer: {
    padding: '10px',
    margin: '2px',
    backgroundColor: `#303030`,
    minHeight: '51px',
    textAlign: 'center',
    flex: 1,
    width: '500px',
    display: 'flex'
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  redTeam: {
    color: '#ff4569'
  },
  blueTeam: {
    color: '#35baf6'
  },
  agentContainer: {
    textAlign: 'left',
    width: '150px'
  },
  playerPickerContainer: {
    flex: '1',
    textAlign: 'left',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center'
  }
});

const Review = (props) => {
  const classes = styles();
  const dispatch = useDispatch();

  const { match, gettingData, team1ConnectionData, team2ConnectionData } =
    useSelector((state) => state.riot);

  const {
    players: team1Players,
    name: team1Name,
    id: team1Id
  } = team1ConnectionData || {};
  const {
    players: team2Players,
    name: team2Name,
    id: team2Id
  } = team2ConnectionData || {};

  const {
    setSelectedMatchId,
    setStage,
    selectedMatchId: matchId,
    realm,
    handleClose,
    cmsId: dbCmsId
  } = props;
  const {
    players: riotPlayers,
    matchInfo,
    teams,
    matchingPlayers
  } = match || {};
  console.log(matchingPlayers);
  const riotRedTeam = teams && teams.filter((t) => t.teamId === 'Red')[0];
  const riotBlueTeam = teams && teams.filter((t) => t.teamId === 'Blue')[0];

  const { roundsWon: redTeamRoundsWon } = riotRedTeam || {};
  const { roundsWon: blueTeamRoundsWon } = riotBlueTeam || {};

  const { mapId } = matchInfo || {};
  const { mapList, agentList } = useSelector((state) => state.data);
  const { puuidPlayers } = useSelector((state) => state.list);
  const { postingData } = useSelector((state) => state.riot);

  const [puuidMap, setPuuidMap] = useState({});

  const copyToClipboard = (copiedPuuid) => {
    clipboard.writeText(copiedPuuid);
    dispatch(setSnackbar(true, 'success', 'Puuid copied!'));
  };

  const [team1Red, setTeam1Red] = useState();

  const [searching, setSearching] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  const selectPlayer = (playerData, puuid) => {
    const tempPuuidMap = { ...puuidMap };
    tempPuuidMap[puuid] = {
      ...tempPuuidMap[puuid],
      cmsData: playerData
      // cmsData: { id: '4', ign: 'poop' } // use this to test
    };
    setPuuidMap(tempPuuidMap);
  };

  const unSelectPlayer = (puuid) => {
    const tempPuuidMap = { ...puuidMap };
    delete tempPuuidMap[puuid].cmsData;
    setPuuidMap(tempPuuidMap);
  };

  useEffect(() => {
    Object.keys(puuidPlayers).forEach((puuid) => {
      selectPlayer(puuidPlayers[puuid], puuid);
    });
    setAddingNew();
  }, [puuidPlayers]);

  useEffect(() => {
    return () => {
      dispatch(
        setData({
          match: undefined,
          team1ConnectionData: undefined,
          team2ConnectionData: undefined
        })
      );
      dispatch(setList({ puuidPlayers: {} }));
    };
  }, []);

  useEffect(() => {
    dispatch(getData('getIndividualMatch', { realm, matchId }));
  }, [matchId]);

  useEffect(() => {
    if (riotPlayers) {
      const team1PlayersTagged = team1Players.map((p) => ({
        ...p,
        teamId: team1Id
      }));
      const team2PlayersTagged = team2Players.map((p) => ({
        ...p,
        teamId: team2Id
      }));
      const allPlayers = [...team1PlayersTagged, ...team2PlayersTagged];
      const puuidMapTemp = {};

      riotPlayers.forEach((player) => {
        if (player.teamId === 'Blue' || player.teamId === 'Red') {
          puuidMapTemp[player.puuid] = { riotData: player };
        }
      });
      allPlayers.forEach((player) => {
        const { previousRiotPlayerIds } = player;
        previousRiotPlayerIds &&
          previousRiotPlayerIds.forEach((puuid) => {
            if (Object.keys(puuidMapTemp).includes(puuid)) {
              if (puuidMapTemp[puuid].cmsData) {
                puuidMapTemp[puuid] = {
                  ...puuidMapTemp[puuid],
                  autoFilled: false
                };
              } else {
                puuidMapTemp[puuid] = {
                  ...puuidMapTemp[puuid],
                  cmsData: player,
                  autoFilled: true
                };
              }
              if (player.teamId === team1Id) {
                if (puuidMapTemp[puuid].riotData.teamId === 'Blue') {
                  // console.log('players team is 1 and is blue');
                  setTeam1Red(false);
                } else {
                  // console.log('players team is 1 and is red');
                  setTeam1Red(true);
                }
              }
              if (player.teamId === team2Id) {
                if (puuidMapTemp[puuid].riotData.teamId === 'Blue') {
                  // console.log('players team is 2 and is blue');
                  setTeam1Red(true);
                } else {
                  // console.log('players team is 2 and is red');
                  setTeam1Red(false);
                }
              }
            }
          });
      });

      Object.keys(puuidMapTemp).forEach((puuid) => {
        if (
          !puuidMapTemp[puuid].cmsData &&
          matchingPlayers &&
          matchingPlayers[puuid].length > 0
        ) {
          puuidMapTemp[puuid] = {
            ...puuidMapTemp[puuid],
            cmsData: matchingPlayers[puuid][0]
          };
        }
      });
      setPuuidMap(puuidMapTemp);
    }
  }, [riotPlayers]);

  const handleBack = () => {
    setSelectedMatchId();
    setStage('histories');
  };

  const allConnected = Object.keys(puuidMap).every(
    (puuid) => puuidMap[puuid].cmsData
  );

  const handleSubmit = () => {
    const { players: matchPlayers, teams: matchTeams } = match;
    const newPlayers = matchPlayers.map((p) => {
      const { puuid } = p;
      return {
        ...p,
        cmsId:
          puuidMap[puuid] && puuidMap[puuid].cmsData
            ? puuidMap[puuid].cmsData.id
            : undefined
      };
    });
    const newTeams = matchTeams.map((t) => {
      const { teamId } = t;
      if (teamId === 'Red' && team1Red) {
        return { ...t, cmsId: team1Id };
      }
      if (teamId === 'Red' && !team1Red) {
        return { ...t, cmsId: team2Id };
      }
      if (teamId === 'Blue' && team1Red) {
        return { ...t, cmsId: team2Id };
      }
      return { ...t, cmsId: team1Id };
    });
    const submittedMatchInfo = {
      ...match,
      players: newPlayers,
      teams: newTeams
    };
    delete submittedMatchInfo.matchingPlayers;
    dispatch(
      postData('quickConnectMatch', {
        riotMatchData: submittedMatchInfo,
        dbMatchId: dbCmsId,
        realm
      })
    );
    // handleClose();
  };

  useEffect(() => {
    if (postingData === false) {
      dispatch(setData({ postingData: undefined }));
      handleClose();
    }
  }, [postingData]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Button
          onClick={handleBack}
          startIcon={<ArrowBackIcon size="small" />}
          variant="outlined"
          size="small"
        >
          Back
        </Button>
      </Box>
      <Box sx={classes.contentContainer}>
        <Typography variant="h5" color="primary">
          Review
        </Typography>
        <Typography sx={{ textAlign: 'center' }} variant="h4">
          {mapList &&
            mapId &&
            `${
              mapList &&
              mapId &&
              mapList.filter((map) => map.riotId === mapId)[0].name
            }`}
        </Typography>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          {!postingData ? (
            <Button
              disabled={!mapId || !allConnected || team1Red === undefined}
              variant="outlined"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Box>
        {team1Red !== undefined && (
          <>
            <Box sx={classes.topContainer}>
              <Typography variant="h5" sx={classes.redTeam}>
                {team1Red ? team1Name : team2Name}
              </Typography>
              <Typography variant="h5" sx={classes.blueTeam}>
                {team1Red ? team2Name : team1Name}
              </Typography>
            </Box>
            <Box sx={classes.topContainer}>
              <Typography variant="h6">{redTeamRoundsWon}</Typography>
              <Typography variant="h6">{blueTeamRoundsWon}</Typography>
            </Box>
          </>
        )}
        {gettingData && <CircularProgress />}
        {!gettingData && !match && (
          <Typography>
            {`Error getting match data from Riot's API, contact adawg`}
          </Typography>
        )}
        {match && puuidMap && Object.keys(puuidMap).length > 0 && (
          <>
            <Box sx={classes.container}>
              {['Red', 'Blue'].map((color) => (
                <Box sx={classes.team}>
                  {Object.keys(puuidMap).map((puuid) => {
                    const { riotData, cmsData, autoFilled } = puuidMap[puuid];
                    const { gameName, teamId, characterId, stats } = riotData;
                    const { kills, deaths, assists } = stats || {};

                    let cmsTeamData;
                    if (teamId === 'Red' && team1Red) {
                      cmsTeamData = team1ConnectionData;
                    } else if (teamId === 'Red' && !team1Red) {
                      cmsTeamData = team2ConnectionData;
                    } else if (teamId === 'Blue' && team1Red) {
                      cmsTeamData = team2ConnectionData;
                    } else {
                      cmsTeamData = team1ConnectionData;
                    }
                    const {
                      id: cmsTeamId,
                      name: cmsTeamName,
                      countryId: cmsCountryId
                    } = cmsTeamData;

                    const { ign, id: cmsId } = cmsData || {};
                    const playerAgent = agentList.filter(
                      (agent) =>
                        agent &&
                        agent.riotId &&
                        characterId &&
                        agent.riotId.toLowerCase() === characterId.toLowerCase()
                    );

                    const matchingPlayersFromPuuid = matchingPlayers[puuid];

                    const handleOnClick = (playerData) => {
                      selectPlayer(playerData, puuid);
                      setSearching(false);
                    };

                    const { name } = (playerAgent && playerAgent[0]) || {};
                    let agentImgUrl;
                    if (name) {
                      agentImgUrl = `/assets/${name
                        .replace(/\//g, '')
                        .toLowerCase()}.png`;
                    }
                    if (teamId === color) {
                      return (
                        <Box sx={classes.playerContainer}>
                          <Box sx={classes.agentContainer}>
                            {agentImgUrl && (
                              <img
                                src={agentImgUrl}
                                style={{ width: '40px' }}
                                alt={name}
                              />
                            )}

                            <Tooltip
                              title={`Click to copy puuid to cliboard - puuid: ${puuid}`}
                            >
                              <Typography
                                className={
                                  color === 'Blue'
                                    ? classes.blueTeam
                                    : classes.redTeam
                                }
                                sx={{ cursor: 'pointer' }}
                                onClick={() => copyToClipboard(puuid)}
                              >
                                {gameName}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box sx={classes.playerPickerContainer}>
                            {puuidMap[puuid].cmsData && (
                              <CheckCircle color="secondary" />
                            )}
                            {autoFilled && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  width: '100%',
                                  whiteSpace: 'break-spaces',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography>{ign}</Typography>
                                <Typography variant="caption" color="primary">
                                  {`cms id: ${cmsId}`}
                                </Typography>
                              </Box>
                            )}
                            {!autoFilled &&
                              matchingPlayersFromPuuid.length > 0 && (
                                <Box sx={{ width: '200px' }}>
                                  <Autocomplete
                                    value={puuidMap[puuid].cmsData}
                                    options={matchingPlayersFromPuuid}
                                    disableClearable
                                    onChange={(_, option) => {
                                      selectPlayer(option, puuid);
                                    }}
                                    sx={{ width: 'inherit' }}
                                    getOptionLabel={(option) =>
                                      option && option.ign
                                    }
                                    renderOption={(option) => (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: '100%',
                                          justifyContent: 'space-between'
                                        }}
                                      >
                                        <Typography>{option.ign}</Typography>
                                        <Typography variant="caption">
                                          {`player id: ${option.id}`}
                                        </Typography>
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={
                                          matchingPlayersFromPuuid &&
                                          matchingPlayersFromPuuid.length > 1
                                            ? `${matchingPlayersFromPuuid.length} matching players
                                    found`
                                            : `${matchingPlayersFromPuuid.length} matching player
                                    found`
                                        }
                                        size="small"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                  <Typography variant="caption" color="primary">
                                    {`cms id: ${cmsId}`}
                                  </Typography>
                                </Box>
                              )}
                            {matchingPlayersFromPuuid.length === 0 &&
                              !cmsData && (
                                <Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() =>
                                      searching !== puuid
                                        ? setSearching(puuid)
                                        : setSearching()
                                    }
                                  >
                                    {searching !== puuid && <ExpandMoreIcon />}
                                    {searching === puuid && <ExpandLessIcon />}
                                    <Typography>Search existing</Typography>
                                  </Box>
                                  <Collapse
                                    in={searching === puuid}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <SearchField
                                      category="players"
                                      handleOnClick={handleOnClick}
                                      clearSelected
                                    />
                                  </Collapse>
                                  <Typography>or</Typography>
                                  <Button
                                    variant="outlined"
                                    onClick={() =>
                                      searching !== puuid
                                        ? setAddingNew(puuid)
                                        : setAddingNew()
                                    }
                                  >
                                    create new player
                                  </Button>
                                  <Collapse
                                    in={addingNew === puuid}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <DensePlayerForm
                                      teamId={cmsTeamId}
                                      isNew
                                      teamName={cmsTeamName}
                                      teamCountryId={cmsCountryId}
                                      gameName={gameName}
                                      puuid={puuid}
                                    />
                                  </Collapse>
                                </Box>
                              )}
                            {matchingPlayersFromPuuid.length === 0 &&
                              cmsData && (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    width: '100%',
                                    whiteSpace: 'break-spaces',
                                    alignItems: 'center'
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      unSelectPlayer(puuid);
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                  <Typography>{ign} </Typography>
                                  <Typography variant="caption" color="primary">
                                    {`cms id: ${cmsId}`}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                          <Box sx={{ alignSelf: 'center' }}>
                            <Typography color="secondary">
                              {`${kills} / ${deaths} / ${assists}`}
                            </Typography>
                          </Box>
                          {/* <Typography>{puuid}</Typography> */}
                        </Box>
                      );
                    }
                    return <></>;
                  })}
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Review;

/*
    Player object from 'players':
      puuid(pin):"RX-qYF4fDZPhWLmt-AkDRPuaRjKJK5v5JXbcGJwT_ce_4yEDNZWiH0PrwCoUIL-WlLl1cL16sjFhhQ"
      gameName(pin):"CATG reggz"
      tagLine(pin):"1102"
      teamId(pin):"Blue"
*/

/*
    Team object from 'teams':
      teamId(pin):"Red"
      won(pin):false
      roundsPlayed(pin):14
      roundsWon(pin):1
      numPoints(pin):1
*/

/*
    Team object from 'teams':
      teamId(pin):"Red"
      won(pin):false
      roundsPlayed(pin):14
      roundsWon(pin):1
      numPoints(pin):1
*/

/*
    Match Info Object:
      matchId(pin):"dbff06c0-e1dc-48f3-965d-2cd994d100e1"
      mapId(pin):"/Game/Maps/Triad/Triad"
      gameVersion(pin):"release-04.07-shipping-99000001-699063"
      gameLengthMillis(pin):1744524
      gameStartMillis(pin):1651262383583
      provisioningFlowId(pin):"CustomGame"
      isCompleted(pin):true
      customGameName(pin):""
      queueId(pin):""
      gameMode(pin):"/Game/GameModes/Bomb/BombGameMode.BombGameMode_C"
      isRanked(pin):false
      seasonId(pin):"3e47230a-463c-a301-eb7d-67bb60357d4f"
*/
