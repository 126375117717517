import React from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SearchField from './SearchField';
import { createByUrl } from '../../redux/ducks/teamSlice';

const styles = () => ({
  root: {
    display: 'flex'
  },
  button: {
    marginLeft: '15px'
  },
  listItem: {
    margin: '10px 10px 10px 0'
  },
  searchBar: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  }
});

const ListLayout = () => {
  const classes = styles();
  const { category } = useParams();
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [teamUrl, setTeamUrl] = React.useState('');
  const history = useNavigate();

  return (
    <>
      <Box sx={classes.root}>
        <Typography variant="h4"> {category} </Typography>
        <Button
          variant="outlined"
          color="primary"
          sx={classes.button}
          onClick={() => history(`/${category}/new/edit`)}
        >
          Add New
        </Button>
        {category === 'teams' && (
          <Button
            variant="outlined"
            color="primary"
            sx={classes.button}
            onClick={() => setDialogOpen(true)}
            disabled
          >
            Add Team By vlr.gg Link
          </Button>
        )}
      </Box>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Create new team</DialogTitle>
        <DialogContent sx={{ width: '400px' }}>
          <TextField
            sx={{ width: '100%' }}
            value={teamUrl}
            onChange={e => setTeamUrl(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => dispatch(createByUrl({ teamUrl }))}
          >
            ADD TEAM
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setDialogOpen(false)}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
      <SearchField category={category} linkable />
    </>
  );
};

export default ListLayout;
