import React from 'react';
import { Typography, Box, Paper, Button } from '@mui/material';

import * as clipboard from 'clipboard-polyfill/text';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../redux/ducks/settings';

const styles = () => ({
  root: {
    margin: '15px',
    padding: '15px'
    // display: 'flex'
  },
  header: {
    fontWeight: 'bold'
  }
});

const RiotPlayerCard = props => {
  const classes = styles(props);
  const { ign, tagline, puuid } = props;
  const dispatch = useDispatch();

  const copyToClipboard = () => {
    clipboard.writeText(puuid);
    dispatch(setSnackbar(true, 'success', 'Puuid copied!'));
  };
  return (
    <Paper sx={classes.root}>
      <Typography sx={classes.header} color="primary">
        {`${ign} #${tagline}`}{' '}
      </Typography>
      <Typography variant="caption"> {`PUUID: ${puuid}`} </Typography>
      <Box>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => copyToClipboard()}
        >
          Copy PUUID to clipboard
        </Button>
      </Box>
    </Paper>
  );
};

export default RiotPlayerCard;
