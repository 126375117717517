/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Divider,
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Switch,
  Box,
  createTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import RibBracket from '@runitback/bracket/RibBracket';
import RibPrizePoolEditor from '@runitback/bracket/RibPrizePoolEditor';
import RibPrizePool from '@runitback/bracket/RibPrizePool';
import StandingsTable from '@runitback/bracket/weekly/StandingsTable';
import { useQueryState } from 'react-router-use-location-state';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { baseTheme, darkPalette } from '../../themes/theme';
import MatchForm from '../matches/MatchForm';
import SeriesAccordion from '../series/SeriesAccordion';
import SeriesForm from '../series/SeriesForm';
import DeleteDialog from '../layout/DeleteDialog';
import AddPlayerDialog from '../matches/AddPlayerDialog';
import BracketBuilder from '../brackets/form/BracketBuilder';
import { edit } from '../../redux/ducks/data';
import Lineage from './Lineage';
import SearchField from '../layout/SearchField';
import useBracketBuilder from '../brackets/form/useBracketBuilder';
import { getUniqueTeams } from '../../utils/series';
import { backendBaseUrl } from '../../constants/constants';

const styles = () => ({
  img: {
    height: '50px'
  },
  deleteButton: {
    marginTop: '50px'
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px'
  }
});

const defaultBracket = {
  type: 'single',
  winners: [
    {
      seeds: [
        {
          teams: [{}, {}]
        },
        {
          teams: [{}, {}]
        }
      ],
      title: 'Upper Round 1'
    }
  ],
  losers: [
    {
      seeds: [
        {
          teams: [{}, {}]
        },
        {
          teams: [{}, {}]
        }
      ],
      title: 'Lower Round 1'
    }
  ],
  groups: [{ seeds: [], teams: [], title: 'Group A' }]
};

// const defaultPrizePool = {
//   positions: [
//     {
//       positionId: 1,
//       team: { id: 0, name: 'TBD', shortName: 'TBD', logoUrl: '' }
//     },
//     {
//       positionId: 2,
//       team: { id: 0, name: 'TBD', shortName: 'TBD', logoUrl: '' }
//     },
//     {
//       positionId: 3,
//       team: { id: 0, name: 'TBD', shortName: 'TBD', logoUrl: '' }
//     },
//     {
//       positionId: 4,
//       team: { id: 0, name: 'TBD', shortName: 'TBD', logoUrl: '' }
//     },
//     {
//       positionId: 5,
//       team: { id: 0, name: 'TBD', shortName: 'TBD', logoUrl: '' }
//     },
//     {
//       positionId: 5,
//       team: { id: 0, name: 'TBD', shortName: 'TBD', logoUrl: '' }
//     },
//     {
//       positionId: 6,
//       team: { id: 0, name: 'TBD', shortName: 'TBD', logoUrl: '' }
//     },
//     {
//       positionId: 7,
//       team: { id: 0, name: 'TBD', shortName: 'TBD', logoUrl: '' }
//     }
//   ],
//   monetaryPrizes: [],
//   circuitPointPrizes: [],
//   miscPrizes: []
// };

const defaultPrizePool = {
  positions: [
    {
      positionId: 1,
      team: {
        logoUrl: 'https://i.imgur.com/H7IBgvI.webp',
        name: 'M80',
        shortName: 'M80',
        id: 9854
      },
      color: '#CAA43D'
    }
  ],
  monetaryPrizes: [
    {
      positionId: 1,
      amountUSD: 300
    }
  ],
  circuitPointPrizes: [
    {
      circuitEventId: '2191',
      title: 'VCL NA Points',
      positions: [
        {
          positionId: 1,
          value: 20
        }
      ]
    }
  ],
  miscPrizes: [
    {
      title: 'Qualifies To',
      positions: [
        {
          positionId: 1,
          markdownValue:
            '<img src="https://i.imgur.com/Nj4gjQz.webp" width="12" height="12"> <a href="https://www.rib.gg/events/2394?stage=2413" target="_blank">Mid-Season Invitational Off</a>'
        }
      ]
    }
  ]
};

const Event = (props) => {
  const currentTheme = createTheme({ ...baseTheme, palette: darkPalette });

  const { data } = props;
  const {
    id,
    name,
    description,
    startDate,
    endDate,
    imageUrl,
    prizePool: prizePoolOld,
    prizePoolCurrency,
    eventUrl,
    series,
    countryId,
    regionId,
    rank,
    bracketJson,
    childLabel,
    parent,
    parentId,
    slug,
    shortName,
    importance,
    type,
    lineage,
    prizePoolData: prizePoolJson
  } = data;
  const classes = styles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentDialog, setCurrentDialog] = useState('');
  const [currentDialogData, setCurrentDialogData] = useState('');
  const [showUnlinked, setShowUnlinked] = useState(false);
  const [showBracket, setShowBracket] = useState(false);
  const [showBracketInfo, setShowBracketInfo] = useState(false);
  const [showBracketPreview, setShowBracketPreview] = useState(false);
  const [showPrizePool, setShowPrizePool] = useState(false);
  const [bracket, setBracket] = useState({ type: 'single' });
  const [prizePool, setPrizePool] = useState(prizePoolJson || defaultPrizePool);

  const history = useNavigate();
  const dispatch = useDispatch();

  const dialogMap = {
    newSeries: {
      title: 'Add a new series',
      child: <SeriesForm eventId={id} isNew />
    },
    editSeries: {
      title: 'Edit a series',
      child: <SeriesForm eventId={id} data={currentDialogData} />
    },
    deleteSeries: {
      title: 'Delete a series',
      child: (
        <DeleteDialog
          category="series"
          id={currentDialogData}
          handleClose={() => setDialogOpen(false)}
        />
      )
    },
    editMatch: {
      title: 'Edit match',
      child: <MatchForm eventId={id} data={currentDialogData} />
    },
    addPlayersToMatch: {
      title: 'Add players to match',
      child: (
        <AddPlayerDialog
          data={currentDialogData}
          dialogOpen={dialogOpen}
          handleClose={() => setDialogOpen(false)}
        />
      )
    }
  };
  const seriesAmount = series.length;
  let matchAmount = 0;
  let linkedMatchAmount = 0;
  // eslint-disable-next-line no-unused-vars
  let completedMatchAmount = 0;
  series.forEach((s) => {
    const { matches } = s;
    matchAmount += matches.length;
    matches.forEach((match) => {
      if (match.riotId) {
        linkedMatchAmount += 1;
      }
      if (match.completed) {
        completedMatchAmount += 1;
      }
    });
  });

  const [filter, setFilter] = useState('');

  const filteredSeries =
    filter.length > 0 || showUnlinked
      ? series.filter((serie) => {
          const {
            team1,
            team2,
            id: seriesId,
            matches
          } = serie || {
            team1: { name: '' },
            team2: { name: '' }
          };
          const { name: team1Name } = team1 || {};
          const { name: team2Name } = team2 || {};
          let hasUnlinkedMatches = false;
          if (showUnlinked) {
            matches.forEach((match) => {
              if (!match.riotId) {
                hasUnlinkedMatches = true;
              }
            });
          }
          const searchableString = `${team1Name} vs ${team2Name} ${seriesId}`;
          if (showUnlinked) {
            return (
              hasUnlinkedMatches &&
              searchableString.toLowerCase().includes(filter.toLowerCase())
            );
          }
          return searchableString.toLowerCase().includes(filter.toLowerCase());
        })
      : series;

  const openDialog = (category, dialogdata) => {
    setCurrentDialog(category);
    setCurrentDialogData(dialogdata);
    setDialogOpen(true);
  };

  const handleBracketSave = () => {
    dispatch(
      edit('events', id, { ...data, bracketJson: JSON.stringify(bracket) })
    );
  };

  const [linkedSeriesId, _] = useQueryState('seriesId', '');

  const [scrolled, setScrolled] = useState(false);
  const scrollToSection = () => {
    if (document && document.getElementById(linkedSeriesId) && !scrolled) {
      document.getElementById(linkedSeriesId).scrollIntoView();
      setScrolled(true);
    }
  };

  useEffect(() => {
    if (series && Object.keys(series).length > 0 && linkedSeriesId) {
      scrollToSection();
    }
  }, [series, linkedSeriesId]);

  useEffect(() => {
    if (bracketJson && Object.keys(bracketJson).length > 0) {
      setBracket(bracketJson);
    } else {
      setBracket(defaultBracket);
    }
  }, [bracketJson]);

  useEffect(() => {
    if (prizePoolJson && Object.keys(prizePoolJson).length > 0) {
      console.log({ prizePoolJson });
      setPrizePool(prizePoolJson);
    }
  }, [prizePoolJson]);

  const bracketBuilder = useBracketBuilder(setBracket);

  const uniqueTeams = getUniqueTeams(series);
  const [teamsForPrizePool, setTeamsForPrizePool] = useState(uniqueTeams);
  const handleSelectTeam = (team) => {
    if (!teamsForPrizePool.some((t) => t.id === team.id)) {
      const newTeam = {
        logoUrl: team.logoUrl,
        name: team.name,
        shortName: team.shortName,
        id: team.id
      };
      setTeamsForPrizePool([...teamsForPrizePool, newTeam]);
    }
  };

  useEffect(() => {
    if (prizePoolJson && Object.keys(prizePoolJson).length > 0) {
      // setPrizePool(prizePoolJson);

      // // Extract teams from the prizePoolJson
      const prizePoolTeams = new Map();
      prizePoolJson.positions.forEach((position) => {
        if (position.team.id && position.team.id !== 0) {
          // Ignoring TBD teams
          prizePoolTeams.set(position.team.id, position.team);
        }
      });

      // Merge uniqueTeams with teams from prizePoolJson
      const mergedTeams = [
        ...uniqueTeams,
        ...Array.from(prizePoolTeams.values())
      ];
      const uniqueMergedTeams = Array.from(
        new Map(mergedTeams.map((team) => [team.id, team])).values()
      );
      setTeamsForPrizePool(uniqueMergedTeams);
    }
  }, [prizePoolJson]);

  const handleSubmit = async () => {
    try {
      console.log({ prizePool });
      await axios.request({
        method: 'POST',
        url: `${backendBaseUrl}/cms/events/update-prize-pool/${id}`,
        data: {
          prizePool
        },
        withCredentials: true
      });
    } catch (error) {
      console.error('Error fetching event data', error);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center'
        }}
      >
        {imageUrl && <img src={imageUrl} style={classes.img} alt={name} />}
        <Typography variant="h5" color="secondary" sx={{ marginLeft: '10px' }}>
          {name}
        </Typography>
      </Box>
      <Lineage lineage={lineage} />
      <Divider sx={classes.divider} />
      <Link
        style={{ marginLeft: '4px' }}
        href={`https://rib.gg/events/${id}`}
        target="_blank"
      >
        <Typography>{` https://rib.gg/events/${id}`}</Typography>
      </Link>
      <Typography variant="body1">{`Short Name: ${shortName}`}</Typography>
      <Typography variant="body1">{`Type: ${type}`}</Typography>
      <Typography variant="body1">{`Parent event: ${parent}`}</Typography>
      <Typography variant="body1">{`Parent Id: ${parentId}`}</Typography>
      <Typography variant="body1">{`Child Label: ${childLabel}`}</Typography>
      <Divider sx={classes.divider} />
      <Button
        color="primary"
        variant="contained"
        onClick={() => history(`/events/${id}/edit`)}
      >
        Edit
      </Button>
      <Typography> {description} </Typography>
      <Typography variant="body1">{`Event Importance: ${importance}`}</Typography>
      <Typography variant="body1">{`Event Rank: ${rank}`}</Typography>
      <Typography variant="body1">{`Event Slug: ${slug}`}</Typography>
      <Typography variant="body1">{`Country Id: ${countryId}`}</Typography>
      <Typography variant="body1">{`Region Id: ${regionId}`}</Typography>
      {prizePoolCurrency && prizePoolOld && (
        <Typography> {`${prizePoolOld} ${prizePoolCurrency}`} </Typography>
      )}
      <Box>
        <Typography color="primary" variant="body1">
          {`Start date: ${moment(startDate).format('ddd  MMMM DD YYYY')}`}
        </Typography>
      </Box>
      <Typography color="primary" variant="body1">
        {`End date: ${moment(endDate).format('ddd  MMMM DD YYYY')}`}
      </Typography>

      <Divider sx={classes.divider} />
      <Link href={eventUrl}>
        <Typography> Official link</Typography>
      </Link>
      <Divider sx={classes.divider} />
      <Typography variant="h5"> Prize Pool Builder </Typography>
      <FormControlLabel
        control={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Switch
            checked={showPrizePool}
            onChange={() => {
              setShowPrizePool((show) => !show);
            }}
            name="checkedB"
            color="primary"
          />
        }
        label="Show Prize Pool"
      />
      {showPrizePool && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <RibPrizePool prizePool={prizePool} />
          <Typography>
            Add additional teams that are not in the event:
          </Typography>
          <SearchField category="teams" handleOnClick={handleSelectTeam} />
          <RibPrizePoolEditor
            handleSubmit={handleSubmit}
            prizePool={prizePool}
            setPrizePool={setPrizePool}
            teams={teamsForPrizePool}
          />
        </Box>
      )}
      <Typography variant="h5"> Bracket Builder </Typography>
      <FormControlLabel
        control={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Switch
            checked={showBracket}
            onChange={() => {
              setShowBracket((show) => !show);
            }}
            name="checkedB"
            color="primary"
          />
        }
        label="Show Bracket"
      />
      {showBracket && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            variant="outlined"
            color="primary"
            sx={classes.button}
            onClick={handleBracketSave}
          >
            Save Bracket
          </Button>

          {bracket.type === 'weekly' && (
            <StandingsTable
              getTeamPath={() => {}}
              weeks={bracket?.weekly?.weeks}
              editable
              standingOptions={bracket?.weekly?.standingOptions || {}}
              handleUpdate={(newStandingOptions) => {
                bracketBuilder.editStandingOptions({
                  standingOptions: newStandingOptions
                });
              }}
            />
          )}
          <BracketBuilder
            eventId={id}
            bracket={bracket}
            eventData={data}
            setBracket={setBracket}
          />
          <Button
            variant="outlined"
            color="primary"
            sx={classes.button}
            onClick={handleBracketSave}
          >
            Save Bracket
          </Button>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Switch
                checked={showBracketPreview}
                onChange={() => {
                  setShowBracketPreview((show) => !show);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="Show Preview"
          />
          {showBracketPreview && (
            <>
              <h1> Bracket Preview</h1>
              <RibBracket
                bracket={bracket}
                theme={createTheme(currentTheme)}
                getSeriesPath={() => {}}
              />
            </>
          )}
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Switch
                checked={showBracketInfo}
                onChange={() => {
                  setShowBracketInfo((show) => !show);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="Show JSON"
          />
          {showBracketInfo && (
            <>
              <Box sx={{ color: 'black', backgroundColor: '#ccc' }}>
                <pre>{JSON.stringify(bracket, null, 2)}</pre>
              </Box>
            </>
          )}
        </Box>
      )}
      <Divider sx={classes.divider} />
      <Typography variant="h5"> Series</Typography>
      <Button
        variant="outlined"
        color="primary"
        sx={classes.button}
        onClick={() => openDialog('newSeries')}
      >
        Create new series
      </Button>
      <Box>
        <Typography variant="h6" sx={{ flex: 1 }}>
          {`# Series: ${seriesAmount}`}
        </Typography>
        <Typography variant="h6" sx={{ flex: 1 }}>
          {`# Matches: ${matchAmount}`}
        </Typography>
        <Typography variant="h6" sx={{ flex: 1 }}>
          {`# Linked Matches: ${linkedMatchAmount}`}
        </Typography>
        <Typography variant="h6" sx={{ flex: 1 }}>
          {`# Completed Matches:  ${completedMatchAmount}`}
        </Typography>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Switch
              checked={showUnlinked}
              onChange={() => {
                setShowUnlinked((unlinked) => !unlinked);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Show Series With Unlinked Matches"
        />
      </Box>
      {series && series.length > 0 && (
        <Box>
          <Box>
            <TextField
              value={filter}
              label="Search for a series"
              onChange={(e) => setFilter(e.target.value)}
            />
          </Box>
          {filteredSeries.map((s) => (
            <SeriesAccordion
              key={s.id}
              linkedSeriesId={linkedSeriesId}
              openDialog={openDialog}
              series={s}
              eventData={data}
            />
          ))}
        </Box>
      )}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {dialogMap[currentDialog] && dialogMap[currentDialog].title}
        </DialogTitle>
        <DialogContent>
          {dialogMap[currentDialog] && dialogMap[currentDialog].child}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Event;
