import React from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { createBrowserHistory } from 'history';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { baseTheme, darkPalette } from '../themes/theme';

import App from './App';

export const history = createBrowserHistory();

const AppWrapper = () => {
  const currentTheme = createTheme({ ...baseTheme, palette: darkPalette });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <Router history={history}>
          <App />
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default AppWrapper;
