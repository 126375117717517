import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';

export function requestCheckIfLoggedIn() {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/auth/discord/check-auth`,
    withCredentials: true
  });
}

export function requestGetUser() {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/auth/users/getUser`,
    withCredentials: true
  });
}

export function requestLogout() {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/auth/discord/logout`,
    withCredentials: true
  });
}
