import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import SearchField from '../layout/SearchField';
import { addTrending } from '../../redux/ducks/trendingSlice';

const styles = () => ({
  dialog: {
    padding: '20px',
    minWidth: '600px',
    minHeight: '200px'
  }
});

const AddTrendingDialog = ({ adding, setAdding, category }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [dateAdded, setDateAdded] = React.useState(
    moment().format('YYYY-MM-DD')
  );
  const [imgUrl, setImgUrl] = React.useState();
  const [articleUrl, setArticleUrl] = React.useState();

  const handleOnClick = listItem => {
    if (category === 'potw') setSelectedPlayer(listItem);
    if (category === 'totw') setSelectedTeam(listItem);
  };

  const handleOnSubmit = () => {
    let data;
    if (category === 'potw') {
      data = {
        playerId: selectedPlayer.id,
        dateAdded,
        imgUrl,
        articleUrl
      };
    }
    if (category === 'totw') {
      data = {
        teamId: selectedTeam.id,
        dateAdded,
        imgUrl,
        articleUrl
      };
    }
    dispatch(
      addTrending({
        category,
        data
      })
    );
    setAdding(false);
  };

  const categoryMap = {
    potw: 'players',
    totw: 'teams'
  };

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={adding}
      onClose={() => setAdding(false)}
    >
      <DialogTitle id="alert-dialog-title">Select an entity</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            width: '100%',
            flexDirection: 'column'
          }}
        >
          <SearchField
            category={categoryMap[category]}
            handleOnClick={handleOnClick}
            clearSelected
          />
          {selectedPlayer && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Typography color="primary"> {selectedPlayer.ign} </Typography>
            </Box>
          )}
          {selectedTeam && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Typography color="primary"> {selectedTeam.name} </Typography>
            </Box>
          )}
          <TextField
            sx={{ marginTop: '20px' }}
            label="Date"
            type="date"
            defaultValue={dateAdded}
            value={dateAdded}
            InputProps={{
              onChange: e => setDateAdded(e.target.value)
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            sx={{ marginTop: '20px' }}
            label="Image URL"
            type="text"
            value={imgUrl}
            InputProps={{
              onChange: e => setImgUrl(e.target.value)
            }}
          />
          <TextField
            sx={{ marginTop: '20px' }}
            label="Article URL"
            type="text"
            value={articleUrl}
            InputProps={{
              onChange: e => setArticleUrl(e.target.value)
            }}
          />
          <Button
            variant="outlined"
            disabled={!selectedPlayer && !selectedTeam}
            onClick={handleOnSubmit}
          >
            Confirm
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddTrendingDialog;
