import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { get } from '../../../../redux/ducks/currentDataSlice';

function sanitizeData(data) {
  const sanitizedData = {};

  if (data.id !== undefined) sanitizedData.id = data.id;
  if (data.name !== undefined) sanitizedData.name = data.name;
  if (data.shortName !== undefined) sanitizedData.shortName = data.shortName;
  if (data.logoUrl !== undefined) sanitizedData.logoUrl = data.logoUrl;

  return sanitizedData;
}

export default function AddTeam({ setContentDialogValues }) {
  const dispatch = useDispatch();

  const [localTeamId, setLocalTeamId] = useState('');
  const teamData = useSelector((state) => state.currentData).teams;
  const [team, setTeam] = useState({});

  const handleImportFromTeam = () => {
    dispatch(get({ category: 'teams', dataId: localTeamId }));
  };

  useEffect(() => {
    if (teamData) {
      const sanitizedTeamData = sanitizeData(teamData);
      setContentDialogValues({ team: sanitizedTeamData });
      setTeam(sanitizedTeamData);
    }
  }, [teamData]);

  return (
    <Box>
      <TextField
        sx={{ marginTop: '20px' }}
        label="Team ID"
        type="text"
        onChange={(e) => {
          setLocalTeamId(e.target.value);
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleImportFromTeam}
      >
        Import from team id
      </Button>
      <Typography> </Typography>
      <TextField
        sx={{ marginTop: '20px' }}
        label="Team Name (You Must Import)"
        type="text"
        value={team?.name}
        disabled
        InputLabelProps={{
          shrink: true
        }}
      />
    </Box>
  );
}
