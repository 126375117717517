import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';

export function requestUpdateRankings() {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/rankings/update`,
    withCredentials: true
  });
}
