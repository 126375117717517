import { createSlice } from '@reduxjs/toolkit';

const trendingSlice = createSlice({
  name: 'trending',
  initialState: {
    currentPotw: {},
    allPotws: [],
    currentTotw: {},
    allTotws: []
  },
  reducers: {
    getTrending() {},
    getAllTrending() {},
    deleteTrending() {},
    addTrending() {},
    setCurrent(state, action) {
      const { data, category } = action.payload;
      if (category === 'potw') state.currentPotw = data;
      if (category === 'totw') state.currentTotw = data;
    },
    setAll(state, action) {
      const { data, category } = action.payload;
      if (category === 'potw') state.allPotws = data;
      if (category === 'totw') state.allTotws = data;
    }
  }
});

export const {
  getTrending,
  getAllTrending,
  deleteTrending,
  addTrending,
  setCurrent,
  setAll
} = trendingSlice.actions;
export default trendingSlice.reducer;
