import { call, put } from 'redux-saga/effects';
import { get, getMatchDetails, set } from '../../redux/ducks/currentDataSlice';
import { addMatch, setData } from '../../redux/ducks/riot';
import {
  doGetData,
  doPostData,
  doPostPMT,
  doSchedulePMT
} from '../requests/riot';

const getResponseData = (dataId, responseData) => {
  if (dataId === 'searchPlayerIGN') {
    return { playerSearchResults: responseData };
  }
  if (dataId === 'getMatchHistory') {
    return { matchHistoryResults: responseData };
  }
  if (dataId === 'getMatch') {
    return { match: responseData };
  }
  if (dataId === 'getIndividualMatch') {
    return { match: responseData };
  }
  if (dataId === 'getCmsMatchDetails') {
    return { cmsMatchDetails: responseData };
  }
  if (dataId === 'getTeamHistories') {
    return {
      team1ConnectionData: responseData.team1,
      team2ConnectionData: responseData.team2
    };
  }
  return {};
};

export function* handleGetData(action) {
  const { dataId, queryParams, urlParams } = action;
  yield put(setData({ gettingData: true }));
  try {
    const { data: responseData } = yield call(
      doGetData,
      dataId,
      queryParams,
      urlParams
    );
    const data = yield getResponseData(dataId, responseData.payload);
    if (dataId === 'getMatch') {
      yield put(addMatch(data.match));
    } else if (dataId === 'addPlayerByIgn' || dataId === 'addPlayerByPuuid') {
      yield put(setData({ gettingData: false }));
      return;
    } else {
      yield put(setData(data));
    }
  } catch (error) {
    console.log(error);
  }
  yield put(setData({ gettingData: false }));
}

export function* handlePostData(action) {
  const { dataId, data: actionData } = action;
  yield put(setData({ postingData: true }));
  try {
    yield call(doPostData, dataId, actionData);
  } catch (error) {
    console.log(error);
  }
  if (dataId === 'quickConnectMatch') {
    console.log('updating match');
    const { dbMatchId } = actionData;
    console.log(actionData);
    yield put(getMatchDetails({ id: dbMatchId }));
  }
  yield put(setData({ postingData: false }));
}

export function* handlePostPMT(action) {
  yield put(setData({ postingData: true }));
  const { dataId = 'postPMT', data, eventId } = action.payload;
  try {
    yield call(doPostPMT, dataId, data);
  } catch (error) {
    console.log(error);
  }
  yield put(set({ category: 'series', data: undefined }));
  yield put(get({ category: 'events', dataId: eventId }));
  yield put(setData({ postingData: false }));
}

export function* handleSchedulePMT(action) {
  yield put(setData({ postingData: true }));

  const { dataId = 'schedulePMT', data, eventId } = action.payload;
  try {
    yield call(doSchedulePMT, dataId, data);
  } catch (error) {
    console.log(error);
  }
  yield put(set({ category: 'series', data: undefined }));
  yield put(get({ category: 'events', dataId: eventId }));
  yield put(setData({ postingData: false }));
}
