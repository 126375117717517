import { call } from 'redux-saga/effects';
import { requestUpdateRankings } from '../requests/ranking';

export function* handleUpdateRankings() {
  try {
    yield call(requestUpdateRankings);
  } catch (error) {
    console.log(error);
  }
}
