import moment from 'moment';

export const filterEvents = (events = [], { type, hasSeries }) => {
  console.log(events);
  let filteredEvents = events;
  if (type !== 'all') {
    filteredEvents = filteredEvents.filter(
      e => e.type.toLowerCase() === type.toLowerCase()
    );
  }
  if (hasSeries) {
    filteredEvents = filteredEvents.filter(e => e.seriesCount > 0);
  }
  return filteredEvents;
};

export const getUpcomingEvents = (events, includeFeatured = false) => {
  return events
    ? events
        .slice()
        .filter(
          e =>
            moment(e.startDate) >= moment() &&
            (!e.importance || includeFeatured)
        )
    : [];
};

export const getOngoingEvents = (events, includeFeatured = false) => {
  return events
    ? events
        .slice()
        .filter(
          e =>
            moment(e.endDate) >= moment() &&
            moment(e.startDate) <= moment() &&
            (!e.importance || includeFeatured)
        )
    : [];
};

export const getCompletedEvents = events => {
  return events
    ? events.slice().filter(e => moment(e.endDate).add(1, 'days') <= moment())
    : [];
};

export const getFeaturedEvents = events => {
  return events
    ? events
        .slice()
        .filter(e => e.importance)
        .sort((a, b) => b.importance - a.importance)
    : [];
};
