/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Divider,
  Paper,
  Checkbox,
  FormControlLabel,
  Tooltip
} from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';

const styles = () => ({
  tableContainer: {
    display: 'flex',
    width: '100%'
  },
  divider: {
    width: '5px',
    height: 'auto'
  },
  match: {
    padding: '5px',
    margin: '2px',
    backgroundColor: `#303030`,
    minHeight: '51px',
    textAlign: 'center'
  },
  playerContainer: {
    minWidth: '93px'
  }
});

const colors = [
  '#F17105',
  '#D11149',
  '#6610F2',
  '#1A8FE3',
  '#DF9A57',
  '#FC7A57',
  '#FCD757',
  '#EEFC57',
  '#8E5572',
  '#3B429F',
  '#AA7DCE',
  '#F5D7E3',
  '#F4A5AE',
  '#A8577E',
  '#05A8AA',
  '#B8D5B8',
  '#D7B49E',
  '#DC602E',
  '#BC412B',
  '#E6C229'
];

const Histories = props => {
  const classes = styles();
  const { setStage, setSelectedMatchId } = props;
  const [hideOtherMatches, setHideOtherMatches] = useState(true);
  const [onlyTop20, setOnlyTop20] = useState(true);

  const { team1ConnectionData, team2ConnectionData, gettingData } = useSelector(
    state => state.riot
  );
  const { players: team1Players, histories: team1Histories, name: team1Name } =
    team1ConnectionData || {};

  const { players: team2Players, histories: team2Histories, name: team2Name } =
    team2ConnectionData || {};

  const commonMatches = {
    1: new Set(),
    2: new Set()
  };

  const globalMatchObject = {};
  if (team1Histories || team2Histories) {
    [team1Histories, team2Histories].forEach((teamHistory, idx) => {
      Object.keys(teamHistory).forEach(matchList => {
        let iteratedList = teamHistory[matchList];
        if (hideOtherMatches) {
          iteratedList = teamHistory[matchList].filter(match => !match.queueId);
        }
        iteratedList.forEach(match => {
          const { matchId } = match;
          commonMatches[idx + 1].add(matchId);
          globalMatchObject[matchId]
            ? (globalMatchObject[matchId] += 1)
            : (globalMatchObject[matchId] = 1);
        });
      });
    });
  }

  // Get an array of the keys:
  const keys = Object.keys(globalMatchObject);
  const sortedGlobalMatchList = keys
    .sort((a, b) => {
      return globalMatchObject[a] - globalMatchObject[b];
    })
    .reverse();

  const top20CommonList = sortedGlobalMatchList.slice(0, 20);

  const handleClick = matchId => {
    setSelectedMatchId(matchId);
    setStage('review');
  };

  return (
    <>
      <Typography variant="h5" color="primary">
        Match Histories
      </Typography>

      {!team1ConnectionData && !team2ConnectionData && gettingData && (
        <CircularProgress />
      )}
      {!team1ConnectionData && !team2ConnectionData && !gettingData && (
        <Typography> No matches found. Try a different region</Typography>
      )}
      {/* 4d91c466-ead3-4cc2-87c0-cdb9bb2dd020 */}

      {team1Players && team2Players && (
        <Box>
          <FormControlLabel
            label="Hide non-custom matches"
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Checkbox
                checked={hideOtherMatches}
                onChange={e => setHideOtherMatches(e.target.checked)}
              />
            }
          />
          <FormControlLabel
            label="Show top 20 common matches"
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Checkbox
                checked={onlyTop20}
                onChange={e => setOnlyTop20(e.target.checked)}
              />
            }
          />
          <Box sx={classes.tableContainer}>
            {[
              {
                players: team1Players,
                histories: team1Histories,
                color: 'primary',
                name: team1Name
              },
              {
                players: team2Players,
                histories: team2Histories,
                color: 'secondary',
                name: team2Name
              }
            ].map(team =>
              team.players.map(player => {
                const { ign, id } = player;
                let matches = hideOtherMatches
                  ? team.histories[id] &&
                    team.histories[id].filter(match => !match.queueId)
                  : team.histories[id];

                matches = onlyTop20
                  ? matches &&
                    matches.filter(
                      match => top20CommonList.indexOf(match.matchId) !== -1
                    )
                  : matches;

                matches = [
                  ...new Map(
                    matches && matches.map(match => [match.matchId, match])
                  ).values()
                ];

                matches = matches.sort((a, b) => {
                  return b.gameStartTimeMillis - a.gameStartTimeMillis;
                });

                return (
                  <Box sx={classes.playerContainer} key={id}>
                    <Tooltip title={`playerId: ${id}`}>
                      <Typography color={team.color}>{ign}</Typography>
                    </Tooltip>
                    {matches.map(match => {
                      const { matchId, gameStartTimeMillis, queueId } = match;
                      const isCommon =
                        commonMatches[1].has(matchId) &&
                        commonMatches[2].has(matchId);
                      // const timeAgo = moment(gameStartTimeMillis).fromNow();
                      const monthDay = moment(gameStartTimeMillis).format(
                        'MMMM Do'
                      );
                      const time = moment(gameStartTimeMillis).format(
                        'hh:mm A z'
                      );
                      const indexInTop20 = top20CommonList.indexOf(matchId);

                      return (
                        <Tooltip title={`matchId: ${matchId}`} key={matchId}>
                          <Box
                            sx={{
                              ...classes.match,
                              ...{
                                color:
                                  indexInTop20 !== -1
                                    ? colors[indexInTop20 % 20]
                                    : 'white',
                                border: isCommon
                                  ? '1px solid #6FCF97'
                                  : 'inherit'
                              }
                            }}
                            onClick={() => handleClick(matchId)}
                          >
                            <Typography>{monthDay}</Typography>
                            <Typography variant="caption">{time}</Typography>
                            {/* <Typography>{matchId}</Typography> */}
                            {/* <Typography>{timeAgo}</Typography> */}
                          </Box>
                        </Tooltip>
                      );
                    })}
                  </Box>
                );
              })
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Histories;
