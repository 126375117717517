/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';
import PlayerTable from './RankTable';

export default function TopPlayer() {
  // maintain arrays for multiple events
  const [eventIds, setEventIds] = useState([2521, 2522, 2523]);
  const [columnNames, setColumnNames] = useState([
    'overall',
    'regular season',
    'playoffs'
  ]);
  const [sortEvent, setSortEvent] = useState('');
  const [topPlayers, setTopPlayers] = useState(null);

  // function to generate URL for each event
  const getEventUrl = (id) => `${backendBaseUrl}/cms/events/rank/${id}`;

  const getTopPlayers = async () => {
    const allTopPlayers = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < eventIds.length; i++) {
      // Only make a request if event id is present
      if (eventIds[i]) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const resp = await axios.request({
            method: 'GET',
            url: getEventUrl(eventIds[i]),
            withCredentials: true
          });
          const { data } = resp;
          const { payload } = data || {};
          const { rows } = payload || {};
          allTopPlayers.push({ eventName: columnNames[i], data: rows });
        } catch (error) {
          console.error('Error fetching event data', error);
        }
      }
    }
    setTopPlayers(allTopPlayers);
  };

  // function to handle event id and column name changes
  const handleEventChange = (event, index) => {
    const newEventIds = [...eventIds];
    newEventIds[index] = event.target.value;
    setEventIds(newEventIds);
  };

  const handleColumnNameChange = (event, index) => {
    const newColumnNames = [...columnNames];
    newColumnNames[index] = event.target.value;
    setColumnNames(newColumnNames);
  };

  // render inputs for multiple events
  const renderEventsInput = () => {
    return eventIds.map((_, index) => (
      <div key={index}>
        <TextField
          label={`Event id ${index + 1}`}
          value={eventIds[index]}
          onChange={(event) => handleEventChange(event, index)}
        />
        <TextField
          label={`Column name ${index + 1}`}
          value={columnNames[index]}
          onChange={(event) => handleColumnNameChange(event, index)}
        />
      </div>
    ));
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 4 }}>{renderEventsInput()}</Box>

      <Button variant="outlined" onClick={() => getTopPlayers()}>
        Get top event players
      </Button>
      <TextField
        label="Sort by event"
        onChange={(event) => setSortEvent(event.target.value)}
      />
      {topPlayers && (
        <PlayerTable
          rows={topPlayers}
          sortEvent={sortEvent}
          // minMatches={minMatches}
        />
      )}
    </Box>
  );
}
