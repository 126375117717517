import React, { useEffect } from 'react';
import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { deleteData } from '../../redux/ducks/data';
import { get, set } from '../../redux/ducks/currentDataSlice';
import Event from '../events/Event';
import Team from '../teams/Team';
import Player from '../players/Player';

const styles = () => ({
  deleteButtonContainer: {
    marginTop: '50px'
  }
});

const DataLayout = () => {
  const classes = styles();
  const { id, category } = useParams();
  const data = useSelector(state => state.currentData)[category];
  const dispatch = useDispatch();

  const categoryMap = {
    events: <Event data={data} />,
    teams: <Team data={data} />,
    players: <Player data={data} />
  };

  useEffect(() => {
    dispatch(set({ category, data: undefined }));
    dispatch(get({ category, dataId: id }));
    return () => {
      dispatch(set({ category, data: undefined }));
    };
  }, [dispatch, category]);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = deleteConfirmed => {
    setOpen(false);
    if (deleteConfirmed) {
      dispatch(deleteData(category, id));
    }
  };

  let deleteDisabled = false;
  if (category === 'events' && data) {
    if (data.series && data.series.length > 0) {
      deleteDisabled = true;
    }
  }

  return (
    <>
      {data ? (
        <Box>
          {categoryMap[category]}
          <Box sx={classes.deleteButtonContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClickOpen}
              disabled={deleteDisabled}
            >
              DELETE
            </Button>
            <Dialog open={open} onClose={() => handleClose(false)}>
              <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleClose(true)} color="primary">
                  Yes
                </Button>
                <Button
                  onClick={() => handleClose(false)}
                  color="primary"
                  autoFocus
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default DataLayout;
