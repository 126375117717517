import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';

export function doGetCircuits() {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/cms/circuits`,
    withCredentials: true
  });
}
