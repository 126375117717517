import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';

export function doAddTrending(category, data) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/trending/add`,
    data: {
      category,
      data
    },
    withCredentials: true
  });
}

export function doDeleteTrending(category, id) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/trending/delete`,
    data: {
      category,
      id
    },
    withCredentials: true
  });
}

export function doGetTrending(category) {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/cms/trending/${category}`,
    withCredentials: true
  });
}

export function doGetAllTrending(category) {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/cms/trending/all/${category}`,
    withCredentials: true
  });
}
