import React, { useEffect } from 'react';
import {
  Button,
  LinearProgress,
  Box,
  FormControlLabel,
  Typography,
  MenuItem
} from '@mui/material';
import axios from 'axios';
import MuiTextField from '@mui/material/TextField';
import { DateTimePicker } from 'formik-mui-x-date-pickers';
import { Formik, Form, Field } from 'formik';
import { TextField, Switch, Autocomplete } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import { create, edit, getAllTeams } from '../../redux/ducks/data';

const SeriesForm = (props) => {
  const { data, isNew, eventId } = props;
  const dispatch = useDispatch();
  const { allTeams } = useSelector((state) => state.list);
  const [ggBetItems, setggBetItems] = React.useState([]);
  useEffect(() => {
    if (!allTeams) {
      dispatch(getAllTeams());
    }
  }, [dispatch]);

  useEffect(() => {
    try {
      axios
        .request({
          method: 'get',
          url: `https://odds.data.bet/affiliates/suioPSJsyQjIr_uDKw0P6g/json`
        })
        .then((resp) => {
          const { data } = resp || {};
          const { Sport } = data || {};
          const { eSports } = Sport || {};
          const { Events } = eSports || {};
          const matchList = [];
          Events &&
            Events.forEach((event) => {
              const { Matches } = event;
              matchList.push(...Matches);
            });
          setggBetItems(matchList);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Formik
      initialValues={
        data && !isNew
          ? {
              ...data,
              team1Field: data.team1,
              team2Field: data.team2,
              eventField: data.event,
              ggbetField: data.ggbetId
            }
          : {
              bestOf: '',
              stage: '',
              startDate: new Date()
            }
      }
      validate={(values) => {
        const errors = {};
        if (!values.bestOf) {
          errors.bestOf = 'Required';
        } else if (!Number.isInteger(values.bestOf) || values.bestOf < 1) {
          errors.bestOf = 'Must be an positive integer';
        }
        if (values.vodUrl && !values.vodUrl.startsWith('https://')) {
          errors.vodUrl = 'Invalid url. Must start with https://';
        }
        // if (!values.team1Field) {
        //   errors.team1Field = 'Required';
        // }
        // if (!values.team2Field) {
        //   errors.team2Field = 'Required';
        // }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        const { team1Field, team2Field, ggbetField } = values;
        Object.assign(values, {
          eventId,
          ggbetId: ggbetField && ggbetField.ID,
          team1Id: team1Field && team1Field.id,
          team2Id: team2Field && team2Field.id
        });

        delete values.team1Score;
        delete values.team2Score;

        setTimeout(() => {
          setSubmitting(false);

          isNew
            ? dispatch(create('series', values))
            : dispatch(edit('series', data.id, values, true));
        }, 500);
      }}
    >
      {({ submitForm, isSubmitting, touched, errors, values }) => (
        <Form>
          <Box margin={1}>
            {allTeams && allTeams.length > 0 && (
              <Field
                name="team1Field"
                component={Autocomplete}
                options={allTeams}
                value={
                  data &&
                  data.team1 &&
                  allTeams &&
                  allTeams[
                    allTeams
                      // eslint-disable-next-line func-names
                      .map(function (t) {
                        return t.id;
                      })
                      .indexOf(values.team1Field.id)
                  ]
                }
                getOptionLabel={(option) => option.name}
                sx={{ width: '400px' }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    error={touched.team1Field && !!errors.team1Field}
                    helperText={touched.team1Field && errors.team1Field}
                    label="Team #1"
                    variant="outlined"
                  />
                )}
              />
            )}
          </Box>
          <Box margin={1}>
            {allTeams && allTeams.length > 0 && (
              <Field
                name="team2Field"
                component={Autocomplete}
                options={allTeams}
                getOptionLabel={(option) => option.name}
                sx={{ width: '400px' }}
                value={
                  data &&
                  data.team2 &&
                  allTeams &&
                  allTeams[
                    allTeams
                      // eslint-disable-next-line func-names
                      .map(function (t) {
                        return t.id;
                      })
                      .indexOf(values.team2Field.id)
                  ]
                }
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    error={touched.team2Field && !!errors.team2Field}
                    helperText={touched.team2Field && errors.team2Field}
                    label="Team #2"
                    variant="outlined"
                  />
                )}
              />
            )}
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={DateTimePicker}
              name="startDate"
              label="Start Date Time"
            />
          </Box>
          <Box margin={1}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Field type="text" name="vlrId" />
              }
              label="VLR Series ID"
            />
            <Typography variant="caption">
              The VLR Match ID - it will be the first number in the url i.e
              www.vlr.gg/15766/ has a VLR Series ID of 15766
            </Typography>
          </Box>
          <Box margin={1}>
            {ggBetItems && ggBetItems.length > 0 && (
              <Field
                name="ggbetField"
                component={Autocomplete}
                options={ggBetItems}
                getOptionLabel={(option) => option.Name}
                sx={{ width: '400px' }}
                // value={option => option.ID}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label="ggbet id"
                    variant="outlined"
                  />
                )}
              />
            )}
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              type="text"
              name="bracket"
              label="Winners or Losers bracket?"
              select
              variant="standard"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            >
              {[null, 'winners', 'losers', 'group'].map((position) => (
                <MenuItem key={position} value={position}>
                  {position}
                </MenuItem>
              ))}
            </Field>
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="bestOf"
              type="number"
              label="Best Of (number)"
              sx={{ width: '400px' }}
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="stage"
              type="number"
              label="Stage (Number)"
            />
          </Box>
          {/* <Box margin={1}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Field component={Switch} type="checkbox" name="live" />
              }
              label="Live"
            />
            <Typography variant="caption">
              When this is on, the game will be displayed as Live on the site.
              As soon as a game starts, turn this on. When the game ends, turn
              this off.
            </Typography>
          </Box> */}
          <Box margin={1}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Field component={Switch} type="checkbox" name="completed" />
              }
              label="Series completed?"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="vodUrl"
              type="text"
              label="VOD Url"
            />
          </Box>
          {values.complete && (
            <Box>
              <Box margin={1}>
                <Field
                  sx={{ width: '400px' }}
                  component={TextField}
                  type="text"
                  name="winningTeamNumber"
                  label="Winning Team Number (Leave blank if game was not played)"
                  select
                  variant="standard"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  {[null, '1', '2'].map((position) => (
                    <MenuItem key={position} value={position}>
                      {position}
                    </MenuItem>
                  ))}
                </Field>
              </Box>
              <Box margin={1}>
                <Field
                  sx={{ width: '400px' }}
                  component={TextField}
                  type="text"
                  name="winCondition"
                  label="Win Condition (Leave blank if game was not played)"
                  select
                  variant="standard"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  {[null, 'standard', 'forfeit', 'bye'].map((position) => (
                    <MenuItem key={position} value={position}>
                      {position}
                    </MenuItem>
                  ))}
                </Field>
              </Box>
            </Box>
          )}

          {isSubmitting && <LinearProgress />}
          <Box margin={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SeriesForm;
