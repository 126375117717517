import React, { useState } from 'react';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';

const styles = () => ({
  container: {
    display: 'flex'
  }
});

const Rankings = () => {
  const classes = styles();
  const [startDate, setStartDate] = useState(new Date());
  const [seriesId, setSeriesId] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [timeTaken, setTimeTaken] = useState(null);

  const recalibrateRankingsUrl = `${backendBaseUrl}/rankings/recalibrate-by-date`;
  const recalibrateBySeriesRankingsUrl = `${backendBaseUrl}/rankings/recalibrate-by-series`;
  const resetRankingsUrl = `${backendBaseUrl}/rankings/reset`;

  const recalibrateRankings = async () => {
    try {
      setIsUpdating(true);
      const startTime = Date.now();
      const response = await axios.post(
        recalibrateRankingsUrl,
        {
          date: startDate.toISOString()
        },
        { withCredentials: true }
      );
      const endTime = Date.now();
      setTimeTaken((endTime - startTime) / 1000); // Time in seconds
      setIsUpdating(false);
      console.log(response.data.message);
    } catch (error) {
      console.error('Error recalibrating rankings', error);
      setIsUpdating(false);
    }
  };

  const resetRankings = async () => {
    try {
      setIsUpdating(true);
      const startTime = Date.now();
      const response = await axios.post(resetRankingsUrl, {
        withCredentials: true
      });
      const endTime = Date.now();
      setTimeTaken((endTime - startTime) / 1000); // Time in seconds
      setIsUpdating(false);
      console.log(response.data.message);
    } catch (error) {
      console.error('Error resetting rankings', error);
      setIsUpdating(false);
    }
  };

  const recalibrateRankingsBySeries = async () => {
    try {
      setIsUpdating(true);
      const startTime = Date.now();
      const response = await axios.post(
        recalibrateBySeriesRankingsUrl,
        {
          seriesId
        },
        { withCredentials: true }
      );
      const endTime = Date.now();
      setTimeTaken((endTime - startTime) / 1000); // Time in seconds
      setIsUpdating(false);
      console.log(response.data.message);
    } catch (error) {
      console.error('Error recalibrating rankings', error);
      setIsUpdating(false);
    }
  };

  return (
    <Box>
      <Box sx={classes.container}>
        <Typography variant="h4">Rankings</Typography>
      </Box>
      <Button
        variant="outlined"
        onClick={recalibrateRankings}
        disabled={isUpdating}
      >
        {isUpdating ? 'Updating...' : 'Recalibrate rankings by date'}
      </Button>
      {timeTaken && <Typography>Time taken: {timeTaken} seconds</Typography>}
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        disabled={isUpdating}
      />

      <Divider sx={{ mb: 3, mt: 3 }} />
      <Button
        variant="outlined"
        onClick={recalibrateRankingsBySeries}
        disabled={isUpdating}
      >
        {isUpdating
          ? 'Updating...'
          : 'Recalibrate all effected rankings from Series Id'}
      </Button>
      {timeTaken && <Typography>Time taken: {timeTaken} seconds</Typography>}
      <TextField
        value={seriesId}
        onChange={(e) => setSeriesId(e.target.value)}
        disabled={isUpdating}
      />
    </Box>
  );
};

export default Rankings;
