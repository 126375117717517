import React from 'react';
import {
  Button,
  LinearProgress,
  Box,
  MenuItem,
  FormControlLabel
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { DatePicker } from 'formik-mui-x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { create, edit } from '../../redux/ducks/data';

const styles = () => ({
  select: {
    minWidth: '120px'
  },
  currency: {
    marginTop: '0',
    marginBottom: '0'
  }
});

const PlayerForm = (props) => {
  const { id, data, isNew, teamId } = props;
  const { countryList } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const classes = styles();

  return (
    <Formik
      initialValues={
        data && !isNew
          ? data
          : {
              firstName: '',
              lastName: '',
              ign: '',
              bio: '',
              countryId: 38,
              imageUrl: '',
              instagramUrl: '',
              twitterUrl: '',
              twitchUrl: '',
              youtubeUrl: '',
              liquipediaUrl: '',
              metafyUrl: '',
              customUrl: '',
              ufa: false,
              rfa: false
            }
      }
      validate={(values) => {
        const errors = {};
        if (!values.ign) {
          errors.ign = 'Required';
        }
        if (teamId && !values.dateJoined) {
          errors.dateJoined = 'Required';
        }
        if (
          values.imageUrl &&
          (!values.imageUrl.match('.*\\.(jpg|png|jpeg|webp)$') ||
            !values.imageUrl.startsWith('https://'))
        ) {
          errors.imageUrl =
            'Invalid url. Must start with https:// and end with .jpg .png .webp or .jpeg';
        }
        if (
          values.instagramUrl &&
          !values.instagramUrl.startsWith('https://')
        ) {
          errors.instagramUrl = 'Invalid url. Must start with https://';
        }
        if (values.twitterUrl && !values.twitterUrl.startsWith('https://')) {
          errors.twitterUrl = 'Invalid url. Must start with https://';
        }
        if (
          values.liquipediaUrl &&
          !values.liquipediaUrl.startsWith('https://')
        ) {
          errors.liquipediaUrl = 'Invalid url. Must start with https://';
        }
        if (values.twitchUrl && !values.twitchUrl.startsWith('https://')) {
          errors.twitchUrl = 'Invalid url. Must start with https://';
        }
        if (values.youtubeUrl && !values.youtubeUrl.startsWith('https://')) {
          errors.youtubeUrl = 'Invalid url. Must start with https://';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        let newValues = values;
        if (teamId) {
          newValues = Object.assign(values, { teamId });
        }
        setTimeout(() => {
          setSubmitting(false);
          isNew
            ? dispatch(create('players', newValues))
            : dispatch(edit('players', id, values));
        }, 500);
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          {teamId && (
            <Box margin={1}>
              <Field
                component={DatePicker}
                name="dateJoined"
                label="Date joined team"
                value=""
              />
            </Box>
          )}
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="firstName"
              type="text"
              label="First Name (Leave blank if not sure)"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="lastName"
              type="text"
              label="Last Name (Leave blank if not sure)"
            />
          </Box>
          <Box margin={1}>
            <Field component={TextField} name="ign" type="text" label="ign" />
          </Box>
          <Box margin={1}>
            <Field
              component="textarea"
              placeholder="Player bio"
              rows="5"
              name="bio"
              type="text"
              label="bio"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={classes.select}
              component={TextField}
              type="text"
              name="countryId"
              label="Country"
              select
              variant="standard"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            >
              {countryList &&
                countryList.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.niceName}
                  </MenuItem>
                ))}
            </Field>
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '600px' }}
              component={TextField}
              name="imageUrl"
              type="text"
              label="Image URL (Leave blank if player does not has one - square & transparent)"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="instagramUrl"
              type="text"
              label="Instagram Url"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="twitterUrl"
              type="text"
              label="Twitter Url"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="twitchUrl"
              type="text"
              label="Twitch Url"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="youtubeUrl"
              type="text"
              label="Youtube Url"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="liquipediaUrl"
              type="text"
              label="Liquipedia Url"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="metafyUrl"
              type="text"
              label="Metafy Url"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="customUrl"
              type="text"
              label="Custom Url"
            />
          </Box>
          <Box margin={1}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Field component={Switch} type="checkbox" name="ufa" />
              }
              label="Is the player an UFA (Unrestricted Free Agent)"
            />
          </Box>
          <Box margin={1}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Field component={Switch} type="checkbox" name="rfa" />
              }
              label="Is the player a RFA (Restricted Free Agent)"
            />
          </Box>
          {isSubmitting && <LinearProgress />}
          <Box margin={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PlayerForm;
