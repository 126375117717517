import { createSlice } from '@reduxjs/toolkit';

const listSlice = createSlice({
  name: 'list',
  initialState: {
    puuidPlayers: {}
  },
  reducers: {
    setList(state, action) {
      const listData = action.payload;
      return { ...state, ...listData };
    },
    addToPlayerList(state, action) {
      const { puuid, data } = action.payload;
      return {
        ...state,
        puuidPlayers: { ...state.puuidPlayers, [puuid]: data }
      };
    },
    search() {},
    getCircuits() {}
  }
});

export const {
  setList,
  search,
  addToPlayerList,
  getCircuits
} = listSlice.actions;
export default listSlice.reducer;
