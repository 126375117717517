import { createSlice } from '@reduxjs/toolkit';

const stripeSlice = createSlice({
  name: 'stripe',
  initialState: {},
  reducers: {
    getCustomers() {},
    setCustomers(state, action) {
      const { data } = action.payload;
      state.customers = data;
    }
  }
});

export const { getCustomers, setCustomers } = stripeSlice.actions;
export default stripeSlice.reducer;
