import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useSWR from 'swr';
import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';
import FantasyLeagueForm from './FantasyLeagueForm'; // Ensure this is correctly imported

// Define a fetcher function that will be used by SWR for data fetching
const fetcher = (url) =>
  axios.get(url, { withCredentials: true }).then((res) => res.data);

const Fantasy = () => {
  const baseFantasyUrl = `${backendBaseUrl}/cms/fantasy`;

  // Use the useSWR hook to fetch fantasy leagues
  const {
    data: fantasyLeagues,
    error,
    mutate
  } = useSWR(`${baseFantasyUrl}`, fetcher);

  // Function to handle successful form submission
  const handleSubmitSuccess = () => {
    mutate(); // Revalidate the SWR cache to update the list
  };

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [leagueToDelete, setLeagueToDelete] = useState(null);

  const handleDeleteConfirmationOpen = (leagueId) => {
    setLeagueToDelete(leagueId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setLeagueToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteLeague = async (leagueId) => {
    try {
      await axios.delete(`${backendBaseUrl}/cms/fantasy/${leagueId}`, {
        withCredentials: true
      });

      // Revalidate the SWR cache to update the list
      mutate();
    } catch (error) {
      console.error('Failed to delete fantasy league', error);
      // Handle error (e.g., show error message)
    } finally {
      handleDeleteConfirmationClose();
    }
  };

  if (error) return <div>Failed to load fantasy leagues</div>;
  if (!fantasyLeagues) return <div>Loading...</div>;

  return (
    <Box sx={{ mt: 1 }}>
      <Typography variant="h4" gutterBottom>
        Fantasy Leagues
      </Typography>
      <Divider sx={{ mb: 3, mt: 3 }} />

      <Typography sx={{ mt: 5 }}> Existing leagues </Typography>
      {fantasyLeagues.map((league) => (
        <Accordion key={league.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{league.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* Form for editing an existing league */}
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDeleteConfirmationOpen(league.id)}
            >
              Delete League
            </Button>
            <FantasyLeagueForm
              league={league}
              onSubmitSuccess={handleSubmitSuccess}
            />
          </AccordionDetails>
        </Accordion>
      ))}

      <Divider sx={{ mb: 3, mt: 3 }} />
      <FantasyLeagueForm onSubmitSuccess={handleSubmitSuccess} />
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteConfirmationClose}
      >
        <DialogTitle>Delete League</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this league?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmationClose}>Cancel</Button>
          <Button
            onClick={() => handleDeleteLeague(leagueToDelete)}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Fantasy;
