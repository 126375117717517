import { createSlice } from '@reduxjs/toolkit';

const teamSlice = createSlice({
  name: 'team',
  initialState: {},
  reducers: {
    createByUrl() {}
  }
});

export const { createByUrl } = teamSlice.actions;
export default teamSlice.reducer;
