import React from 'react';
import { Typography, Paper } from '@mui/material';

const styles = () => ({
  root: {
    padding: '15px',
    width: '210px',
    textAlign: 'center'
  },
  img: {
    height: '180px',
    width: '180px'
  }
});

const TeamCard = (props) => {
  const classes = styles(props);
  const { data } = props;
  const { name, logoUrl } = data;

  return (
    <Paper sx={classes.root}>
      <Typography> {name} </Typography>
      <img src={logoUrl} style={classes.img} alt={name} />
    </Paper>
  );
};

export default TeamCard;
