import { takeLatest, takeEvery } from 'redux-saga/effects';
import {
  CREATE,
  DELETE_DATA,
  EDIT,
  ADD_PLAYER_TO_TEAM,
  REMOVE_PLAYER_FROM_TEAM,
  MAKE_PLAYER_CAPTAIN,
  ADD_PLAYERS_TO_MATCH,
  REMOVE_PLAYER_FROM_MATCH,
  SWAP_TEAMS,
  GET_COUNTRY_LIST,
  GET_ALL_TEAMS,
  GET_MAP_LIST,
  GET_AGENT_LIST,
  SET_PLAYER_STATS,
  MATCH_UPDATE_PLAYER,
  GET_REGION_LIST,
  DO_TEAM_ALIAS,
  DO_NEW_PUUID
} from '../redux/ducks/data';
import { getCircuits, search } from '../redux/ducks/listSlice';
import {
  toggleSeriesLive,
  get,
  getMatchDetails,
  deleteSeries,
  deleteMatch,
  addPatch
} from '../redux/ducks/currentDataSlice';
import {
  handleGet,
  handleCreate,
  handleDelete,
  handleEdit,
  handleGetCountryList,
  handleSearch,
  handleGetMapList,
  handleGetAgentList,
  handleGetRegionList
} from './handlers/data';
import {
  handleAddPlayerToTeam,
  handleRemovePlayerFromTeam,
  handleMakePlayerCaptain,
  handleGetAllTeams,
  handleCreateByUrl,
  handleTeamAlias,
  handleNewPuuid
} from './handlers/teams';
import {
  handleAddPatch,
  handleAddPlayersToMatch,
  handleDeleteMatch,
  handleDeleteSeries,
  handleGetMatchDetails,
  handleRemovePlayerFromMatch,
  handleSetPlayerStats,
  handleSwapTeams,
  handleToggleSeriesLive,
  handleUpdatePlayer
} from './handlers/matches';
import { CHECK_IF_LOGGED_IN, LOG_OUT, GET_USER } from '../redux/ducks/user';
import { doCheckIfLoggedIn, doLogout, doGetUser } from './handlers/user';
import { GET_DATA, POST_DATA } from '../redux/ducks/riot';
import {
  handleGetData,
  handlePostData,
  handlePostPMT,
  handleSchedulePMT
} from './handlers/riot';
import { createByUrl } from '../redux/ducks/teamSlice';
import { updateRankings } from '../redux/ducks/rankingSlice';
import { handleUpdateRankings } from './handlers/ranking';

import {
  handleAddTrendingData,
  handleDeleteTrending,
  handleGetAllTrendingData,
  handleGetTrendingData
} from './handlers/trending';
import {
  addTrending,
  deleteTrending,
  getAllTrending,
  getTrending
} from '../redux/ducks/trendingSlice';
import { getCustomers } from '../redux/ducks/stripeSlice';
import { handleGetCustomers } from './handlers/stripe';
import { handleGetCircuits } from './handlers/circuits';
import { postPmt, schedulePmt } from '../redux/ducks/metaSlice';

export function* watcherSaga() {
  yield takeLatest(CHECK_IF_LOGGED_IN, doCheckIfLoggedIn);
  yield takeLatest(LOG_OUT, doLogout);
  yield takeLatest(GET_USER, doGetUser);
  yield takeLatest(get.type, handleGet);
  yield takeLatest(GET_ALL_TEAMS, handleGetAllTeams);
  yield takeLatest(search.type, handleSearch);
  yield takeEvery(GET_COUNTRY_LIST, handleGetCountryList);
  yield takeEvery(GET_REGION_LIST, handleGetRegionList);
  yield takeEvery(GET_MAP_LIST, handleGetMapList);
  yield takeEvery(GET_AGENT_LIST, handleGetAgentList);
  yield takeLatest(CREATE, handleCreate);
  yield takeLatest(EDIT, handleEdit);
  yield takeLatest(DELETE_DATA, handleDelete);
  yield takeLatest(ADD_PLAYER_TO_TEAM, handleAddPlayerToTeam);
  yield takeLatest(REMOVE_PLAYER_FROM_TEAM, handleRemovePlayerFromTeam);
  yield takeLatest(MAKE_PLAYER_CAPTAIN, handleMakePlayerCaptain);
  yield takeLatest(SET_PLAYER_STATS, handleSetPlayerStats);
  yield takeLatest(MATCH_UPDATE_PLAYER, handleUpdatePlayer);
  yield takeLatest(ADD_PLAYERS_TO_MATCH, handleAddPlayersToMatch);
  yield takeLatest(REMOVE_PLAYER_FROM_MATCH, handleRemovePlayerFromMatch);
  yield takeLatest(DO_TEAM_ALIAS, handleTeamAlias);
  yield takeLatest(DO_NEW_PUUID, handleNewPuuid);
  yield takeLatest(GET_DATA, handleGetData);
  yield takeLatest(POST_DATA, handlePostData);
  yield takeLatest(SWAP_TEAMS, handleSwapTeams);
  yield takeLatest(getMatchDetails.type, handleGetMatchDetails);
  yield takeLatest(createByUrl.type, handleCreateByUrl);
  yield takeLatest(updateRankings.type, handleUpdateRankings);
  yield takeLatest(toggleSeriesLive.type, handleToggleSeriesLive);
  // potw
  yield takeEvery(getTrending.type, handleGetTrendingData);
  yield takeEvery(getAllTrending.type, handleGetAllTrendingData);
  yield takeEvery(addTrending.type, handleAddTrendingData);
  yield takeEvery(deleteTrending.type, handleDeleteTrending);
  // stripe
  yield takeLatest(getCustomers, handleGetCustomers);
  // circuits
  yield takeLatest(getCircuits.type, handleGetCircuits);
  // pmt
  yield takeLatest(postPmt.type, handlePostPMT);
  yield takeLatest(schedulePmt.type, handleSchedulePMT);
  // delete series match
  yield takeLatest(deleteSeries.type, handleDeleteSeries);
  yield takeLatest(deleteMatch.type, handleDeleteMatch);
  // patch
  yield takeLatest(addPatch.type, handleAddPatch);
}
