import { call, put } from 'redux-saga/effects';
import {
  getMatchDetails,
  setMatchDetails,
  get,
  set
} from '../../redux/ducks/currentDataSlice';
import { setData } from '../../redux/ducks/riot';
import {
  doAddPlayerToMatch,
  doRemovePlayerFromMatch,
  doSwapTeams,
  doGetMatchDetails,
  doSetPlayerStats,
  doUpdatePlayer,
  doToggleSeriesLive,
  doDeleteMatch,
  doDeleteSeries,
  doAddPatch
} from '../requests/matches';

export function* handleAddPlayersToMatch(action) {
  const { matchId, playerObject } = action;
  try {
    const response = yield call(doAddPlayerToMatch, matchId, playerObject);
    if (response.status === 200) {
      yield put(getMatchDetails({ id: matchId }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleSetPlayerStats(action) {
  const { matchId, playerObject } = action;
  try {
    const response = yield call(doSetPlayerStats, matchId, playerObject);
    if (response.status === 200) {
      yield put(getMatchDetails({ id: matchId }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdatePlayer(action) {
  const { matchId, playerObject } = action;
  try {
    const response = yield call(doUpdatePlayer, matchId, playerObject);
    if (response.status === 200) {
      yield put(getMatchDetails({ id: matchId }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleRemovePlayerFromMatch(action) {
  const { matchId, playerId } = action;
  try {
    const response = yield call(doRemovePlayerFromMatch, matchId, playerId);
    if (response.status === 200) {
      yield put(getMatchDetails({ id: matchId }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleSwapTeams(action) {
  const { matchId } = action;
  try {
    yield call(doSwapTeams, matchId);
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
}

export function* handleGetMatchDetails(action) {
  const { id } = action.payload;
  try {
    const response = yield call(doGetMatchDetails, id);
    const { data } = response;
    if (data && data.payload) {
      yield put(setMatchDetails({ details: data.payload }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleToggleSeriesLive(action) {
  const {
    id,
    postPMT = false,
    seriesData,
    eventData,
    eventId,
    markSeriesCompleted = false
  } = action.payload;
  const reqData = { postPMT, seriesData, eventData, markSeriesCompleted };
  yield put(setData({ postingData: true }));
  try {
    const response = yield call(doToggleSeriesLive, id, reqData);
    const { data } = response;
    if (data && data.payload) {
      yield put(setMatchDetails({ details: data.payload }));
    }
  } catch (error) {
    console.log(error);
  }
  yield put(set({ category: 'series', data: undefined }));
  yield put(get({ category: 'events', dataId: eventId }));
  yield put(setData({ postingData: false }));
}

export function* handleDeleteMatch(action) {
  const { id } = action.payload;
  try {
    yield call(doDeleteMatch, id);
  } catch (error) {
    console.log(error);
  }
}

export function* handleDeleteSeries(action) {
  const { id, eventId } = action.payload;
  try {
    const response = yield call(doDeleteSeries, id);
    const { data } = response;
    if (data) {
      yield put(get({ category: 'events', dataId: eventId }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleAddPatch(action) {
  const { number, releaseDate } = action.payload;
  try {
    yield call(doAddPatch, { number, releaseDate });
  } catch (error) {
    console.log(error);
  }
}
