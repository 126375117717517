/* eslint-disable func-names */
import React, { useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getCustomers } from '../../redux/ducks/stripeSlice';

const styles = () => ({
  row: {
    '&:nth-child(odd)': {
      backgroundColor: '#303030'
    }
  }
});

const Stripe = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const { customers } = useSelector(state => state.stripe);

  const productMap = {
    prod_IutvmzRZdBDTwH: 'Diamond',
    prod_IutudNwivjsLlt: 'Immortal',
    prod_IutqC32tiS2Yb2: 'Radiant'
  };

  useEffect(() => {
    dispatch(getCustomers());
  }, []);

  return (
    <>
      <TableContainer component={Paper} sx={classes.tableContainer}>
        <Table sx={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography color="primary">Discord </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color="primary">Email</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color="primary">Sub</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color="primary">Interval</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color="primary">Sub start</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color="primary">Renewal date</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color="primary">Time subbed</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color="primary">Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers &&
              customers
                .slice()
                .sort(function(a, b) {
                  return (
                    (!b.subscriptions ||
                      !b.subscriptions[0] ||
                      b.subscriptions[0].current_period_end) -
                    (!a.subscriptions ||
                      !a.subscriptions[0] ||
                      a.subscriptions[0].current_period_end)
                  );
                })
                .map(customer => {
                  const { metadata, subscriptions, email, id } = customer;
                  const currentSub = subscriptions[0] || {};
                  const {
                    plan,
                    current_period_end: currentPeriodEnd,
                    created
                  } = currentSub || {};
                  const { product, interval_count: intervalCount, active } =
                    plan || {};
                  const { username, discriminator } = metadata || {};
                  const start = created ? moment.unix(created) : undefined;
                  return (
                    <TableRow key={id} sx={classes.row}>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{ padding: 0 }}
                      >
                        <Typography>
                          {username
                            ? `${username}#${discriminator}`
                            : 'no discord'}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{ padding: 0 }}
                      >
                        <Typography>{email}</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{ padding: 0 }}
                      >
                        <Typography>{productMap[product]}</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{ padding: 0 }}
                      >
                        <Typography>
                          {intervalCount ? `${intervalCount} month` : ''}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{ padding: 0 }}
                      >
                        <Typography>
                          {created
                            ? moment
                                .unix(created)
                                .format('MMM DD YYYY')
                                .toString()
                            : ''}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{ padding: 0 }}
                      >
                        <Typography>
                          {currentPeriodEnd
                            ? moment
                                .unix(currentPeriodEnd)
                                .format('MMM DD YYYY')
                                .toString()
                            : ''}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{ padding: 0 }}
                      >
                        <Typography>
                          {start ? start.fromNow(true) : ''}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{ padding: 0 }}
                      >
                        <Typography>
                          {active ? `active` : `Inactive`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Stripe;
