import { lightBlue, lime } from '@mui/material/colors';

const common = {
  rib: {
    main: '#6E6DD5',
    light: '#a7a6e5',
    dark: '#4645c9'
  },
  common: {
    white: '#FFF',
    grey: '#DDD',
    black: '#000',
    ribPurple: '#6E6DD5'
  }
};

export const darkPalette = {
  mode: 'dark',
  ...common,
  background: {
    default: '#303030'
  },
  primary: lightBlue,
  secondary: lime,
  backgrounds: {
    content: '#132330',
    card: '#223749',
    hover: '#3C556A',
    section: '#111',
    drawer: '#424242'
  },
  text: {
    main: '#DDDDDD',
    category: '#FFFFFF',
    header: '#CCCCCC',
    secondary: '#AAAAAA'
  },
  green: {
    seventh: '#104544',
    sixth: '#1C655E',
    fifth: '#299186',
    fourth: '#3CADA3',
    third: '#6ED7D3',
    second: '#A8EDEB',
    first: '#E7FFFE'
  },
  red: {
    first: '#611818',
    second: '#891A1B',
    third: '#B81F20',
    fourth: '#DC2F2F',
    fifth: '#E26464',
    sixth: '#F5AAAA',
    seventh: '#FCE8E7'
  },
  yellow: {
    first: '#5C4813',
    second: '#8C6D20',
    third: '#CAA43D',
    fourth: '#F4C964',
    fifth: '#FAE29F',
    sixth: '#FDF2D7',
    seventh: '#FEFCF4'
  },
  regions: {
    eu: '#b37d00',
    na: '#64A1D8',
    intl: '#6E6DD5',
    ap: '#E26464',
    latam: '#00807b',
    oc: '#908B7A',
    mena: '#884466'
  }
};

export const baseTheme = {
  spacing: [
    0, // 0
    4, // 1
    8, // 2
    12, // 3
    16, // 4
    24, // 5
    32, // 6
    48, // 7
    64, // 8
    96, // 9
    128, // 10
    190, // 11
    240, // 12
    384, // 14
    430, // 15
    640, // 16
    768, // 17
    1160 // 18
  ],
  typography: {
    headerUnselected: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px'
    },
    headerSelected: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '19px'
    },
    stickiedHeading: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '28px'
    },
    subHeadingBold: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '26px',
      lineHeight: '30px'
    },
    subHeading: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '30px'
    },
    subHeadingLight: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '26px',
      lineHeight: '30px'
    },
    subSubHeadingLight: {
      // don't laugh subsub is a real thing ok
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '20',
      lineHeight: '24px'
    },
    largeBold: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '14px'
    },
    large: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '14px'
    },
    largeLight: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '14px'
    },
    mainBold: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '14px'
    },
    main: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px'
    },
    mainLight: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px'
    },
    subtextBold: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '10px',
      lineHeight: '12px'
    },
    subtext: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '12px'
    },
    subtextLight: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '12px'
    },
    smallBold: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '8px',
      lineHeight: '9px'
    },
    small: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '8px',
      lineHeight: '9px'
    },
    smallLight: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '8px',
      lineHeight: '9px'
    },
    tooltip: {
      fontWeight: 500,
      fontSize: 12
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#031320',
          padding: '16px'
        },
        arrow: {
          color: '#031320'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none!important',
          backgroundColor: '#424242'
        }
      }
    }
  }
};
