import {
  Typography,
  Box,
  Select,
  MenuItem,
  Checkbox,
  Divider
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  filterEvents,
  getCompletedEvents,
  getFeaturedEvents,
  getOngoingEvents,
  getUpcomingEvents
} from './helpers';

export default function EventSearchResults({
  listData: events,
  category,
  classes,
  getCategoryListComponent
}) {
  const [typeFilter, setTypeFilter] = useState('all');
  const [hasSeriesFilter, setHasSeriesFilter] = useState(true);

  const filteredEvents = filterEvents(events, {
    type: typeFilter,
    hasSeries: hasSeriesFilter
  });

  const featuredEvents = getFeaturedEvents(filteredEvents);
  const upcomingEvents = getUpcomingEvents(filteredEvents);
  const ongoingEvents = getOngoingEvents(filteredEvents);
  const completedEvents = getCompletedEvents(filteredEvents);

  const cardWrapper = listItem => (
    <Box sx={classes.listItem} key={listItem.id}>
      <Link style={classes.link} to={`/${category}/${listItem.id}`}>
        {getCategoryListComponent(listItem)[category]}
      </Link>
    </Box>
  );

  const eventCategories = [
    {
      title: 'Featured Events',
      data: featuredEvents
    },
    {
      title: 'Ongoing Events',
      data: ongoingEvents
    },
    {
      title: 'Upcoming Events',
      data: upcomingEvents
    },
    {
      title: 'Completed Events',
      data: completedEvents
    }
  ];

  const types = ['all', 'circuit', 'stage', 'event', 'bracket'];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ width: '150px' }}>
          <Typography color="secondary" variant="caption">
            Type
          </Typography>
          <Select
            sx={{ width: '100%' }}
            value={typeFilter}
            onChange={e => setTypeFilter(e.target.value)}
          >
            {types.map(type => (
              <MenuItem value={type} key={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            width: '250px',
            marginLeft: '15px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography color="secondary" variant="caption">
            Hide events with no series
          </Typography>

          <Checkbox
            checked={hasSeriesFilter}
            onChange={e => setHasSeriesFilter(e.target.checked)}
          />
        </Box>
      </Box>
      <Divider sx={{ mt: '20px', mb: '20px' }} />{' '}
      <Box sx={{ display: 'flex' }}>
        {eventCategories.map(cat => {
          const { title, data } = cat;
          return (
            <>
              {data && data.length > 0 && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography> {title} </Typography>
                  {data.map(item => cardWrapper(item))}
                </Box>
              )}
            </>
          );
        })}
      </Box>
    </Box>
  );
}
