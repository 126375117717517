/* eslint-disable func-names */
import React, { useEffect } from 'react';
import { Typography, Box, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { search, setList } from '../../redux/ducks/listSlice';
import EventCard from '../events/EventCard';
import TeamCard from '../teams/TeamCard';
import PlayerCard from '../players/PlayerCard';
import EventSearchResults from '../events/EventSearchResults';

const styles = () => ({
  link: { cursor: 'pointer', textDecoration: 'none', color: 'inherit' },
  root: {
    display: 'flex'
  },
  button: {
    marginLeft: '15px'
  },
  listItem: {
    margin: '10px 10px 10px 0',
    cursor: 'pointer'
  },
  searchBar: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  }
});

const SearchField = (props) => {
  const classes = styles();
  const [searchTerm, setSearchTerm] = React.useState('');

  const {
    category,
    handleOnClick,
    clearSelected = false,
    linkable = false
  } = props;
  const dispatch = useDispatch();
  const list = useSelector((state) => state.list);
  const listData = list[category];

  useEffect(() => {
    if (searchTerm.length > 1 || category === 'events') {
      dispatch(search({ category, searchTerm }));
    }
  }, [dispatch, searchTerm]);

  useEffect(() => {
    setSearchTerm('');
    return () => {
      setSearchTerm('');
    };
  }, [category]);

  const handleItemClick = (listItem) => {
    if (clearSelected) {
      setSearchTerm('');
      dispatch(setList({ [category]: undefined }));
    }
    handleOnClick(listItem);
  };

  let sortable = [];
  if (listData && listData.length > 0) {
    sortable = [...listData];
    sortable.sort((a, b) => {
      return new Date(b.endDate) - new Date(a.endDate);
    });
  }
  const getCategoryListComponent = (data) => ({
    events: <EventCard key={data.id} data={data} />,
    teams: <TeamCard key={data.id} data={data} />,
    players: <PlayerCard key={data.id} data={data} />
  });

  return (
    <Box>
      <Box sx={classes.searchBar}>
        <TextField
          value={searchTerm}
          inputProps={{
            onChange: (event) => setSearchTerm(event.target.value)
          }}
          label="Search"
          variant="outlined"
        />
      </Box>
      {listData && listData.length > 0 ? (
        <Box sx={classes.listContainer}>
          {category === 'events' && (
            <EventSearchResults
              listData={listData}
              linkable={linkable}
              classes={classes}
              category={category}
              getCategoryListComponent={getCategoryListComponent}
              handleItemClick={handleItemClick}
            />
          )}
          {category !== 'events' &&
            listData.map((listItem) =>
              !linkable ? (
                <Box
                  sx={classes.listItem}
                  key={listItem.id}
                  onClick={() => handleItemClick(listItem)}
                >
                  {getCategoryListComponent(listItem)[category]}
                </Box>
              ) : (
                <Box sx={classes.listItem} key={listItem.id}>
                  <Link
                    className={classes.link}
                    to={`/${category}/${listItem.id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    {getCategoryListComponent(listItem)[category]}
                  </Link>
                </Box>
              )
            )}
        </Box>
      ) : (
        <>
          {searchTerm.length > 0 ? (
            <Typography color="secondary"> No results found. </Typography>
          ) : (
            <Typography color="secondary"> Search to see results.</Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default SearchField;
