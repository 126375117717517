import React from 'react';
import {
  Button,
  LinearProgress,
  Box,
  MenuItem,
  FormControlLabel
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField, CheckboxWithLabel, Switch } from 'formik-mui';
import { DatePicker } from 'formik-mui-x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { create } from '../../redux/ducks/data';
import { playerRoleOptions } from '../../constants/constants';

const styles = () => ({
  select: {
    minWidth: '120px'
  },
  currency: {
    marginTop: '0',
    marginBottom: '0'
  }
});

const DensePlayerForm = (props) => {
  const { data, isNew, teamId, teamName, teamCountryId, gameName, puuid } =
    props;
  const { countryList } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const classes = styles();

  return (
    <Formik
      initialValues={
        data && !isNew
          ? data
          : {
              ign: gameName,
              countryId: teamCountryId,
              dateJoined: new Date(),
              addToTeam: true,
              role: 'player',
              igl: false
            }
      }
      validate={(values) => {
        const errors = {};
        if (!values.ign) {
          errors.ign = 'Required';
        }
        console.log(values.dateJoined);
        if (teamId && !values.dateJoined) {
          errors.dateJoined = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        const newValues = {
          ...values,
          igl: values.igl === 'on' || values.igl === true,
          teamId: teamId && values.addToTeam ? teamId : values.teamId,
          dateJoined:
            values.dateJoined && moment(values.dateJoined).format('YYYY-MM-DD')
        };

        delete newValues.addToTeam;

        setTimeout(() => {
          setSubmitting(false);
          dispatch(create('players', newValues, true, puuid));
          console.log(newValues);
        }, 500);
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          {teamId && (
            <>
              <Box margin={1}>
                <Field
                  component={DatePicker}
                  name="dateJoined"
                  label="Date joined team"
                />
              </Box>
              <Box margin={1}>
                <Field
                  sx={{ width: '200px' }}
                  component={TextField}
                  type="text"
                  name="role"
                  label="Role"
                  select
                  variant="standard"
                  margin="normal"
                >
                  {playerRoleOptions.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Field>
              </Box>
              <Box margin={1}>
                <FormControlLabel
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Field component={Switch} type="checkbox" name="igl" />
                  }
                  label="Is the player the IGL?"
                />
              </Box>
            </>
          )}
          <Box margin={1}>
            <Field component={TextField} name="ign" type="text" label="ign" />
          </Box>
          <Box margin={1}>
            <Field
              sx={classes.select}
              component={TextField}
              type="text"
              name="countryId"
              label="Country"
              select
              variant="standard"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            >
              {countryList &&
                countryList.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.niceName}
                  </MenuItem>
                ))}
            </Field>
          </Box>
          <Box margin={1}>
            <Field
              type="checkbox"
              component={CheckboxWithLabel}
              name="addToTeam"
              Label={{ label: `Add player to team ${teamName}?` }}
            />
          </Box>
          {isSubmitting && <LinearProgress />}
          <Box margin={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default DensePlayerForm;
