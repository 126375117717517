import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Link,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  getTrending,
  getAllTrending,
  deleteTrending
} from '../../redux/ducks/trendingSlice';
import AddTrendingDialog from './AddTrendingDialog';

const styles = () => ({
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly'
  }
});

const Potw = () => {
  const dispatch = useDispatch();
  const { allPotws, currentPotw, allTotws, currentTotw } = useSelector(
    state => state.trending
  );
  const classes = styles();
  const [adding, setAdding] = useState(false);
  const [addingCategory, setAddingCategory] = useState();
  useEffect(() => {
    dispatch(getAllTrending({ category: 'potw' }));
    dispatch(getAllTrending({ category: 'totw' }));
  }, []);

  useEffect(() => {
    dispatch(getTrending({ category: 'potw' }));
    dispatch(getTrending({ category: 'totw' }));
  }, []);

  return (
    <>
      {adding && (
        <AddTrendingDialog
          category={addingCategory}
          adding={adding}
          setAdding={setAdding}
        />
      )}
      <Box sx={{ display: 'flex', placeContent: 'space-evenly' }}>
        <Box sx={{ width: '100%', padding: '15px' }}>
          {currentPotw && (
            <Box>
              <Typography variant="h6">Current POTW:</Typography>
              {currentPotw.imageUrl && (
                <img
                  src={currentPotw.imageUrl}
                  alt={currentPotw.ign}
                  style={{ width: '200px' }}
                />
              )}
            </Box>
          )}
          <Button
            variant="outlined"
            onClick={() => {
              setAdding(true);
              setAddingCategory('potw');
            }}
            color="primary"
            autoFocus
          >
            Add POTW
          </Button>
          {allPotws && allPotws.length > 0 && (
            <TableContainer component={Paper} sx={classes.tableContainer}>
              <Table sx={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" />
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">IGN</TableCell>
                    <TableCell align="center">Image url</TableCell>
                    <TableCell align="center">Article url</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allPotws.map(potw => {
                    const {
                      id,
                      dateAdded,
                      imageUrl,
                      articleUrl,
                      player,
                      playerId
                    } = potw;
                    return (
                      <TableRow key={dateAdded}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ padding: 0 }}
                        >
                          <IconButton>
                            <CloseIcon
                              fontSize="small"
                              onClick={() =>
                                dispatch(
                                  deleteTrending({ category: 'potw', id })
                                )
                              }
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ padding: 0 }}
                        >
                          {moment(dateAdded)
                            .format('YYYY-MM-DD')
                            .toString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ padding: 0 }}
                        >
                          <Typography color="primary">
                            <Link
                              target="_blank"
                              href={`https://rib.gg/players/${playerId}`}
                            >
                              {player && player.ign}
                            </Link>
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ padding: 0 }}
                        >
                          <Typography color="primary">
                            <Link target="_blank" href={articleUrl}>
                              {articleUrl && `${articleUrl.slice(0, 20)}...`}
                            </Link>
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ padding: 0 }}
                        >
                          <Typography>
                            <Link target="_blank" href={imageUrl}>
                              {imageUrl && `${imageUrl.slice(0, 20)}...`}
                            </Link>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <Box sx={{ width: '100%', padding: '15px' }}>
          {currentTotw && (
            <Box>
              <Typography variant="h6">Current TOTW:</Typography>
              {currentTotw.imageUrl && (
                <img
                  src={currentTotw.imageUrl}
                  alt={currentTotw.name}
                  style={{ width: '200px' }}
                />
              )}
            </Box>
          )}
          <Button
            variant="outlined"
            onClick={() => {
              setAdding(true);
              setAddingCategory('totw');
            }}
            color="primary"
            autoFocus
          >
            Add TOTW
          </Button>

          {allTotws && allTotws.length > 0 && (
            <TableContainer component={Paper} sx={classes.tableContainer}>
              <Table sx={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" />
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Image url</TableCell>
                    <TableCell align="center">Article url</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allTotws.map(totw => {
                    const {
                      id,
                      dateAdded,
                      imageUrl,
                      articleUrl,
                      team,
                      teamId
                    } = totw;
                    return (
                      <TableRow key={dateAdded}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ padding: 0 }}
                        >
                          <IconButton>
                            <CloseIcon
                              fontSize="small"
                              onClick={() =>
                                dispatch(
                                  deleteTrending({ category: 'totw', id })
                                )
                              }
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ padding: 0 }}
                        >
                          {moment(dateAdded)
                            .format('YYYY-MM-DD')
                            .toString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ padding: 0 }}
                        >
                          <Typography color="primary">
                            <Link
                              target="_blank"
                              href={`https://rib.gg/teamss/${teamId}`}
                            >
                              {team && team.name}
                            </Link>
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ padding: 0 }}
                        >
                          <Typography color="primary">
                            <Link target="_blank" href={articleUrl}>
                              {articleUrl && `${articleUrl.slice(0, 20)}...`}
                            </Link>
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ padding: 0 }}
                        >
                          <Typography>
                            <Link target="_blank" href={imageUrl}>
                              {imageUrl && `${imageUrl.slice(0, 20)}...`}
                            </Link>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Potw;
