import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  Tooltip,
  IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { doNewPuuid } from '../../redux/ducks/data';
import { backendBaseUrl } from '../../constants/constants';
import CareerHistory from './CareerHistory';

const styles = () => ({
  img: {
    height: '250px',
    maxWidth: '250px'
  },
  deleteButton: {
    marginTop: '50px'
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px'
  }
});

const Player = (props) => {
  const { data } = props;

  const history = useNavigate();
  const {
    id,
    ign,
    bio,
    firstName,
    lastName,
    countryId,
    imageUrl,
    instagramUrl,
    twitchUrl,
    twitterUrl,
    youtubeUrl,
    team,
    previousRiotPlayerIds
  } = data;
  const { countryList } = useSelector((state) => state.data);
  const { name: teamName, id: teamId } = team || {};
  const [enteredPuuid, setPuuid] = React.useState('');
  const dispatch = useDispatch();
  const country =
    countryList && countryList.filter((country) => country.id === countryId)[0];
  const classes = styles(props);

  const [open, setOpen] = useState(false);
  const [deletingPuuid, setDeletingPuuid] = useState();
  const [careerHistory, setCareerHistory] = useState([]);

  const getCareerUrl = (id) => `${backendBaseUrl}/cms/players/career/${id}`;
  const updateCareerUrl = () => `${backendBaseUrl}/cms/players/update-career`;
  const deleteCareerItemUrl = () =>
    `${backendBaseUrl}/cms/players/delete-career-item`;

  useEffect(() => {
    async function fetchCareerHistory() {
      try {
        const resp = await axios.request({
          method: 'GET',
          url: getCareerUrl(id),
          withCredentials: true
        });
        const { data } = resp;
        const { payload } = data || {};
        const { career } = payload || {};

        setCareerHistory(career);
      } catch (error) {
        console.error(error);
      }
    }
    fetchCareerHistory();
  }, [id]);

  const handleUpdateCareerHistory = async (updatedCareerItem) => {
    try {
      try {
        await axios.request({
          method: 'POST',
          url: updateCareerUrl(),
          withCredentials: true,
          data: updatedCareerItem
        });
      } catch (error) {
        console.error(error);
      }
      // update local state to reflect the changes
      setCareerHistory((prevCareerHistory) =>
        prevCareerHistory.map((item) =>
          item.teamPlayerHistoryId === updatedCareerItem.teamPlayerHistoryId
            ? updatedCareerItem
            : item
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteCareerHistoryItem = async (careerHistory) => {
    try {
      try {
        await axios.request({
          method: 'POST',
          url: deleteCareerItemUrl(),
          withCredentials: true,
          data: careerHistory
        });
      } catch (error) {
        console.error(error);
      }

      setCareerHistory((prevCareerHistory) =>
        prevCareerHistory.filter(
          (item) =>
            item.teamPlayerHistoryId !== careerHistory.teamPlayerHistoryId
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = (deletePuuid) => {
    if (deletePuuid) {
      dispatch(doNewPuuid(id, deletingPuuid, 'remove'));
    }
    setOpen(false);
    setDeletingPuuid();
  };
  return (
    <>
      <img
        src={imageUrl || 'https://i.imgur.com/s8elSjO.png'}
        style={classes.img}
        alt={ign}
      />
      <Typography color="secondary" variant="h2">
        {ign}
      </Typography>
      {teamName && teamId && (
        <>
          <Link to={`/teams/${teamId}`} sx={{ textDecoration: 'none' }}>
            <Typography color="primary" variant="h6">
              {teamName}
            </Typography>
          </Link>
        </>
      )}
      <Button
        color="primary"
        variant="contained"
        onClick={() => history(`/players/${id}/edit`)}
      >
        Edit
      </Button>
      <Divider sx={classes.divider} />
      <Link
        to={`https://rib.gg/players/${id}`}
        sx={{ textDecoration: 'none' }}
        target="_blank"
      >
        <Typography
          color="secondary"
          variant="body"
        >{`https://rib.gg/players/${id}`}</Typography>
      </Link>
      {country && <Typography> Country: {country.niceName} </Typography>}
      {firstName && <Typography> {`First name: ${firstName}`} </Typography>}
      {lastName && <Typography> {`Last name: ${lastName}`} </Typography>}
      <Typography> {bio} </Typography>
      <Divider sx={classes.divider} />
      {instagramUrl && <Typography> {instagramUrl} </Typography>}
      {twitchUrl && <Typography> {twitchUrl} </Typography>}
      {twitterUrl && <Typography> {twitterUrl} </Typography>}
      {youtubeUrl && <Typography> {youtubeUrl} </Typography>}
      <Divider sx={classes.divider} />

      <Typography variant="h6" color="primary">
        Edit Career History:
      </Typography>
      {careerHistory &&
        careerHistory.map((careerItem) => (
          <CareerHistory
            key={careerItem.teamPlayerHistoryId}
            careerItem={careerItem}
            onUpdateCareerHistory={handleUpdateCareerHistory}
            handleDeleteCareerHistoryItem={handleDeleteCareerHistoryItem}
          />
        ))}
      <>
        <Divider sx={classes.divider} />
        <Typography variant="h6"> Previous PUUIDs:</Typography>
        {previousRiotPlayerIds &&
          previousRiotPlayerIds.map((puuid) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }} key={puuid}>
              <Typography> {puuid} </Typography>
              <Tooltip title="Remove puuid from player">
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setDeletingPuuid(puuid);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ))}
        <Box>
          <TextField
            value={enteredPuuid}
            onChange={(event) => setPuuid(event.target.value)}
            placeholder="new puuid"
            sx={{ width: '750px' }}
          />
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(doNewPuuid(id, enteredPuuid, 'add'));
              setPuuid('');
            }}
          >
            Add Puuid
          </Button>
        </Box>
        <Dialog open={open} onClose={() => handleClose(false)}>
          <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to delete the puuid ${deletingPuuid} from ${ign}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(true)} color="primary">
              Yes
            </Button>
            <Button
              onClick={() => handleClose(false)}
              color="primary"
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default Player;
