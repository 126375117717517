import React from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Divider,
  TextField,
  MenuItem,
  Select,
  Switch
} from '@mui/material';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, Link } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import PlayerCard from '../players/PlayerCard';
import {
  addPlayerToTeam,
  doTeamAlias,
  removePlayerFromTeam
} from '../../redux/ducks/data';
import SearchField from '../layout/SearchField';
import {
  playerRoleOptions,
  regionShortNameMap
} from '../../constants/constants';

const styles = () => ({
  img: {
    height: '300px'
  },
  playerCardContainer: {
    marginTop: '10px'
  },
  currentPlayersText: {
    marginRight: '40px'
  },
  currentPlayersContainer: {
    display: 'flex'
  },
  playerContainer: {
    display: 'flex'
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    justifyContent: 'center'
  },
  select: {
    width: '200px'
  }
});

const Team = (props) => {
  const { data } = props;
  const { countryList } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const [addingPlayer, setAddingPlayer] = React.useState(false);
  const [dateJoined, setDateJoined] = React.useState(
    moment().format('YYYY-MM-DD')
  );
  const [showDateLeft, setShowDateLeft] = React.useState(false);

  const [dateLeft, setDateLeft] = React.useState(null);
  const [playerRole, setPlayerRole] = React.useState('player');
  const [isIgl, setIsIgl] = React.useState(false);
  const [removingPlayer, setRemovingPlayer] = React.useState(false);
  const [removedPlayer, setRemovedPlayer] = React.useState(undefined);
  const [alias, setAlias] = React.useState('');
  const [endDate, setEndDate] = React.useState(null);
  const [selectedPlayers, setSelectedPlayers] = React.useState({});

  const history = useNavigate();

  const {
    id,
    name,
    description,
    foundedDate,
    logoUrl,
    websiteUrl,
    liquipediaUrl,
    twitterUrl,
    vlrUrl,
    youtubeUrl,
    twitchUrl,
    players: onTeamPeople,
    countryId,
    regionId,

    shortName,
    aliases,
    division,
    vctRegion
  } = data;

  const classes = styles(props);

  const handleOpen = (setStaff = false) => {
    setAddingPlayer(true);
    if (setStaff) setPlayerRole('staff');
    if (!setStaff) setPlayerRole('player');
  };

  const handleClose = () => {
    setSelectedPlayers({});
    setAddingPlayer(false);
    setRemovingPlayer(false);
  };

  const handleAddPlayer = () => {
    if (Object.keys(selectedPlayers).length > 0) {
      dispatch(addPlayerToTeam(id, selectedPlayers));
    }
    handleClose();
  };

  const handleRemovePlayer = () => {
    if (id && removedPlayer) {
      dispatch(removePlayerFromTeam(id, removedPlayer, endDate));
    }
    handleClose();
  };

  const handleSelectPlayer = (listItem) => {
    setSelectedPlayers((selectedPlayers) => ({
      ...selectedPlayers,
      [listItem.id]: {
        ...listItem,
        dateJoined,
        dateLeft,
        playerRole,
        igl: isIgl
      }
    }));
  };

  const handleDeselectPlayer = (player) => {
    setSelectedPlayers((curr) => {
      const { [player]: _, ...newState } = curr;
      return newState;
    });
  };

  const countryName =
    countryList &&
    countryList.find((c) => c.id === countryId) &&
    countryList.find((c) => c.id === countryId).niceName;

  const players =
    onTeamPeople && onTeamPeople.filter((player) => player.role === 'player');

  const staff =
    onTeamPeople && onTeamPeople.filter((player) => player.role !== 'player');
  return (
    <>
      <Box sx={classes.image}>
        <img src={logoUrl} alt={name} style={classes.img} />
      </Box>
      <Typography variant="h2"> {name} </Typography>{' '}
      <Box sx={{ display: 'inline-grid' }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => history(`/players/new/edit/${id}`)}
        >
          Create new player in this team
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => history(`/teams/${id}/edit`)}
        >
          Edit Team
        </Button>
      </Box>
      <Divider sx={classes.divider} />
      <Box>
        <Typography> {`Founded: ${foundedDate}`} </Typography>
        {websiteUrl && (
          <Link to={websiteUrl}>
            <Typography> Website</Typography>
          </Link>
        )}
        {liquipediaUrl && (
          <Link to={liquipediaUrl}>
            <Typography> Liquipedia</Typography>
          </Link>
        )}
        {twitterUrl && (
          <Link to={twitterUrl}>
            <Typography> Twitter</Typography>
          </Link>
        )}
        {twitchUrl && (
          <Link to={twitchUrl}>
            <Typography> Twitch</Typography>
          </Link>
        )}
        {youtubeUrl && (
          <Link to={youtubeUrl}>
            <Typography> Youtube</Typography>
          </Link>
        )}
        {vlrUrl && (
          <Link to={vlrUrl}>
            <Typography> Vlr</Typography>
          </Link>
        )}
      </Box>
      <Divider sx={classes.divider} />
      <Link
        target="_blank"
        to={`https://rib.gg/teams/${id}`}
        sx={{ textDecoration: 'none' }}
      >
        <Typography
          color="secondary"
          variant="body"
        >{`https://rib.gg/teams/${id}`}</Typography>
      </Link>
      <Typography>{`Aliases: `}</Typography>
      {aliases &&
        aliases.map((currentAlias) => (
          <Box>
            <Typography color="primary" variant="caption">
              {currentAlias}
            </Typography>
            <Tooltip title="Remove alias from team">
              <IconButton
                onClick={() => {
                  dispatch(doTeamAlias(id, currentAlias, 'remove'));
                }}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
      <Box>
        <TextField
          value={alias}
          onChange={(event) => setAlias(event.target.value)}
          placeholder="new alias"
        />
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(doTeamAlias(id, alias, 'add'));
            setAlias('');
          }}
        >
          Add Alias
        </Button>
      </Box>
      <Typography>{`Short Name: ${shortName}`}</Typography>
      <Typography variant="caption"> {description} </Typography>
      <Divider sx={classes.divider} />
      <Typography> {`CountryId: ${countryId}`} </Typography>
      <Typography>{`Country Name: ${countryName}`}</Typography>
      <Typography> {`RegionId: ${regionId}`} </Typography>
      <Typography> {`Division: ${division}`} </Typography>
      <Typography> {`Vct Region: ${vctRegion}`} </Typography>
      <Typography>
        {`Region Short Name: ${regionShortNameMap[regionId]}`}
      </Typography>
      <Divider sx={classes.divider} />
      <>
        <Divider sx={classes.divider} />
        <Box sx={classes.currentPlayersContainer}>
          <Typography variant="h5" sx={classes.currentPlayersText}>
            Current Players
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleOpen()}
          >
            Add player
          </Button>
        </Box>
        {players &&
          players.length > 0 &&
          players.map((playerData) => (
            <Box sx={classes.playerContainer} key={playerData.id}>
              <Box
                onClick={() => history(`/players/${playerData.id}`)}
                sx={classes.playerCardContainer}
              >
                <PlayerCard data={playerData} />
              </Box>
              <Box sx={classes.iconContainer}>
                <Tooltip title="Remove player from team">
                  <IconButton
                    onClick={() => {
                      setRemovedPlayer(playerData.id);
                      setRemovingPlayer(true);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ))}
      </>
      <>
        <Divider sx={classes.divider} />
        <Box sx={classes.currentPlayersContainer}>
          <Typography variant="h5" sx={classes.currentPlayersText}>
            Current Staff
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleOpen(true)}
          >
            Add Staff
          </Button>
        </Box>
        {staff &&
          staff.length > 0 &&
          staff.map((playerData) => (
            <Box sx={classes.playerContainer} key={playerData.id}>
              <Box
                onClick={() => history(`/players/${playerData.id}`)}
                sx={classes.playerCardContainer}
              >
                <PlayerCard data={playerData} />
              </Box>
              <Box sx={classes.iconContainer}>
                <Tooltip title="Remove player from team">
                  <IconButton
                    onClick={() => {
                      setRemovedPlayer(playerData.id);
                      setRemovingPlayer(true);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ))}
      </>
      <Dialog open={removingPlayer} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          <span>
            <Typography>When did this player leave the team?</Typography>
            <Typography color="secondary">
              (Leave blank if player was accidently added to team)
            </Typography>
          </span>
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: '20px' }}
            label="Date Left"
            type="date"
            defaultValue={endDate}
            value={endDate}
            InputProps={{
              onChange: (e) => setEndDate(e.target.value)
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleRemovePlayer} color="primary" autoFocus>
            Remove Player
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addingPlayer} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">Select a player</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '600px', height: '400px' }}>
            <TextField
              sx={{ marginTop: '20px' }}
              label="Date Joined"
              type="date"
              defaultValue={dateJoined}
              value={dateJoined}
              InputProps={{
                onChange: (e) => setDateJoined(e.target.value)
              }}
              InputLabelProps={{
                shrink: true
              }}
            />

            {showDateLeft ? (
              <TextField
                sx={{ marginTop: '20px' }}
                label="Date left (if the player is not on this team anymore)"
                type="date"
                defaultValue={null}
                value={dateLeft}
                InputProps={{
                  onChange: (e) => setDateLeft(e.target.value)
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            ) : (
              <Button onClick={() => setShowDateLeft(true)}>
                Set Date Left
              </Button>
            )}
            <Box margin={1}>
              <Select
                label="Player Role"
                value={playerRole}
                onChange={(event) => setPlayerRole(event.target.value)}
              >
                {playerRoleOptions.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box margin={1}>
              <Typography>Is the player the IGL?</Typography>
              <Switch
                checked={isIgl}
                onChange={() => {
                  setIsIgl((curr) => !curr);
                }}
              />
            </Box>
            {Object.keys(selectedPlayers).map((player) => {
              const { id, ign, dateJoined, igl, role } =
                selectedPlayers[player];
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <span>
                    <Typography color="primary"> player id: {id} </Typography>
                    <Typography color="primary"> {ign} </Typography>
                    <Typography color="secondary"> {role} </Typography>
                    <Typography color="secondary">
                      Is igl: {igl || 'false'}
                    </Typography>
                    <Typography color="secondary">
                      {` Joined: ${dateJoined}`}
                    </Typography>
                    <Typography color="secondary">
                      {` Left: ${dateLeft}`}
                    </Typography>
                  </span>
                  <IconButton onClick={() => handleDeselectPlayer(player)}>
                    <CloseIcon color="error" fontSize="small" />
                  </IconButton>
                </Box>
              );
            })}
            <SearchField
              category="players"
              handleOnClick={handleSelectPlayer}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddPlayer} color="primary" autoFocus>
            Add Player
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Team;
