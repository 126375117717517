import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Button, TextField } from '@mui/material';

import { getData } from '../../../redux/ducks/riot';
import RiotPlayerCard from './RiotPlayerCard';

const styles = () => ({
  searchBar: {
    marginTop: '10px',
    width: '400px',
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    marginLeft: '10px'
  }
});

const RiotPlayerLookup = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { playerSearchResults, gettingData } = useSelector(state => state.riot);
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <Box>
      <Typography variant="h4"> Riot Player Search</Typography>
      <Box sx={classes.searchBar}>
        <TextField
          inputProps={{
            onChange: event => setSearchQuery(event.target.value)
          }}
          label="IGN"
          variant="outlined"
          fullWidth
          value={searchQuery}
        />
        <Button
          onClick={() =>
            dispatch(getData('searchPlayerIGN', { ign: searchQuery }))
          }
          sx={classes.button}
          variant="contained"
          color="primary"
          disabled={gettingData}
        >
          Search
        </Button>
      </Box>
      {playerSearchResults && playerSearchResults.length > 0 && (
        <>
          <Typography variant="h4"> Results: </Typography>
          {playerSearchResults.map(player => (
            <RiotPlayerCard key={player.puuid} {...player} />
          ))}
        </>
      )}
      {playerSearchResults && playerSearchResults.length === 0 && (
        <Typography variant="h4"> No players match your results </Typography>
      )}
    </Box>
  );
};

export default RiotPlayerLookup;
