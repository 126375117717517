import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';

export default function Lineage({ lineage }) {
  const colorMap = {
    circuit: 'error',
    stage: 'secondary',
    event: 'primary',
    bracket: 'default'
  };
  return (
    <Breadcrumbs>
      {lineage &&
        lineage.map((l) => {
          const { shortName, name, id, type } = l;
          return (
            <Link
              key={id}
              to={`/events/${id}`}
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography color={colorMap[type]}>
                {shortName || name}
              </Typography>
            </Link>
          );
        })}
    </Breadcrumbs>
  );
}
