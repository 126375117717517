import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get, set } from '../../redux/ducks/currentDataSlice';
import EventForm from '../events/EventForm';
import TeamForm from '../teams/TeamForm';
import PlayerForm from '../players/PlayerForm';

const EditLayout = () => {
  const { id, category, teamId } = useParams();
  const isNew = id === 'new';
  const data = useSelector(state => state.currentData)[category];
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isNew) {
      dispatch(get({ category, dataId: id }));
    }
    return () => {
      dispatch(set({ category, data: undefined }));
    };
  }, [dispatch]);

  const categoryMap = {
    events: <EventForm isNew={isNew} id={id} data={data} />,
    teams: <TeamForm isNew={isNew} id={id} data={data} />,
    players: <PlayerForm isNew={isNew} id={id} data={data} teamId={teamId} />
  };

  return (
    <>
      <Typography variant="h4">
        {isNew
          ? `New ${
              category !== 'series' && category !== 'matches'
                ? category.substring(0, category.length - 1)
                : category
            }`
          : `Edit`}
      </Typography>
      {(isNew || data) && categoryMap[category]}
    </>
  );
};

export default EditLayout;
