import React from 'react';
import { Box, List, ListItem, ListItemText, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
  container: {
    width: '200px',
    backgroundColor: theme.palette.backgrounds.drawer,
    borderRight: '1px solid rgba(255, 255, 255, 0.12);'
  }
});

const CMSDrawer = () => {
  const theme = useTheme();
  const classes = styles(theme);

  const cmsRoutes = [
    {
      name: 'Circuits',
      url: '/circuits'
    },
    {
      name: 'Events',
      url: '/events'
    },
    {
      name: 'Teams',
      url: '/teams'
    },
    {
      name: 'Players',
      url: '/players'
    }
  ];

  const apiRoutes = [
    {
      name: 'Add Player',
      url: '/riot-add-player'
    },
    {
      name: 'Player Lookup',
      url: '/riot-player-lookup'
    },
    {
      name: 'Match History Lookup',
      url: '/riot-match-history-lookup'
    },
    {
      name: 'Manual Riot Match Connection',
      url: '/connect-match'
    }
  ];

  const betaRoutes = [
    {
      name: 'Add Patch ',
      url: '/patch'
    },
    {
      name: 'Fantasy',
      url: '/fantasy'
    },

    {
      name: 'Rankings',
      url: '/rankings'
    },

    {
      name: 'Player Merging',
      url: '/player-merge'
    },
    {
      name: 'Player Ranking',
      url: '/player-rankings'
    },
    {
      name: 'Brackets ',
      url: '/brackets'
    },
    {
      name: 'Stripe (for pickles)',
      url: '/stripe'
    }
  ];

  return (
    <Box sx={classes.container} variant="permanent" anchor="left">
      <List>
        <ListItem key="CMS">
          <ListItemText sx={{ color: '#ffac33' }} primary="CMS" />
        </ListItem>
        {cmsRoutes.map((route) => {
          const { name, url } = route;
          return (
            <Link
              to={url}
              style={{ textDecoration: 'none', color: 'inherit' }}
              key={name}
            >
              <ListItem button key={url}>
                <ListItemText primary={name} />
              </ListItem>
            </Link>
          );
        })}
        <ListItem key="API">
          <ListItemText sx={{ color: '#ffac33' }} primary="API" />
        </ListItem>
        {apiRoutes.map((route) => {
          const { name, url } = route;
          return (
            <Link
              to={url}
              style={{ textDecoration: 'none', color: 'inherit' }}
              key={name}
            >
              <ListItem button key={url}>
                <ListItemText primary={name} />
              </ListItem>
            </Link>
          );
        })}
        <ListItem key="BETA">
          <ListItemText
            sx={{ color: '#ffac33' }}
            primary="MISC / TESTING FEATURES"
          />
        </ListItem>
        {betaRoutes.map((route) => {
          const { name, url } = route;
          return (
            <Link
              to={url}
              style={{ textDecoration: 'none', color: 'inherit' }}
              key={name}
            >
              <ListItem button key={url}>
                <ListItemText primary={name} />
              </ListItem>
            </Link>
          );
        })}
      </List>
    </Box>
  );
};

export default CMSDrawer;
