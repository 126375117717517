import { Button, DialogActions as MuiDialogActions } from '@mui/material';
import React from 'react';

export default function DialogActions({ handleCancel, handleConfirm }) {
  return (
    <MuiDialogActions>
      <Button onClick={handleCancel} color="secondary">
        Cancel
      </Button>
      <Button
        onClick={() => {
          handleConfirm();
        }}
        color="primary"
        autoFocus
      >
        Confirm
      </Button>
    </MuiDialogActions>
  );
}
