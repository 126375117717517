import React, { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  Switch,
  TextField
} from '@mui/material';
import moment from 'moment';
import { playerRoleOptions } from '../../constants/constants';

const CareerHistory = ({
  careerItem,
  onUpdateCareerHistory,
  handleDeleteCareerHistoryItem
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedCareerItem, setEditedCareerItem] = useState({
    ...careerItem,
    startDate: moment(careerItem.startDate).format('YYYY-MM-DD'),
    endDate: careerItem.endDate
      ? moment(careerItem.endDate).format('YYYY-MM-DD')
      : null
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    const careerItemWithUTCDate = {
      ...editedCareerItem,
      startDate: moment(editedCareerItem.startDate).utc().format(),
      endDate: editedCareerItem.endDate
        ? moment(editedCareerItem.endDate).utc().format()
        : null
    };
    onUpdateCareerHistory(careerItemWithUTCDate);
  };

  const handleChange = (event, useChecked = false) => {
    setEditedCareerItem({
      ...editedCareerItem,
      [event.target.name]: useChecked
        ? event.target.checked
        : event.target.value
    });
  };

  return (
    <Box>
      <Typography>{careerItem.teamName}</Typography>
      {isEditing ? (
        <>
          <Box sx={{ my: 2 }}>
            <TextField
              sx={{ marginTop: '20px' }}
              label="Date Joined"
              type="date"
              name="startDate"
              value={editedCareerItem.startDate}
              InputProps={{
                onChange: handleChange
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <TextField
              label="End date"
              type="date"
              value={editedCareerItem.endDate}
              name="endDate"
              InputProps={{
                onChange: handleChange
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>
          <Box margin={1}>
            <Select
              label="Player Role"
              value={editedCareerItem.role}
              onChange={(e) => handleChange(e, false)}
              name="role"
            >
              {playerRoleOptions.map((val) => (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box margin={1}>
            <Typography>Is the player the IGL?</Typography>
            <Switch
              name="igl"
              checked={editedCareerItem.igl}
              onChange={(e) => handleChange(e, true)}
            />
          </Box>
          <Button variant="outlined" color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleDeleteCareerHistoryItem(careerItem)}
          >
            {`Delete this team from this player's history`}
          </Button>
        </>
      ) : (
        <>
          <Button onClick={handleEdit}>Edit</Button>
        </>
      )}
    </Box>
  );
};

export default CareerHistory;
