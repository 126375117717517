import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addPatch } from '../../redux/ducks/currentDataSlice';
import ConfirmDialog from '../shared/ConfirmDialog';

const AddPatch = () => {
  const dispatch = useDispatch();
  const [number, setNumber] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [addOpen, setAddOpen] = useState(false);

  const handleAddPatch = () => {
    dispatch(addPatch({ number, releaseDate: startDate }));
  };

  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4"> Add a new patch </Typography>
        <Typography variant="caption"> Example value: 6.01 </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <TextField value={number} onChange={(e) => setNumber(e.target.value)} />
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />

        <Button
          color="secondary"
          variant="outlined"
          onClick={() => setAddOpen(true)}
        >
          Add Patch
        </Button>
      </Box>
      <ConfirmDialog
        open={addOpen}
        setOpen={setAddOpen}
        title="Add Patch (double check patch number)"
        onConfirm={handleAddPatch}
      />
    </Box>
  );
};

export default AddPatch;
