import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';

export function doGetCountryList() {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/cms/countryList`,
    withCredentials: true
  });
}

export function doGetRegionList() {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/cms/regionList`,
    withCredentials: true
  });
}

export function doGetMapList() {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/cms/mapList`,
    withCredentials: true
  });
}

export function doGetAgentList() {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/cms/agentList`,
    withCredentials: true
  });
}

export function doGet(category, dataId) {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/cms/${category}/get/${dataId}`,
    withCredentials: true
  });
}

export function doSearch(category, searchTerm) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/${category}/search`,
    data: {
      searchTerm
    },
    withCredentials: true
  });
}

export function doCreate(category, data, foolData = false) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/${category}/create`,
    data: {
      data,
      foolDataReturn: foolData
    },
    withCredentials: true
  });
}

export function doEdit(category, dataId, data) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/${category}/edit/${dataId}`,
    data: {
      data
    },
    withCredentials: true
  });
}

export function doDelete(category, dataId) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/${category}/delete/${dataId}`,
    withCredentials: true
  });
}
