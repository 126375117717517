import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Box,
  Button,
  TextField,
  Select,
  MenuItem
} from '@mui/material';

import { getData, setData } from '../../../redux/ducks/riot';
import MatchHistoryCard from './MatchHistoryCard';
import { valRegionList } from '../../../constants/constants';

const styles = () => ({
  searchBar: {
    marginTop: '10px',
    width: '600px',
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    width: '100px',
    marginLeft: '10px'
  }
});

const MatchHistory = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { matchHistoryResults, gettingData } = useSelector(state => state.riot);
  const { history } = matchHistoryResults || {};
  // const [puuid, setPuuid] = useState(
  //   '88BVoSc_LO1o8rtnHrRuYnp3bhUfYTd1sbUPJbuNeBt93TrgojiVi8Q1fNGw3MjlZhDwU_-DCH4SHA'
  // );
  const [puuid, setPuuid] = useState('');
  const [realm, setRealm] = useState(valRegionList[3]);

  return (
    <Box>
      <Typography variant="h4"> Riot Match History Search</Typography>
      <Box sx={classes.searchBar}>
        <TextField
          inputProps={{
            onChange: event => setPuuid(event.target.value)
          }}
          label="PUUID"
          variant="outlined"
          fullWidth
          value={puuid}
        />
        <Select
          sx={classes.select}
          value={realm}
          onChange={event => setRealm(event.target.value)}
        >
          {valRegionList.map(region => (
            <MenuItem value={region} key={region}>
              {region}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Button
        onClick={() => {
          dispatch(getData('getMatchHistory', { puuid, realm }));
          dispatch(setData({ matchHistoryRegion: realm }));
        }}
        disabled={gettingData}
        sx={classes.button}
        variant="contained"
        color="primary"
      >
        Search
      </Button>
      {history && history.length > 0 && (
        <>
          <Typography variant="h4"> Results: </Typography>
          {history.map(match => (
            <MatchHistoryCard
              parentPuuid={puuid}
              key={match.matchId}
              {...match}
            />
          ))}
        </>
      )}
      {history && history.length === 0 && (
        <Typography variant="h4"> No Match Found </Typography>
      )}
    </Box>
  );
};

export default MatchHistory;
