import { createSlice } from '@reduxjs/toolkit';

const metaSlice = createSlice({
  name: 'meta',
  initialState: {},
  reducers: {
    postPmt() {},
    schedulePmt() {}
  }
});

export const { postPmt, schedulePmt } = metaSlice.actions;
export default metaSlice.reducer;
