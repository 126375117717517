import React, { useState } from 'react';
import RibBracket from '@runitback/bracket/RibBracket';
import { Box, createTheme } from '@mui/material';
import { baseTheme, darkPalette } from '../../themes/theme';
import BracketBuilder from './form/BracketBuilder';

const Bracket = () => {
  const [bracket, setBracket] = useState({
    type: 'single',
    winners: [
      {
        seeds: [
          {
            teams: [{}, {}]
          },
          {
            teams: [{}, {}]
          }
        ],
        title: 'Upper Round 1'
      }
    ],
    losers: [
      {
        seeds: [
          {
            teams: [{}, {}]
          },
          {
            teams: [{}, {}]
          }
        ],
        title: 'Lower Round 1'
      }
    ],
    groups: [{ seeds: [], teams: [], title: 'Group A' }],
    weekly: {
      standingOptions: {},
      weeks: [
        {
          id: 1,
          title: 'Week 1',
          series: []
        }
      ]
    }
  });
  const currentTheme = createTheme({ ...baseTheme, palette: darkPalette });

  return (
    <>
      <BracketBuilder bracket={bracket} setBracket={setBracket} />
      <h1> Bracket Preview</h1>
      <RibBracket
        bracket={bracket}
        theme={createTheme(currentTheme)}
        getSeriesPath={() => {}}
      />
      <p> bracket data</p>
      <Box sx={{ color: 'black', backgroundColor: '#ccc' }}>
        <pre>{JSON.stringify(bracket, null, 2)}</pre>
      </Box>
    </>
  );
};

export default Bracket;
