import { createSlice } from '@reduxjs/toolkit';

const rankingSlice = createSlice({
  name: 'ranking',
  initialState: {},
  reducers: {
    updateRankings() {}
  }
});

export const { updateRankings } = rankingSlice.actions;
export default rankingSlice.reducer;
