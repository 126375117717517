import React from 'react';
import { Dialog as MuiDialog, DialogTitle, DialogContent } from '@mui/material';

export default function Dialog(props) {
  const { open, handleClose, title, content } = props;

  return (
    <MuiDialog
      sx={{
        padding: '20px',
        minWidth: '400px',
        minHeight: '200px'
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
    </MuiDialog>
  );
}
