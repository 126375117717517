import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Divider,
  Typography
} from '@mui/material';
import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';
import TopPlayer from './TopPlayer';

export default function PlayerRanking() {
  const [matchId, setMatchId] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [override, setOverride] = useState(false);

  const getMatchUrl = () => `${backendBaseUrl}/cms/matches/rank/${matchId}`;
  const getEventUrl = () => `${backendBaseUrl}/cms/events/rank/${eventId}`;

  const postRankMatch = async () => {
    try {
      await axios.request({
        method: 'POST',
        url: getMatchUrl(),
        withCredentials: true
      });
    } catch (error) {
      console.error('Error fetching player data', error);
    }
  };

  const postRankEvent = async () => {
    try {
      await axios.request({
        method: 'POST',
        url: getEventUrl(),
        data: {
          override
        },
        withCredentials: true
      });
    } catch (error) {
      console.error('Error fetching event data', error);
    }
  };

  const postRankAllEvents = async () => {
    try {
      await axios.request({
        method: 'POST',
        url: `${backendBaseUrl}/cms/events/rankAll`,
        data: {
          override
        },
        withCredentials: true
      });
    } catch (error) {
      console.error('Error fetching event data', error);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 4 }}>
        <TextField
          label="Match id"
          onChange={(event) => setMatchId(event.target.value)}
        />
        <Button variant="outlined" onClick={() => postRankMatch()}>
          Rank match
        </Button>
      </Box>
      <Box sx={{ display: 'flex', mb: 4 }}>
        <TextField
          label="Event id"
          onChange={(event) => setEventId(event.target.value)}
        />
        <FormControlLabel
          control={
            <Switch
              checked={override}
              onChange={() => setOverride(!override)}
            />
          }
          label="Override existing match ratings"
        />
        <Button variant="outlined" onClick={() => postRankEvent()}>
          Rank event
        </Button>
      </Box>
      <Box sx={{ display: 'flex', mb: 4 }}>
        <Typography>Rank all events</Typography>
        <Button variant="outlined" disabled onClick={() => postRankAllEvents()}>
          Rank ALL events
        </Button>
      </Box>
      <Divider />
      <Box mt={4} mb={4}>
        <TopPlayer />
      </Box>
    </Box>
  );
}
