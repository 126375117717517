import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import NotFound from './NotFound';
import ListLayout from './ListLayout';
import DataLayout from './DataLayout';
import EditLayout from './EditLayout';
import RiotPlayerLookup from '../riot/riotPlayerLookup/RiotPlayerLookup';
import MatchHistory from '../riot/matchHistory/MatchHistory';
import AddPlayer from '../riot/addPlayer/AddPlayer';
import ConnectMatch from '../riot/connectMatch/ConnectMatch';
import Bracket from '../brackets/Bracket';
import Rankings from '../rankings/Rankings';
import AddPatch from '../patch/AddPatch';
import Trending from '../trending/Trending';
import Stripe from '../stripe/Stripe';
import Circuits from '../circuits/Circuits';
import MergePlayer from '../playerMerge/MergePlayer';
import PlayerRanking from '../playerRanking/PlayerRanking';
import Fantasy from '../fantasy/Fantasy';

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/events" replace />} />
        <Route path="/circuits" element={<Circuits />} />
        <Route path="/riot-player-lookup" element={<RiotPlayerLookup />} />
        <Route path="/riot-match-history-lookup" element={<MatchHistory />} />
        <Route path="/connect-match" element={<ConnectMatch />} />
        <Route path="/riot-add-player" element={<AddPlayer />} />
        <Route path="/brackets" element={<Bracket />} />
        <Route path="/trending" element={<Trending />} />
        <Route path="/stripe" element={<Stripe />} />
        <Route path="/patch" element={<AddPatch />} />
        <Route path="/rankings" element={<Rankings />} />
        <Route path="/fantasy" element={<Fantasy />} />
        <Route path="/player-merge" element={<MergePlayer />} />
        <Route path="/player-rankings" element={<PlayerRanking />} />
        <Route exact path="/:category/:id" element={<DataLayout />} />
        <Route path="/:category/:id/edit">
          <Route index element={<EditLayout />} />
          <Route path=":teamId" element={<EditLayout />} />
        </Route>
        <Route exact path="/:category" element={<ListLayout />} />
        <Route element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
