import React from 'react';
import { Typography, Box } from '@mui/material';

import moment from 'moment';

const styles = () => ({
  container: {
    border: `1px solid #405162`,
    backgroundColor: '#2d3844',
    width: '255px',
    height: '150px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#3d4854'
    },
    padding: '4px'
  },
  link: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    textDecoration: 'none'
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '12px'
  },
  region: {
    marginLeft: 'auto',
    textTransform: 'uppercase'
  },
  topText: {
    color: 'white',
    fontSize: 14,
    textDecoration: 'none'
  },
  bottomContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%'
  },
  bottomText: {
    marginTop: '4px',
    color: '#8FA3B0',
    fontSize: 13,
    textDecoration: 'none'
  },
  seriesCount: {
    color: 'orange',
    fontSize: 13
  }
});

const EventCard = props => {
  const classes = styles();
  const { data: event } = props;

  const {
    name,
    imageUrl,
    startDate,
    endDate,
    type,
    seriesCount,
    parent
  } = event;

  // const { name, startDate, endDate, parent, seriesCount } = data;

  const formattedStartDate = moment(startDate).format('MMM Do');
  const formattedEndDate = moment(endDate).format('MMM Do');

  return (
    <Box sx={classes.container}>
      <img
        src={imageUrl || 'https://i.imgur.com/RGUkZmy.webp'}
        style={{ width: 28, height: 28, marginRight: '4px' }}
        alt={name}
      />
      <Box sx={classes.content}>
        <Typography sx={classes.topText}>{name}</Typography>
        {parent && (
          <Typography sx={classes.seriesCount}>
            Parent event - no matches here.
          </Typography>
        )}
        {!parent && (
          <Typography sx={classes.seriesCount}>
            {`${seriesCount} series`}
          </Typography>
        )}
        <Box>
          <Typography sx={classes.bottomText}>
            {`${formattedStartDate} - ${formattedEndDate}`}
          </Typography>
          <Typography sx={classes.bottomText}>{type}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EventCard;
