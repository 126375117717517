import {
  Box,
  Button,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Typography
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { DatePicker } from 'formik-mui-x-date-pickers';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchWinConditions } from '../../constants/constants';
import { edit } from '../../redux/ducks/data';

const MatchForm = (props) => {
  const { data } = props;
  const { team1Name, team2Name } = data;
  const dispatch = useDispatch();
  const { mapList } = useSelector((state) => state.data);

  return (
    <Formik
      initialValues={{
        noRiotData: false,
        ...data
      }}
      validate={(values) => {
        const errors = {};
        if (values.winCondition === 'automatic' && !values.winningTeamNumber) {
          errors.winningTeamNumber =
            'Need a winning team number for automatic wins';
        }
        if (values.vodUrl && !values.vodUrl.startsWith('https://')) {
          errors.vodUrl = 'Invalid url. Must start with https://';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (
          values.winCondition === 'forfeit' ||
          values.winCondition === 'automatic'
        ) {
          values.completed = true;
        }
        setTimeout(() => {
          setSubmitting(false);
          dispatch(edit('matches', data.id, values));
        }, 500);
      }}
    >
      {({ submitForm, isSubmitting, values }) => (
        <Form>
          <Typography>{`Team 1: ${team1Name}`}</Typography>
          <Typography>{`Team 2: ${team2Name}`}</Typography>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="team1Score"
              type="number"
              label="Team 1 score"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="team2Score"
              type="number"
              label="Team 2 score"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              type="text"
              name="attackingFirstTeamNumber"
              label="Attacking First Team #"
              select
              variant="standard"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            >
              {[undefined, 1, 2].map((position) => (
                <MenuItem key={position} value={position}>
                  {position}
                </MenuItem>
              ))}
            </Field>
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              type="text"
              name="winningTeamNumber"
              label="Winning Team #"
              select
              variant="standard"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            >
              {[null, 1, 2].map((position) => (
                <MenuItem key={position} value={position}>
                  {position}
                </MenuItem>
              ))}
            </Field>
          </Box>
          <Box margin={1}>
            <Box sx={{ display: 'grid' }}>
              <Field
                sx={{ width: '400px' }}
                component={TextField}
                type="text"
                name="winCondition"
                label="Win Condition"
                select
                variant="standard"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              >
                {matchWinConditions.map((position) => (
                  <MenuItem key={position} value={position}>
                    {position}
                  </MenuItem>
                ))}
              </Field>
              {values.winCondition === 'automatic' &&
                !values.winningTeamNumber && (
                  <Typography variant="caption" color="error">
                    If win condition is automatic, add a winning team Number
                  </Typography>
                )}
            </Box>
          </Box>
          <Box margin={1}>
            <Field
              component={DatePicker}
              name="startDate"
              label="Match Start Date *"
            />
          </Box>
          {mapList && (
            <Box margin={1}>
              <Field
                sx={{ width: '400px' }}
                component={TextField}
                type="text"
                name="mapId"
                label="Map"
                select
                variant="standard"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              >
                {mapList.map((map) => (
                  <MenuItem key={map.id} value={map.id}>
                    {map.name}
                  </MenuItem>
                ))}
              </Field>
            </Box>
          )}
          <Box margin={1}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Field component={Switch} type="checkbox" name="live" />
              }
              label="Live"
            />
            <Typography variant="caption">
              When this is on, the game will be displayed as Live on the site.
              As soon as a game starts, turn this on. When the game ends, turn
              this off.
            </Typography>
          </Box>
          <Box margin={1}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Field component={Switch} type="checkbox" name="completed" />
              }
              label="Completed"
            />
          </Box>
          <Box margin={1}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Field type="text" name="vlrId" />
              }
              label="VLR Match ID"
            />
            <Typography variant="caption">
              The VLR Match ID - it will be the ?game= part of the url, i.e
              ?game=65435 has a VLR Match ID of 65435
            </Typography>
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="vodUrl"
              type="text"
              label="VOD Url"
            />
          </Box>
          <Box margin={1}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Field
                  component={Switch}
                  type="checkbox"
                  name="readyToDisplay"
                  // disabled={!riotId}
                />
              }
              // label="Ready To Display - requires riot id"
              label="Ready To Display"
            />
          </Box>
          <Box margin={1}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Field
                  component={Switch}
                  type="checkbox"
                  name="noRiotData"
                  // disabled={!riotId}
                />
              }
              // label="Ready To Display - requires riot id"
              label="No Riot Data - turn this on to enable manual data entry of the match"
            />
          </Box>
          {/* <Box margin={1}>
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Field
                  component={Switch}
                  type="checkbox"
                  name="teamsInvertedInStream"
                />
              }
              label="Swaped Teams (for stillman)"
            />
          </Box> */}
          {isSubmitting && <LinearProgress />}
          <Box margin={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default MatchForm;
