import React, { useState } from 'react';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import PlayerCard from './PlayerCard';
import { setSnackbar } from '../../../redux/ducks/settings';
import MatchDialog from './MatchDialog';

const styles = () => ({
  paperContainer: {
    display: 'flex'
  },
  originalPuuid: {
    color: '#ffac33'
  },
  teamDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  redTeamId: {
    color: '#ff4569'
  },
  blueTeamId: {
    color: '#35baf6'
  }
});

const MatchDetails = props => {
  const classes = styles();
  const [connecting, setConnecting] = useState(false);
  const { mapList } = useSelector(state => state.data);
  const { details, parentPuuid, realm } = props;
  const { matchInfo, players, teams } = details || {};
  const { provisioningFlowId, gameLengthMillis, mapId } = matchInfo;
  const { roundsWon: team1Score, teamId: team1Color } = teams[0] || {};
  const { roundsWon: team2Score, teamId: team2Color } = teams[1] || {};
  const duration = moment.duration(gameLengthMillis).asMinutes();
  const dispatch = useDispatch();

  const clickConnecting = () => {
    if (provisioningFlowId === 'CustomGame') {
      setConnecting(true);
    } else {
      dispatch(setSnackbar(true, 'error', 'You can only connect custom games'));
    }
  };

  const theMap = mapList.filter(listMap => listMap.riotId === mapId)[0];
  console.log(theMap);

  return (
    <Paper variant="outlined">
      <MatchDialog
        {...{
          connecting,
          setConnecting,
          details,
          realm,
          map: theMap
        }}
      />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="secondary">Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ width: '100%' }}>
            <Button variant="outlined" onClick={() => clickConnecting()}>
              Connect match to CMS match
            </Button>
            <Typography>{`Map: ${theMap.name}`}</Typography>
            <Typography>{`Duration: ${duration} minutes`}</Typography>
            <Typography>{`Queue: ${provisioningFlowId}`}</Typography>
            <Typography
              className={
                team1Color === 'Blue' ? classes.blueTeamId : classes.redTeamId
              }
            >
              {`${team1Color} Team score: ${team1Score}`}
            </Typography>
            <Typography
              className={
                team2Color === 'Blue' ? classes.blueTeamId : classes.redTeamId
              }
            >
              {`${team2Color} Team score: ${team2Score}`}
            </Typography>
            <Box sx={classes.paperContainer}>
              <Box sx={classes.teamDiv}>
                {players
                  .filter(player => player.teamId === 'Blue')
                  .map(player => {
                    const {
                      teamId,
                      puuid,
                      characterId,
                      stats,
                      gameName,
                      tagLine
                    } = player || {};
                    const { kills, deaths, assists } = stats || {};
                    return (
                      <PlayerCard
                        key={puuid}
                        {...{
                          teamId,
                          puuid,
                          characterId,
                          kills,
                          deaths,
                          assists,
                          parentPuuid,
                          gameName,
                          tagLine
                        }}
                      />
                    );
                  })}
              </Box>
              <Box sx={classes.teamDiv}>
                {players
                  .filter(player => player.teamId === 'Red')
                  .map(player => {
                    const {
                      teamId,
                      puuid,
                      characterId,
                      stats,
                      gameName,
                      tagLine
                    } = player || {};
                    const { kills, deaths, assists } = stats || {};
                    return (
                      <PlayerCard
                        key={puuid}
                        {...{
                          teamId,
                          puuid,
                          characterId,
                          kills,
                          deaths,
                          assists,
                          parentPuuid,
                          gameName,
                          tagLine
                        }}
                      />
                    );
                  })}
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default MatchDetails;
