import { Button, Typography, Box, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { addPlayersToMatch } from '../../redux/ducks/data';
import { get, set } from '../../redux/ducks/currentDataSlice';
import SearchField from '../layout/SearchField';

const AddPlayerDialog = props => {
  const dispatch = useDispatch();
  const [selectedPlayers, setSelectedPlayers] = React.useState({});
  const [called, setCalled] = React.useState(false);
  const { data, dialogOpen } = props;
  const { id, teamNumber, players, team1Data, team2Data } = data;
  const { currentTeamData } = useSelector(state => state.currentData);
  const noPlayersAdded =
    players.filter(player => player.teamNumber === teamNumber).length === 0;
  if (noPlayersAdded && !currentTeamData && !called) {
    const dataId = teamNumber === 1 ? team1Data.id : team2Data.id;
    dispatch(get({ category: 'teams', dataId, key: 'currentTeamData' }));
    setCalled(true);
  }

  useEffect(() => {
    if (dialogOpen) {
      if (currentTeamData && currentTeamData.players && noPlayersAdded) {
        const { players } = currentTeamData;
        players.forEach(player =>
          setSelectedPlayers(selectedPlayers => ({
            ...selectedPlayers,
            [player.id]: { ...player, teamNumber }
          }))
        );
      }
    } else {
      setSelectedPlayers({});
      if (currentTeamData) {
        dispatch(set({ category: 'currentTeamData', data: undefined }));
      }
    }
  }, [dialogOpen, currentTeamData]);

  const handleOnClick = listItem => {
    setSelectedPlayers(selectedPlayers => ({
      ...selectedPlayers,
      [listItem.id]: { ...listItem, teamNumber }
    }));
  };

  const handleRemovePlayer = player => {
    setSelectedPlayers(selectedPlayers => {
      delete selectedPlayers[player];
      setSelectedPlayers({ ...selectedPlayers });
    });
  };

  return (
    <Box sx={{ width: '600px', height: '400px' }}>
      {Object.keys(selectedPlayers).map(player => {
        const { ign } = selectedPlayers[player];
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography color="primary"> {ign} </Typography>
            <IconButton>
              <CloseIcon
                fontSize="small"
                onClick={() => handleRemovePlayer(player)}
              />
            </IconButton>
          </Box>
        );
      })}

      <SearchField category="players" handleOnClick={handleOnClick} />
      <Button
        variant="outlined"
        disabled={Object.keys(selectedPlayers).length < 1}
        onClick={() => dispatch(addPlayersToMatch(id, selectedPlayers))}
      >
        Add selected players
      </Button>
    </Box>
  );
};

export default AddPlayerDialog;
