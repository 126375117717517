import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';

export default function MergePlayer() {
  const [sourcePlayerId, setSourcePlayerId] = useState(null);
  const [destinationPlayerId, setDestinationPlayerId] = useState(null);
  const [sourcePlayerInfo, setSourcePlayerInfo] = useState(null);
  const [destinationPlayerInfo, setDestinationPlayerInfo] = useState(null);
  const [matches, setMatches] = useState(null);

  const mergePreviewUrl = `${backendBaseUrl}/cms/players/merge/preview`;
  const mergeUrl = `${backendBaseUrl}/cms/players/merge`;
  const getPlayerInfoByIdUrl = (playerId) =>
    `${backendBaseUrl}/cms/players/get/${playerId}`;

  const fetchPlayerInfo = async (playerId, setter) => {
    try {
      const response = await axios.request({
        method: 'GET',
        url: getPlayerInfoByIdUrl(playerId),
        withCredentials: true
      });
      setter(response?.data?.payload);
    } catch (error) {
      console.error('Error fetching player data', error);
    }
  };

  const previewMerge = async () => {
    try {
      const response = await axios.request({
        method: 'POST',
        url: mergePreviewUrl,
        data: { sourcePlayerId, destinationPlayerId },
        withCredentials: true
      });
      setMatches(response?.data?.payload?.matchCount);
    } catch (error) {
      console.error('Error fetching merge preview', error);
    }
  };

  const mergePlayers = async () => {
    try {
      await axios.request({
        method: 'POST',
        url: mergeUrl,
        data: { sourcePlayerId, destinationPlayerId },
        withCredentials: true
      });
      // eslint-disable-next-line no-alert
      alert('Players merged successfully');
    } catch (error) {
      console.error('Error merging players', error);
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Merge Players
      </Typography>
      <Typography color="primary" sx={{ mb: 2 }}>
        Source player id = player to be deleted and merged
      </Typography>
      <Typography color="secondary" sx={{ mb: 2 }}>
        Destination player id = new profile that will contain all the matches
      </Typography>
      <Typography sx={{ mb: 4 }}>
        Note: if more than 30 matches will be merged, you need to ask Celtic or
        Jus1 to do it
      </Typography>

      <Box sx={{ display: 'flex' }}>
        <TextField
          label="Source (delete) Player id"
          onChange={(event) => setSourcePlayerId(event.target.value)}
        />
        <Button
          variant="outlined"
          onClick={() => fetchPlayerInfo(sourcePlayerId, setSourcePlayerInfo)}
        >
          Fetch Source Player Info
        </Button>
        {sourcePlayerInfo && (
          <Typography>{`${sourcePlayerInfo.ign} (${sourcePlayerInfo.firstName} ${sourcePlayerInfo.lastName})`}</Typography>
        )}
      </Box>

      <Box sx={{ display: 'flex' }}>
        <TextField
          label="Destination Player ID"
          onChange={(event) => setDestinationPlayerId(event.target.value)}
        />
        <Button
          variant="outlined"
          onClick={() =>
            fetchPlayerInfo(destinationPlayerId, setDestinationPlayerInfo)
          }
        >
          Fetch Destination Player Info
        </Button>
        {destinationPlayerInfo && (
          <Typography>{`${destinationPlayerInfo.ign} (firstname: ${destinationPlayerInfo.firstName}, lastname: ${destinationPlayerInfo.lastName})`}</Typography>
        )}
      </Box>

      <Button
        variant="outlined"
        onClick={previewMerge}
        disabled={!sourcePlayerInfo || !destinationPlayerInfo}
      >
        Preview Merge
      </Button>
      {matches && (
        <Typography>{`${matches} matches will be merged. Make sure everything is correct since we can't undo this. ${sourcePlayerInfo.ign} - ${sourcePlayerInfo.id} WILL BE DELETED and their matches will be moved to ${destinationPlayerInfo.ign} - ${destinationPlayerInfo.id}`}</Typography>
      )}

      <Button variant="outlined" onClick={mergePlayers} disabled={!matches}>
        Confirm Merge
      </Button>
    </Box>
  );
}
