import React from 'react';
import { Button, LinearProgress, Box, MenuItem } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { DatePicker } from 'formik-mui-x-date-pickers';
import { TextField } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { create, edit } from '../../redux/ducks/data';
import {
  divisionList,
  regionShortNameMap,
  vctRegionList
} from '../../constants/constants';

const TeamForm = (props) => {
  const { id, data, isNew } = props;
  const dispatch = useDispatch();
  const { countryList } = useSelector((state) => state.data);

  return (
    <Formik
      initialValues={
        data && !isNew
          ? data
          : {
              name: '',
              aliases: [],
              description: '',
              logoUrl: 'https://i.imgur.com/BIC4pnO.webp',
              twitterUrl: '',
              liquipediaUrl: '',
              websiteUrl: '',
              vlrUrl: '',
              twitchUrl: '',
              youtubeUrl: '',
              division: 'T3',
              vctRegion: 'EMEA',
              foundedDate: moment().format('YYYY-MM-DD')
            }
      }
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        if (!values.countryId) {
          errors.countryId = 'Required';
        }
        if (!values.regionId) {
          errors.regionId = 'Required';
        }
        if (!values.logoUrl) {
          errors.logoUrl = 'Required';
        } else if (
          values.logoUrl &&
          (!values.logoUrl.match('.*\\.(jpg|png|jpeg|webp)$') ||
            !values.logoUrl.startsWith('https://'))
        ) {
          errors.logoUrl =
            'Invalid url. Must start with https:// and end with .webp .jpg .png or .jpeg';
        }
        if (values.twitterUrl && !values.twitterUrl.startsWith('https://')) {
          errors.twitterUrl = 'Invalid url. Must start with https://';
        }
        if (
          values.liquipediaUrl &&
          !values.liquipediaUrl.startsWith('https://')
        ) {
          errors.liquipediaUrl = 'Invalid url. Must start with https://';
        }
        if (values.websiteUrl && !values.websiteUrl.startsWith('https://')) {
          errors.websiteUrl = 'Invalid url. Must start with https://';
        }
        if (!values.shortName) {
          errors.shortName = 'Required';
        }
        if (
          values.shortName &&
          (values.shortName.length > 4 || values.shortName.length < 2)
        ) {
          errors.shortName = 'Must be between 2-4 characters';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        values.shortName = values.shortName.toUpperCase();
        setTimeout(() => {
          setSubmitting(false);
          isNew
            ? dispatch(create('teams', values))
            : dispatch(edit('teams', id, values));
        }, 500);
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Box margin={1}>
            <Field
              component={TextField}
              name="name"
              type="text"
              label="Team Name"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="shortName"
              type="text"
              label="Short Name"
            />
          </Box>
          {countryList && (
            <Box margin={1}>
              <Field
                sx={{ width: '400px' }}
                component={TextField}
                type="text"
                name="countryId"
                label="Country"
                select
                variant="standard"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              >
                {countryList.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Field>
            </Box>
          )}
          {regionShortNameMap && (
            <Box margin={1}>
              <Field
                sx={{ width: '400px' }}
                component={TextField}
                type="text"
                name="regionId"
                label="Region"
                select
                variant="standard"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              >
                {Object.keys(regionShortNameMap).map((region) => (
                  <MenuItem key={region} value={region}>
                    {regionShortNameMap[region]}
                  </MenuItem>
                ))}
              </Field>
            </Box>
          )}
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              type="text"
              name="division"
              label="Division"
              select
              variant="standard"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            >
              {divisionList.map((division) => (
                <MenuItem key={division} value={division}>
                  {division}
                </MenuItem>
              ))}
            </Field>
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              type="text"
              name="vctRegion"
              label="VCT Region"
              select
              variant="standard"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            >
              {vctRegionList.map((region) => (
                <MenuItem key={region} value={region}>
                  {region}
                </MenuItem>
              ))}
            </Field>
          </Box>
          <Box margin={1}>
            <Field
              component="textarea"
              placeholder="Team Description"
              rows="5"
              name="description"
              type="text"
              label="Description"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={DatePicker}
              name="foundedDate"
              label="Team Founded Date"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="logoUrl"
              type="text"
              label="Team Logo URL (square & transparent)"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="liquipediaUrl"
              type="text"
              label="Liquipedia Url"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="twitterUrl"
              type="text"
              label="Twitter Url"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="websiteUrl"
              type="text"
              label="Team Website Url"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="twitchUrl"
              type="text"
              label="Twitch Url"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="vlrUrl"
              type="text"
              label="Vlr Url"
            />
          </Box>
          <Box margin={1}>
            <Field
              sx={{ width: '400px' }}
              component={TextField}
              name="youtubeUrl"
              type="text"
              label="Youtube Url"
            />
          </Box>
          {isSubmitting && <LinearProgress />}
          <Box margin={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default TeamForm;
