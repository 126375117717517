import React from 'react';
import { Typography, Paper, Box } from '@mui/material';

import { useSelector } from 'react-redux';

const styles = () => ({
  root: {
    padding: '15px',
    textAlign: 'center',
    width: '225px'
  }
});

const PlayerCard = (props) => {
  const classes = styles(props);
  const { data } = props;
  const { countryList } = useSelector((state) => state.data);
  const { id, ign, countryId, team, firstName, lastName, role, igl } = data;
  const { name: teamName } = team || {};
  const country =
    countryList && countryList.filter((country) => country.id === countryId)[0];
  return (
    <Paper sx={classes.root}>
      <Typography variant="h6" color="secondary">
        {ign}
      </Typography>
      <Typography variant="caption" color="secondary">
        id: {id}
      </Typography>
      <Typography> {teamName && `${teamName}`} </Typography>
      <Box>{role && <Typography color="primary">{role}</Typography>}</Box>
      <Box>
        {igl && (
          <Typography variant="body2" color="error">
            IGL
          </Typography>
        )}
      </Box>
      {firstName ||
        (lastName && (
          <Box>
            <Typography variant="caption">
              {`${firstName}, ${lastName}`}{' '}
            </Typography>
          </Box>
        ))}
      <Box>
        {country && (
          <Typography variant="caption"> {country.niceName} </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default PlayerCard;
