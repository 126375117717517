import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox
} from '@mui/material';

function sanatizeTeamData(data) {
  const sanitizedData = {};

  if (data?.id !== undefined) sanitizedData.id = data?.id;
  if (data?.name !== undefined) sanitizedData.name = data?.name;
  if (data?.shortName !== undefined) sanitizedData.shortName = data?.shortName;
  if (data?.logoUrl !== undefined) sanitizedData.logoUrl = data?.logoUrl;

  return sanitizedData;
}

function sanitizeData(data) {
  const sanitizedData = {};

  if (data.id !== undefined) sanitizedData.id = data.id;
  if (data.seriesId !== undefined) sanitizedData.seriesId = data.seriesId;
  if (data.team1Score !== undefined) sanitizedData.team1Score = data.team1Score;
  if (data.team2Score !== undefined) sanitizedData.team2Score = data.team2Score;
  if (data.team1Id !== undefined) sanitizedData.team1Id = data.team1Id;
  if (data.team2Id !== undefined) sanitizedData.team2Id = data.team2Id;
  if (data.completed !== undefined) sanitizedData.completed = data.completed;
  if (data.startDate !== undefined) sanitizedData.startDate = data.startDate;
  if (data.bestOf !== undefined) sanitizedData.bestOf = data.bestOf;

  if (data.team1 !== undefined)
    sanitizedData.team1 = sanatizeTeamData(data.team1);
  if (data.team2 !== undefined)
    sanitizedData.team2 = sanatizeTeamData(data.team2);

  return sanitizedData;
}

const AddMatch = (props) => {
  const { setContentDialogValues, eventData, bracket } = props;
  const { series } = eventData;

  console.log({ bracket });

  const isSeriesInBracket = (seriesId) => {
    let isPresent = false;

    ['groups', 'losers', 'winners'].forEach((group) => {
      bracket[group].forEach((item) => {
        item.seeds.forEach((seed) => {
          if (seed.id === seriesId) isPresent = true;
        });
      });
    });

    return isPresent;
  };

  const [selectedSeries, setSelectedSeries] = useState([]);

  // Function to handle checkbox select/deselect
  const handleCheckboxChange = (event, match) => {
    if (event.target.checked) {
      setSelectedSeries((prevMatches) => [...prevMatches, match]);
    } else {
      setSelectedSeries((prevMatches) =>
        prevMatches.filter((m) => m.id !== match.id)
      );
    }
  };

  // Function to select all
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedSeries(series);
    } else {
      setSelectedSeries([]);
    }
  };

  const calculateSeriesData = (seriesData) => {
    // any processing that needs to be done for the data that is stored happens here
    // going to capture: wins, losses, roundwins, roundlosses,
    const { matches } = seriesData;
    let team1Score = 0;
    let team2Score = 0;
    let team1RoundWins = 0;
    let team1RoundLosses = 0;
    let team2RoundWins = 0;
    let team2RoundLosses = 0;
    let team1MatchScore = 0;
    let team2MatchScore = 0;
    matches.forEach((match) => {
      const { winningTeamNumber, team1Score: t1s, team2Score: t2s } = match;
      if (!winningTeamNumber || winningTeamNumber === null) {
        return;
      }
      team1RoundWins += t1s;
      team2RoundWins += t2s;
      team1RoundLosses += t2s;
      team2RoundLosses += t1s;
      if (winningTeamNumber === 1) {
        team1Score += 1;
      }
      if (winningTeamNumber === 2) {
        team2Score += 1;
      }
      if (winningTeamNumber) {
        team1MatchScore = t1s;
        team2MatchScore = t2s;
      }
    });
    if (
      (team1Score === 0 || team2Score === 0) &&
      team1Score + team2Score === 1
    ) {
      team1Score = team1MatchScore;
      team2Score = team2MatchScore;
    }

    const sanitizedSeriesData = sanitizeData(seriesData);
    const finalData = {
      ...sanitizedSeriesData,
      team1RoundWins,
      team1RoundLosses,
      team2RoundWins,
      team2RoundLosses,
      teams: [sanitizedSeriesData.team1, sanitizedSeriesData.team2],
      matches: matches.map((match) => sanitizeData(match))
    };

    return finalData;
  };

  // then in your useEffect
  useEffect(() => {
    const selectedSeriesData = selectedSeries.map(calculateSeriesData);
    setContentDialogValues({
      series: selectedSeriesData
    });
  }, [selectedSeries]);

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedSeries.length > 0 &&
                    selectedSeries.length < series.length
                  }
                  checked={
                    series.length > 0 && selectedSeries.length === series.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Match ID</TableCell>
              <TableCell>Team 1 Name</TableCell>
              <TableCell>Team 2 Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {series.map((match) => {
              if (!isSeriesInBracket(match.id)) {
                return (
                  <TableRow key={match.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedSeries.includes(match)}
                        onChange={(e) => handleCheckboxChange(e, match)}
                      />
                    </TableCell>
                    <TableCell>{match.id}</TableCell>
                    <TableCell>{match?.team1?.name}</TableCell>
                    <TableCell>{match?.team2?.name}</TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AddMatch;
