import axios from 'axios';
import { backendBaseUrl } from '../../constants/constants';

export function doAddPlayerToMatch(matchId, playerObject) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/matches/addPlayers`,
    data: {
      matchId,
      playerObject
    },
    withCredentials: true
  });
}

export function doSetPlayerStats(matchId, playerObject) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/matches/setPlayerStats`,
    data: {
      matchId,
      playerObject
    },
    withCredentials: true
  });
}

export function doUpdatePlayer(matchId, playerObject) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/matches/updatePlayer`,
    data: {
      matchId,
      playerObject
    },
    withCredentials: true
  });
}

export function doRemovePlayerFromMatch(matchId, playerId) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/matches/removePlayer`,
    data: {
      matchId,
      playerId
    },
    withCredentials: true
  });
}

export function doSwapTeams(matchId) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/matches/swapTeams`,
    data: {
      matchId
    },
    withCredentials: true
  });
}

export function doGetMatchDetails(matchId) {
  return axios.request({
    method: 'get',
    url: `${backendBaseUrl}/cms/matches/get/${matchId}`,
    withCredentials: true
  });
}

export function doToggleSeriesLive(seriesId, data) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/riot/toggle-series-live/${seriesId}`,
    data,
    withCredentials: true
  });
}

export function doDeleteMatch(matchId) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/matches/delete/${matchId}`,
    withCredentials: true
  });
}

export function doDeleteSeries(seriesId) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/series/delete/${seriesId}`,
    withCredentials: true
  });
}

export function doAddPatch({ number, releaseDate }) {
  return axios.request({
    method: 'post',
    url: `${backendBaseUrl}/cms/patch/add`,
    data: {
      number,
      releaseDate
    },
    withCredentials: true
  });
}
