/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { alpha } from '@mui/material/styles';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Button, IconButton, Typography, Box } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" sx={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" sx={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      sx={{ width: 20, height: 20 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3
    }
  },
  group: {
    marginLeft: '7px',
    paddingLeft: '18px',
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  }
}));

const styles = () => ({
  root: {
    flexGrow: 1
  },
  treeRoot: {
    display: 'flex',
    alignItems: 'center',
    height: '40px'
  },
  treeInnerItem: {
    marginRight: '15px'
  }
});

export default function CustomizedTreeView({ circuit }) {
  const classes = styles();

  let nodeIncrement = 0;

  const typeToChildMap = {
    circuit: 'stage',
    stage: 'event',
    event: 'bracket'
  };

  console.log("here's the circuit", circuit);

  const renderTree = (currentCircuit) => {
    const { children, id, name, shortName, type } = currentCircuit;
    const nextType = typeToChildMap[type];
    const isParent = type !== 'event';
    nodeIncrement += 1;

    const colorMap = {
      circuit: 'error',
      stage: 'secondary',
      event: 'primary',
      bracket: 'default'
    };

    return (
      <StyledTreeItem
        nodeId={nodeIncrement}
        label={
          <Box sx={classes.treeRoot}>
            <Typography sx={classes.treeInnerItem} color={colorMap[type]}>
              {`${shortName || name}`}
            </Typography>
            <Link
              to={`/events/${id}`}
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <IconButton>
                <ExitToAppIcon />
              </IconButton>
            </Link>

            {type !== 'bracket' && (
              <Link
                to={`/events/new/edit?pId=${id}&newType=${nextType}&isParent=${isParent}`}
                target="_blank"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Button size="small" variant="outlined" color="primary">
                  Create {nextType}
                </Button>
              </Link>
            )}
          </Box>
        }
        {...currentCircuit}
      >
        {children && children.map((child) => renderTree(child))}
      </StyledTreeItem>
    );
  };

  return (
    <TreeView
      sx={classes.root}
      defaultExpanded={['1']}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
    >
      {renderTree(circuit)}
    </TreeView>
  );
}
