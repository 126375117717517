/* eslint-disable no-alert */
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  matchUpdatePlayer,
  removePlayerFromMatch,
  setPlayerStats
} from '../../redux/ducks/data';

const styles = () => ({
  table: {
    minWidth: 650
  },
  tableContainer: {
    maxWidth: '1000px'
  }
});

const getPlayerObject = (players, stats) => {
  const playerObject = {};
  if (!players || !players.length > 0 || !stats || !stats.length > 0) {
    return {};
  }
  players.forEach((playerObj) => {
    const { player, agentId, playerId, teamNumber } = playerObj;
    const { ign } = player;
    playerObject[playerId] = {
      agentId,
      teamNumber,
      playerId,
      ign
    };
  });
  stats.forEach((statObj) => {
    const { kills, deaths, assists, score, playerId } = statObj;
    playerObject[playerId] = {
      ...playerObject[playerId],
      playerId,
      kills,
      deaths,
      assists,
      score
    };
  });

  return playerObject;
};

const MatchTable = (props) => {
  const classes = styles();
  const { data, openDialog } = props;
  const {
    team1Name,
    team2Name,
    id,
    riotId,
    stats,
    players,
    team1Data,
    team2Data
  } = data;
  const playerObject = getPlayerObject(players, stats);
  const { agentList } = useSelector((state) => state.data);
  const [changedPlayerObject, setChangedPlayerObject] = useState({});
  const dispatch = useDispatch();

  const playerListItem = (ign, playerId) => (
    <Box key={ign} sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton onClick={() => dispatch(removePlayerFromMatch(id, playerId))}>
        <CloseIcon color="error" />
      </IconButton>
      <Typography> {ign} </Typography>
    </Box>
  );

  const handleChangePlayer = (playerId, attribute, value) => {
    setChangedPlayerObject((changedPlayerObject) => ({
      ...changedPlayerObject,
      [playerId]: {
        ...changedPlayerObject[playerId],
        [attribute]: value,
        playerId
      }
    }));
  };

  const handleUpdate = () => {
    const playerArr = [];
    Object.keys(changedPlayerObject).forEach((changedPlayer) =>
      playerArr.push({ ...changedPlayerObject[changedPlayer] })
    );
    dispatch(matchUpdatePlayer(id, playerArr));
    dispatch(setPlayerStats(id, playerArr));
  };

  return (
    <Box sx={{ justifyContent: 'center', width: '100%' }}>
      {riotId && (
        <>
          <Typography>
            {`This match is connected to a Riot match with the id: ${riotId}`}
          </Typography>
        </>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Box>
          <Button
            variant="outlined"
            onClick={() =>
              openDialog('addPlayersToMatch', {
                ...data,
                teamNumber: 1,
                team1Data,
                team2Data
              })
            }
          >
            Add Players
          </Button>
          <Typography color="primary" variant="h6">
            {` Team 1 : ${team1Name}`}
          </Typography>
          {players.map((playerObj) => {
            const { player, teamNumber, playerId } = playerObj;
            const { ign } = player;
            return (
              <Box key={playerId}>
                {teamNumber === 1 && playerListItem(ign, playerId)}
              </Box>
            );
          })}
        </Box>
        <Box>
          <Button
            onClick={() =>
              openDialog('addPlayersToMatch', { ...data, teamNumber: 2 })
            }
            variant="outlined"
          >
            Add Players
          </Button>
          <Typography color="primary" variant="h6">
            {` Team 2 : ${team2Name}`}
          </Typography>
          {players.map((playerObj) => {
            const { player, teamNumber, playerId } = playerObj;
            const { ign } = player;
            return (
              <Box key={playerId}>
                {teamNumber === 2 && playerListItem(ign, playerId)}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box margin={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleUpdate()}
        >
          UPDATE MATCH
        </Button>
      </Box>
      <TableContainer component={Paper} sx={classes.tableContainer}>
        <Table sx={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Team Number</TableCell>
              <TableCell align="center">Player</TableCell>
              <TableCell align="center">Agent</TableCell>
              <TableCell align="center">Kills</TableCell>
              <TableCell align="center">Deaths</TableCell>
              <TableCell align="center">Assists</TableCell>
              <TableCell align="center">ACS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(playerObject)
              .sort((a, b) => {
                return playerObject[a].teamNumber - playerObject[b].teamNumber;
              })
              .map((player) => {
                const {
                  ign,
                  playerId,
                  kills,
                  deaths,
                  assists,
                  score,
                  agentId,
                  teamNumber
                } = playerObject[player];
                return (
                  <TableRow key={playerId}>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{ padding: 0 }}
                    >
                      {teamNumber}
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{ padding: 0 }}
                    >
                      <Typography color="primary">{ign}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ padding: 0 }}>
                      <Box margin={1}>
                        <TextField
                          sx={{ width: '200px' }}
                          id="standard-select-currency"
                          select
                          label="Agent"
                          defaultValue={agentId === null ? '' : agentId}
                          onChange={(e) =>
                            handleChangePlayer(
                              playerId,
                              'agentId',
                              e.target.value
                            )
                          }
                        >
                          {agentList.map((agent) => (
                            <MenuItem key={agent.id} value={agent.id}>
                              {agent.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ padding: 0 }} align="center">
                      <Box margin={1}>
                        <TextField
                          sx={{ width: '100px' }}
                          label="Kills"
                          type="number"
                          defaultValue={kills === null ? '' : kills}
                          onChange={(e) =>
                            handleChangePlayer(
                              playerId,
                              'kills',
                              e.target.value
                            )
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell sx={{ padding: 0 }} align="center">
                      <Box margin={1}>
                        <TextField
                          sx={{ width: '100px' }}
                          label="Deaths"
                          type="number"
                          defaultValue={deaths === null ? '' : deaths}
                          onChange={(e) =>
                            handleChangePlayer(
                              playerId,
                              'deaths',
                              e.target.value
                            )
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell sx={{ padding: 0 }} align="center">
                      <Box margin={1}>
                        <TextField
                          sx={{ width: '100px' }}
                          label="Assists"
                          type="number"
                          defaultValue={assists === null ? '' : assists}
                          onChange={(e) =>
                            handleChangePlayer(
                              playerId,
                              'assists',
                              e.target.value
                            )
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell sx={{ padding: 0 }} align="center">
                      <Box margin={1}>
                        <TextField
                          sx={{ width: '100px' }}
                          label="ACS"
                          type="number"
                          defaultValue={score === null ? '' : score}
                          onChange={(e) =>
                            handleChangePlayer(
                              playerId,
                              'score',
                              e.target.value
                            )
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box margin={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleUpdate()}
        >
          UPDATE MATCH
        </Button>
      </Box>
    </Box>
  );
};

export default MatchTable;
