import React, { useEffect, useState } from 'react';
import { TextField, Typography, Box, Switch, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { get, set } from '../../../../redux/ducks/currentDataSlice';

function sanitizeData(data) {
  const sanitizedData = {};

  if (data.id !== undefined) sanitizedData.id = data.id;
  if (data.seriesId !== undefined) sanitizedData.seriesId = data.seriesId;
  if (data.localSeriesId !== undefined)
    sanitizedData.localSeriesId = data.localSeriesId;
  if (data.localQualifying !== undefined) {
    sanitizedData.localQualifying = data.localQualifying;
    sanitizedData.qualifying = data.localQualifying;
  }
  if (data.localConnectBottom !== undefined)
    sanitizedData.localConnectBottom = data.localConnectBottom;
  if (data.localConnectTop !== undefined)
    sanitizedData.localConnectTop = data.localConnectTop;
  if (data.startDate !== undefined) sanitizedData.startDate = data.startDate;
  if (data.bestOf !== undefined) sanitizedData.bestOf = data.bestOf;
  if (data.completed !== undefined) sanitizedData.completed = data.completed;

  if (Array.isArray(data.teams)) {
    sanitizedData.teams = data.teams.map((team) => {
      const sanitizedTeam = {};
      if (team.name !== undefined) sanitizedTeam.name = team.name;
      if (team.id !== undefined) sanitizedTeam.id = team.id;
      if (team.score !== undefined) sanitizedTeam.score = team.score;
      if (team.bye !== undefined) sanitizedTeam.bye = team.bye;
      if (team.logoUrl !== undefined) sanitizedTeam.logoUrl = team.logoUrl;
      if (team.shortName !== undefined)
        sanitizedTeam.shortName = team.shortName;
      return sanitizedTeam;
    });
  }

  return sanitizedData;
}

export default function AddSeed(props) {
  const { seed: existingSeedData, setContentDialogValues } = props;
  const dispatch = useDispatch();

  const [data, setData] = useState(
    existingSeedData || {
      localSeriesId: '',
      localQualifying: false,
      localConnectBottom: false,
      localConnectTop: false,
      seriesId: '',
      startDate: '',
      bestOf: '',
      teams: [
        { name: '', id: '', score: '', bye: false, logoUrl: '', shortName: '' },
        { name: '', id: '', score: '', bye: false, logoUrl: '', shortName: '' }
      ]
    }
  );

  const seriesData = useSelector((state) => state.currentData).series;

  const handleImportFromSeries = () => {
    dispatch(
      get({ category: 'series', dataId: data.localSeriesId || data.seriesId })
    );
  };

  const setValue = (key, value) => {
    setData((prevState) => ({ ...prevState, [key]: value }));
  };

  const setTeamValue = (key, value, teamIdx) => {
    setData((prevState) => ({
      ...prevState,
      teams: prevState.teams.map((team, idx) =>
        teamIdx === idx ? { ...team, [key]: value } : team
      )
    }));
  };

  const setByeProperties = (isBye) => ({
    bye: isBye,
    id: isBye ? 'BYE' : '',
    name: isBye ? 'BYE' : ''
  });

  const makeTeamBye = (teamIdx) => {
    const teams = [...data.teams];
    teams[teamIdx] = setByeProperties(!teams[teamIdx].bye);
    setData((prevState) => ({ ...prevState, teams }));
  };

  function selectTeamProperties(team) {
    const { name, id, score, bye, logoUrl, shortName } = team;
    return {
      name,
      id,
      score,
      bye,
      logoUrl,
      shortName
    };
  }

  function selectSeriesProperties(series) {
    const { id, startDate, bestOf, completed } = series;
    return {
      id,
      seriesId: id,
      bestOf,
      startDate,
      completed
    };
  }

  useEffect(() => {
    if (seriesData) {
      setData((curr) => {
        const { team1, team1Score, team2, team2Score } = seriesData;

        const teams = [
          selectTeamProperties({ ...team1, score: team1Score }),
          selectTeamProperties({ ...team2, score: team2Score })
        ];
        const seriesProperties = selectSeriesProperties(seriesData);
        return {
          ...sanitizeData(curr),
          ...seriesProperties,
          teams
        };
      });
    }
  }, [seriesData]);

  useEffect(() => {
    const sanitizedData = sanitizeData(data);
    console.log('we are triggered');
    setContentDialogValues({ seriesData: sanitizedData });
  }, [data, setContentDialogValues]);

  useEffect(() => {
    return () => {
      dispatch(set({ category: 'series', data: undefined }));
    };
  }, []);

  return (
    <Box>
      <Box>
        <Typography>
          Qualifying Seed (will only show team 1 as qualified)
        </Typography>
        <Switch
          checked={data.localQualifying}
          onChange={() => {
            setValue('localQualifying', !data.localQualifying);
          }}
        />
      </Box>
      <Typography>Connected series id: {data.id}</Typography>

      <>
        <TextField
          sx={{ marginTop: '20px' }}
          label="Series ID"
          type="text"
          defaultValue={data.seriesId}
          onChange={(e) => {
            setValue('localSeriesId', e.target.value);
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleImportFromSeries}
        >
          Import from series id
        </Button>
        <Typography />
        {data?.teams?.map((team, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index}>
            <TextField
              sx={{ marginTop: '20px' }}
              label={`Team ${index + 1} Name`}
              type="text"
              value={team.name}
              onChange={(e) => {
                setTeamValue('name', e.target.value, index);
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              sx={{ marginTop: '20px' }}
              label={`Team ${index + 1} ID`}
              type="text"
              value={team.id}
              onChange={(e) => {
                setTeamValue('id', e.target.value, index);
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              sx={{ marginTop: '20px' }}
              label={`Team ${index + 1} Score`}
              type="number"
              value={team.score}
              onChange={(e) => {
                setTeamValue('score', e.target.value, index);
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <Box>
              <Typography>{`Team ${index + 1} BYE (Will set team ${
                index + 1
              } as a BYE)`}</Typography>
              <Switch
                checked={team.bye}
                onChange={() => {
                  makeTeamBye(index);
                }}
              />
            </Box>
          </Box>
        ))}
        <Box>
          <Typography>Connect bottom to nearest bracket</Typography>
          <Switch
            checked={data.localConnectBottom}
            onChange={() => {
              setValue('localConnectBottom', !data.localConnectBottom);
            }}
          />
        </Box>
        <Box>
          <Typography>Connect top to nearest bracket</Typography>
          <Switch
            checked={data.localConnectTop}
            onChange={() => {
              setValue('localConnectTop', !data.localConnectTop);
            }}
          />
        </Box>
      </>
    </Box>
  );
}
